import { config } from "../config";
import { checkTokenExpiration, getToken } from './LoginManager';

export const checkAdmin = () => {
    if (sessionStorage.getItem('isAdmin') === null) return false;
    return sessionStorage.getItem('isAdmin') === 'true';
}

export const createUser = (user) => {
    const token = getToken();
    return fetch(`${config.remoteUrl}/users/register`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(user),
    })
        .then(checkTokenExpiration)
        .then((data) => data.json());
}

export const updateUser = (updatedUser, userToEditEmail) => {
    const token = getToken();
    return fetch(`${config.remoteUrl}/users/update-user/${userToEditEmail}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(updatedUser),
    })
        .then(checkTokenExpiration)
        .then((data) => data.json());
}

export const removeAccount = (account) => {
    const token = getToken();
    return fetch(`${config.remoteUrl}/plaid/remove_account/${account.accountId}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(checkTokenExpiration)
        .then((data) => data.json());
}

export const deleteUser = (userToDeleteEmail) => {
    const token = getToken();
    return fetch(`${config.remoteUrl}/users/delete-user/${userToDeleteEmail}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(checkTokenExpiration)
        .then((data) => data.json());
}

export const restoreUser = (userToRestoreEmail) => {
    const token = getToken();
    return fetch(`${config.remoteUrl}/users/restore-user/${userToRestoreEmail}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(checkTokenExpiration)
        .then((data) => data.json());
}