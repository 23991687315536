import React, { Component } from 'react';
import { config } from '../config';
import { FaHome, FaQuestionCircle, FaSignOutAlt, FaUserCog, FaBars, FaWindowClose } from 'react-icons/fa';
import logo from '../assets/logo2.jpg';
import faqLogo from '../assets/icon-faq.png';
import homeLogo from '../assets/icon-home.png';
import menuLogo from '../assets/icon-menu.png';
import settingsLogo from '../assets/icon-settings.png';
import logoutLogo from '../assets/icon-logout.png';
import { checkAdmin } from '../modules/AdminActionManager';
import { getUserInfo, handleLogout, isLoggedIn } from '../modules/LoginManager';
import { Grid, Button, Drawer, ListItem, ListItemIcon, List, Divider, ListItemText } from '@material-ui/core';
import { bannerStatus, hideBanner, getBroadcast } from '../modules/BroadcastManager';
import Banner from "react-js-banner";
import { checkPlaidEnabled } from '../modules/PlaidManager';

const mql = window.matchMedia('(max-width: 600px)');
export class NavBar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isAdmin: checkAdmin(),
            memberName: getUserInfo().name,
            email: getUserInfo().email,
            isPlaidEnabled: checkPlaidEnabled(),
            loggedIn: isLoggedIn(),
            mobileView: mql.matches,
            showBanner: bannerStatus(),
            broadcastMessage: "",
        }
    }

    async componentDidMount() {


        this.setState({
            mobileView: mql.matches
        })

        mql.addEventListener('change', (e) => {
            this.setState({
                mobileView: e.matches
            })
        });

        const broadcastFromAPI = await getBroadcast();

        if (broadcastFromAPI === undefined || broadcastFromAPI === "") {
            this.dismissBanner();
        } else {
            this.setState({
                broadcastMessage: broadcastFromAPI
            })
        }


    }

    componentWillUnmount() {
        mql.removeEventListener('change', (e) => {
            this.setState({
                mobileView: e.matches
            })
        });
    }

    loadBroadcast() {

    }

    handleRoute(route, event) {
        let changeRoute = true;
        if (this.props.changeProp !== undefined) {
            changeRoute = this.props.changeProp(event);
        }
        if (changeRoute) {
            switch (route) {
                case "dashboard":
                    this.props.history.push("/dashboard");
                    break;
                case "login":
                    this.props.history.push("/");
                    break;
                case "faq":
                    this.props.history.push("/faq");
                    break;
                case "settings":
                    this.props.history.push("/settings");
                    break;
                case "logout":
                    handleLogout();
                    break;
                default:
                    break;
            }
        }
    }

    getLogo() {
        return config.logo;
    }
    getFaq() {
        const faqIcon = require("../assets/icon-faq.png");
        return faqIcon;
    }
    getHome() {
        const homeIcon = require("../assets/icon-home.png");
        return homeIcon;
    }
    getSettings() {
        const settingsIcon = require("../assets/icon-settings.png");
        return settingsIcon;
    }
    getLogout() {
        const logoutIcon = require("../assets/icon-logout.png");
        return logoutIcon;
    }
    getMenu() {
        const menuIcon = require("../assets/icon-menu.png");
        return menuIcon;
    }

    getCompanyName() {
        return config.companyName;
    }

    dismissBanner() {
        hideBanner();
        this.setState({ showBanner: bannerStatus() });
    }

    toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({ [anchor]: open });
    };

    render() {

        const list = <div
            className=""
            role="presentation"
            onClick={this.toggleDrawer("menu", false)}
            onKeyDown={this.toggleDrawer("menu", false)}
        >
            <List>
                <ListItem>
                    <ListItemText>Welcome, <strong>{this.state.memberName}</strong>!</ListItemText>
                </ListItem>
                <ListItem button key="dashboard" onClick={(evt) => this.handleRoute("dashboard", evt)}>
                    <ListItemIcon><FaHome /></ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
            </List>

            <Divider />

            <List>
                <ListItem button key="dashboard" onClick={(evt) => this.handleRoute("dashboard", evt)}>
                    <ListItemIcon><FaHome /></ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem button key="faq" onClick={(evt) => this.handleRoute("faq", evt)}>
                    <ListItemIcon><FaQuestionCircle /></ListItemIcon>
                    <ListItemText primary="FAQs" />
                </ListItem>
                <ListItem button key="settings" onClick={(evt) => this.handleRoute("settings", evt)}>
                    <ListItemIcon><FaUserCog /></ListItemIcon>
                    <ListItemText primary={`Settings${this.state.isAdmin ? " / Admin Actions" : ""}`} />
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem button key="logout" onClick={(evt) => this.handleRoute("logout", evt)}>
                    <ListItemIcon><FaSignOutAlt /></ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItem>
            </List>
        </div>


        if (this.state.mobileView) {
            return (
                <Grid container justify="center" id="header" className="navbar page-header">

                    {this.state.loggedIn &&
                        <Grid item xs={12} className="mobile-menu-button-container">
                            <Button onClick={this.toggleDrawer("left", true)} size="large" className="mobile-menu-button">
                                <FaBars />
                                Menu
                            </Button>
                            <Drawer anchor={"left"} open={this.state["left"]} onClose={this.toggleDrawer("left", false)}>
                                {list}
                            </Drawer>
                        </Grid>
                    }

                    <Grid item xs={12} md={6} className="header-card logo-header" onClick={(evt) => this.handleRoute("login", evt)}>
                        <div className="logo-container">
                            <img src={this.getLogo().length > 0 ? this.getLogo() : logo} alt={this.getCompanyName()} className="company-logo" />
                        </div>
                        {
                            (!this.state.loggedIn && this.props.history.location.pathname !== '/faq') &&
                            <div className="navbar-header">Treasury Management Solutions</div>
                        }
                    </Grid>
                    {this.state.loggedIn &&
                        <Grid xs={12} md={12} className="broadcast">
                            <Banner showBanner={this.state.showBanner}>
                                <div>
                                    {this.state.broadcastMessage}
                                    <Button onClick={(evt) => this.dismissBanner()}>
                                        <FaWindowClose className="toolbar-icon" />
                                    </Button>
                                </div>
                            </Banner>
                        </Grid>
                    }
                </Grid>
            )
        }
        if (this.state.isPlaidEnabled) {
            return (
                <Grid container justify="center" id="header" className="navbar page-header">
                    {this.state.loggedIn &&
                        <Grid container>
                            <Grid item xs={12} md={6} className="welcome-message-container">

                                <div className="welcome-message">
                                    Welcome, <strong>{this.state.memberName}</strong>!
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} className="toolbar">
                                <Button onClick={(evt) => this.handleRoute("dashboard", evt)}>
                                    <img src={this.getHome().length < 1 ? this.getHome() : homeLogo} width="14px" height="14px" className="toolbar-icon" alt="home" />
                                    Dashboard
                                </Button>
                                <Button onClick={(evt) => this.handleRoute("faq", evt)}>
                                    <img src={this.getFaq().length < 1 ? this.getFaq() : faqLogo} width="14px" height="14px" className="toolbar-icon" alt="faq" />
                                    FAQs
                                </Button>
                                <Button onClick={(evt) => this.handleRoute("settings", evt)}>
                                    <img src={this.getSettings().length < 1 ? this.getSettings() : settingsLogo} width="14px" height="14px" className="toolbar-icon" alt="settings" />
                                    Settings
                                </Button>
                                <Button onClick={(evt) => this.handleRoute("logout", evt)}>
                                    <img src={this.getLogout().length < 1 ? this.getLogout() : logoutLogo} width="14px" height="14px" className="toolbar-icon" alt="logout" />
                                    Logout
                                </Button>
                            </Grid>

                        </Grid>
                    }

                    <Grid item xs={12} md={6} className="header-card logo-header" onClick={(evt) => this.handleRoute("login", evt)}>
                        <div className="logo-container">
                            <img src={this.getLogo().length > 0 ? this.getLogo() : logo} alt={this.getCompanyName()} className="company-logo" />
                        </div>
                        {
                            (!this.state.loggedIn && this.props.history.location.pathname !== '/faq')
                        }
                    </Grid>

                    {this.state.loggedIn &&
                        <Grid xs={12} md={12}>
                            <Banner showBanner={this.state.showBanner} className="banner">
                                <div>
                                    {this.state.broadcastMessage}
                                    <Button onClick={(evt) => this.dismissBanner()} className="bannerDismiss">
                                        <FaWindowClose className="toolbar-icon" />
                                    </Button>
                                </div>
                            </Banner>
                        </Grid>
                    }
                </Grid>
            )
        } else {
            return (
                <Grid container justify="center" id="header" className="navbar page-header">
                    {this.state.loggedIn &&
                        <Grid container>
                            < Grid item xs={12} md={6} className="welcome-message-container" >
                                <div class="dropdown">
                                    <button className="dropbtn">
                                        <img src={this.getMenu().length < 1 ? this.getMenu() : menuLogo} width="24px" height="18px" className="toolbar-icon" />
                                        <i className="fa fa-caret-down"></i>
                                    </button>
                                    <div className="dropdown-content">
                                        <a onClick={(evt) => this.handleRoute("dashboard", evt)}>
                                            <img src={this.getHome().length < 1 ? this.getHome() : homeLogo} width="14px" height="14px" className="toolbar-icon" alt="home" />
                                            Dashboard
                                        </a>
                                        <a onClick={(evt) => this.handleRoute("faq", evt)}>
                                            <img src={this.getFaq().length < 1 ? this.getFaq() : faqLogo} width="14px" height="14px" className="toolbar-icon" alt="faq" />
                                            FAQs
                                        </a>
                                        <a onClick={(evt) => this.handleRoute("settings", evt)}>
                                            <img src={this.getSettings().length < 1 ? this.getSettings() : settingsLogo} width="14px" height="14px" className="toolbar-icon" alt="settings" />
                                            Settings
                                        </a>
                                        <a onClick={(evt) => this.handleRoute("logout", evt)}>
                                            <img src={this.getLogout().length < 1 ? this.getLogout() : logoutLogo} width="14px" height="14px" className="toolbar-icon" alt="logout" />
                                            Logout
                                        </a>
                                    </div>
                                </div>

                            </Grid >
                            <Grid item xs={12} md={6} className="toolbar">
                                <div className="welcome-messages">
                                    Welcome, <strong>{this.state.memberName}</strong>!
                                </div>
                            </Grid>

                        </Grid>
                    }

                    <Grid item xs={12} md={6} className="header-card logo-header" onClick={(evt) => this.handleRoute("login", evt)}>
                        <div className="logo-container">
                            <img src={this.getLogo().length > 0 ? this.getLogo() : logo} alt={this.getCompanyName()} className="company-logo" />
                        </div>
                        {
                            (!this.state.loggedIn && this.props.history.location.pathname !== '/faq')
                            // &&
                            // <div className="navbar-header">Treasury Management Solutions</div>
                        }
                    </Grid>

                    {this.state.loggedIn &&
                        <Grid xs={12} md={12}>
                            <Banner showBanner={this.state.showBanner} className="banner">
                                <div>
                                    {this.state.broadcastMessage}
                                    <Button onClick={(evt) => this.dismissBanner()} className="bannerDismiss">
                                        <FaWindowClose className="toolbar-icon" />
                                    </Button>
                                </div>
                            </Banner>
                        </Grid>
                    }
                </Grid>
            )
        }
    }
}
