import { config } from '../config';
import { checkTokenExpiration } from './LoginManager';

export const handleGetLogsList = (callback) => {
    const userToken = sessionStorage.getItem("token");

    return fetch(`${config.remoteUrl}/logs`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userToken}`
        }
    })
        .then(checkTokenExpiration)
        .then((data) => data.json())
        .then((data) => {
            callback(data)
        });
};