import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { checkAdmin } from '../../modules/AdminActionManager';
import { NavBar } from '../../components/NavBar';
import { Grid } from '@material-ui/core';
import { getBroadcast, saveBroadcast } from '../../modules/BroadcastManager';

export class BroadcastEdit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingStatus: true,
            logoUrl: "",
            broadcastMessage: "",
            isAdmin: checkAdmin(),
            messages: []
        }
    }

    async componentDidMount() {
        this.isBackButtonClicked = false;

        window.addEventListener('beforeunload', this.handleLeavePage);

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', this.handleGoBack);

        const broadcastFromAPI = await getBroadcast();
        this.setState( {
            broadcastMessage: broadcastFromAPI
        })
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleLeavePage);
        window.removeEventListener('popstate', this.handleGoBack);
    }

    handleFieldChange = (event) => {
        let newBroadcastMessage = event.target.value;
        this.setState({
            broadcastMessage: newBroadcastMessage
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();

        let messagesToAdd = [];

        saveBroadcast(this.state.broadcastMessage).then((response) => {
            if (response.status === 204) {
                this.props.history.push('/faq');
            }
            else {
                messagesToAdd['error'] = response.message;
                this.setState({
                    messages: messagesToAdd
                });
            }
        }).catch((error) => {
            messagesToAdd['error'] = "We're unable to contact the database at this time. Please contact the administrator or try again later."
            this.setState({
                messages: messagesToAdd
            });
        });
    }

    handleLeavePage = (event) => {
        event.preventDefault();
        event.returnValue = 'Are you sure you wish to leave the page? All unsaved changes will be lost.';
        return event.returnValue;
    }

    handleGoBack = (event) => {
        if (window.confirm("Are you sure you wish to leave the page? All unsaved changes will be lost.")) {
            if (event.type === 'popstate') {
                window.history.back();
                return true;
            }
            else if(event.type === 'click') {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            event.preventDefault();
            return false;
        }
    }

    render() {

        if (!this.state.isAdmin) {
            return (
                <Redirect to='/faq'></Redirect>
            )
        }

        return (
            <div id="faq-edit" className="page">
                <NavBar {...this.props} changeProp={this.handleGoBack}/>
                <Grid container className="page-form">
                    <form onSubmit={(evt) => this.handleSubmit(evt)} className="page-inner-form">
                        <h3 className="page-text">Edit Broadcast Message</h3>
                        <div className="page-error login-error">
                            Please save all changes before leaving page. Failing to do so will result in lost work.
                        </div>
                        <p className="faq-text">On this page, you can add or edit the Broadcast Banner Message. When you're finished, please click 'Save' at the bottom of the page. If you'd like to remove the broadcast message, simply delete the text in the box.</p>
                            <Grid container justify="center" key="ul-grid">
                                <Grid item xs={12} md={8}>
                                    <li className="faq-edit-pair faq-list">
                                        <strong>Broadcast Message</strong>
                                        <textarea
                                            onChange={this.handleFieldChange}
                                            value={this.state.broadcastMessage}
                                            className="page-input faq-textarea"
                                            rows="5"
                                            autoComplete='off' />
                                    </li>
                                </Grid>
                            </Grid>
                        <div className="edit-button-container">
                            <button
                                className="page-button"
                                type="submit">
                                Save
                        </button>
                            <button
                                onClick={(event) => {
                                    if (window.confirm('Are you sure you wish to cancel? All unsaved changes will be lost.')) this.props.history.push('/faq');
                                    else event.preventDefault();
                                }}
                                className="page-button delete cancel"
                                disabled={this.state.loadingStatus}>
                                Cancel
                            </button>
                        </div>
                    </form>
                </Grid>
            </div>
        )
    }
}