import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { NavBar } from '../../components/NavBar';
import { handleApiLogin, isLoggedIn, saveLogin } from '../../modules/LoginManager';
import { handleGetUserAccessTokens, handlePlaidGetAccounts } from "../../modules/PlaidManager";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Button } from '@material-ui/core';
import QRCode from 'qrcode';

export class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            loadingStatus: true,
            loggedIn: isLoggedIn(),
            messages: [],
            samlRequestQueryParam: "",
            relayStateQueryParam: ""
        }
    }

    handleFieldChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleLoginRequirements = () => {
        if (!this.state.loadingStatus) {

            let messagesToAdd = [];
            let canLogin = true;
            let regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            this.setState({
                messages: messagesToAdd
            });


            if (this.state.email === "") {
                messagesToAdd["email"] = "Please enter an email address";
                canLogin = false;
            } else if (!regEx.test(this.state.email)) {
                messagesToAdd["email"] = "Please enter a valid email address";
                canLogin = false;
            }

            if (this.state.password === "") {
                messagesToAdd["password"] = "Please enter a password";
                canLogin = false;
            }

            this.setState({
                messages: messagesToAdd
            });

            return canLogin;
        }
    }

    handleRedirectUser = () => {

        // Check if query param for samlRequest exists, if yes push to sso/rdc/redirect including params
        if(this.state.samlRequestQueryParam !== ""){
            const redirectURLWithParams = `/sso/rdc/redirect?SAMLRequest=${this.state.samlRequestQueryParam}&RelayState=${this.state.relayStateQueryParam}`
            this.props.history.push(redirectURLWithParams);
        }

        //if no query param for samlRequest, simply redirect to dashboard
        else{this.props.history.push("/dashboard")}
    }

    handleSubmit = (event) => {
        event.preventDefault();

        if (this.handleLoginRequirements()) {
            let messagesToAdd = [];

            this.setState({
                loadingStatus: true
            });

            handleApiLogin(this.state.email, this.state.password).then((user) => {
                if (user.status === 'success') {
                    saveLogin(user.data);
                    const config = JSON.parse(window.localStorage.getItem("configuration"));
                    if(config.mfa_enabled == "true") {
                        console.log("Setting OTP Enabled to " + user.data.otp_enabled);
                        this.getQRCode();
                        this.setState({otpEnabled: user.data.otp_enabled ? true : false});
                        this.setState({showMfa: true});
                    } else {
                        try {
                            handleGetUserAccessTokens().then((resp) => {
                                // if so, get those accounts and transactions and store in session storage
                                if (resp.status === 200) {
                                    handlePlaidGetAccounts().then((resp) => {
                                        if(resp.data.length > 0) sessionStorage.setItem('accounts', JSON.stringify(resp.data));
                                        this.props.history.push("/dashboard");
                                    });
                                } else if (resp.status === 204) { // if not, link on dashboard
                                    // Check if the user is a sub user
                                    if(user.data.is_sub_user) {
                                        handlePlaidGetAccounts().then((resp) => {
                                            if(resp.data.length > 0) sessionStorage.setItem('accounts', JSON.stringify(resp.data));
                                            this.props.history.push("/dashboard");
                                        })
                                    } else {
                                        this.props.history.push("/dashboard");
                                    }
                                }
                            });
                        } catch (err) {
                            console.error(err);
                        }
                    }
                    // check if user has access tokens in db

                }
                if (user.status === 'error') {
                    messagesToAdd["loginError"] = user.message;

                    this.setState({
                        messages: messagesToAdd,
                        loadingStatus: false
                    })
                }
            })
                .catch((error) => {
                    messagesToAdd["loginError"] = "We're unable to contact the database at this time. Please contact the administrator or try again later."
                    this.setState({
                        messages: messagesToAdd,
                        loadingStatus: false
                    })
                });

        }
    };

    componentDidMount() {
        const searchParams = new URLSearchParams(this.props.location.search);
        this.setState({
            email: "",
            password: "",
            loadingStatus: false,
            messages: [],
            showMfa: false,
            samlRequestQueryParam: searchParams.get('SAMLRequest') || "",
            relayStateQueryParam: searchParams.get('RelayState') || ""
        });
    }

    async getQRCode() {
        const totp = sessionStorage.getItem('totp');
        QRCode.toDataURL(totp, (err, url) => {
            if (!err) {
                this.setState({ dataUrl: url });
            } else {
                console.error(totp);
                console.error('Error generating data URL:', err, totp);
                setTimeout(() => {
                    this.getQRCode();
                }, 1000);
            }
        });
    }

    async verifyTotp(code) {
        try {
            const reqBody = {
                token: sessionStorage.getItem('token'),
                code
            }
            const config = JSON.parse(window.localStorage.getItem("configuration"));
            const response = await fetch(`${config.remoteUrl}/users/verifyTotp`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                },
                body: JSON.stringify(reqBody),
            });
            const responseParsed = await response.json();
            const token = responseParsed.token;
            sessionStorage.setItem('token', token);
            sessionStorage.setItem('validated', true);
            this.setState({validated: true});
            try {
                handleGetUserAccessTokens().then((resp) => {
                    // if so, get those accounts and transactions and store in session storage
                    if (resp.status === 200) {
                        handlePlaidGetAccounts().then((resp) => {
                            if(resp.data.length > 0) sessionStorage.setItem('accounts', JSON.stringify(resp.data));
                            this.props.history.push("/dashboard");
                        });
                    } else if (resp.status === 204) { // if not, link on dashboard
                        // Check if the user is a sub user
                        if(window.sessionStorage.getItem("isSubUser")) {
                            handlePlaidGetAccounts().then((resp) => {
                                if(resp.data.length > 0) sessionStorage.setItem('accounts', JSON.stringify(resp.data));
                                this.props.history.push("/dashboard");
                            })
                        } else {
                            this.props.history.push("/dashboard");
                        }
                    }
                });
            } catch (err) {
                this.props.history.push("/dashboard");
            }
        } catch (err) {
            alert("Failed to validate. Incorrect verification code");
        }
    }

    render() {
        if (this.state.loggedIn && !this.state.showMfa) {
            return (
                <Redirect to='/dashboard'></Redirect>
            )
        }

        if(this.state.showMfa) {
            return (
                <div>
                <h2>Account Security</h2>
                <h4>Account MFA</h4>
                {this.state.otpEnabled == true ? (
                    <div>
                        <input
                            type="text"
                            name="verify"
                            placeholder="Verify Code"
                            onChange={(e) => this.setState({ totpCode: e.target.value })}
                        />
                        <Button
                            className="settings-page-button"
                            disabled={this.state.loading}
                            onClick={(evt) => {
                                this.verifyTotp(this.state.totpCode);
                            }}
                        >
                            Verify
                        </Button>
                    </div>
                ) : (
                    <>
                        Download an approved authenticator application and use code for verification.< br/>
                        {this.state.dataUrl && <img src={this.state.dataUrl} alt="Generated Data URL" />}
                        <br />
                        <input
                            type="text"
                            name="verify"
                            placeholder="Verify Code"
                            onChange={(e) => this.setState({ totpCode: e.target.value })}
                        />
                        <Button
                            className="settings-page-button"
                            disabled={this.state.loading}
                            onClick={(evt) => {
                            this.verifyTotp(this.state.totpCode);
                            }}
                        >
                            Verify
                        </Button>
                    </>
                )}
            </div>
            )
        }

        return (
            <Box id="login" className="page">
                <NavBar {...this.props} />
                <Grid container alignContent="center" className="page-form">
                    <form onSubmit={(evt) => this.handleSubmit(evt)} className="page-inner-form">
                        <h3 className="page-text">Login</h3>

                        <label htmlFor="email" className="page-text">Email address</label>
                        <input
                            onChange={this.handleFieldChange}
                            name="email"
                            id="email"
                            autoFocus="on"
                            value={this.state.email}
                            autoComplete="off"
                            className="page-input"
                        />

                        <label htmlFor="password" className="page-text">Password</label>
                        <input
                            onChange={this.handleFieldChange}
                            type="password"
                            name="password"
                            id="password"
                            value={this.state.password}
                            autoComplete="off"
                            className="page-input"
                        />

                        {
                            (this.state.messages["email"] || this.state.messages["password"] || this.state.messages["loginError"]) &&
                            <div className="page-error login-error">
                                <div className="login-error-header">We're sorry, but something went wrong:</div>
                                <ul className="page-error-body">
                                    {
                                        this.state.messages["email"] &&
                                        <li>
                                            {this.state.messages["email"]}
                                        </li>
                                    }
                                    {
                                        this.state.messages["password"] &&
                                        <li>
                                            {this.state.messages["password"]}
                                        </li>
                                    }
                                    {
                                        this.state.messages["loginError"] &&
                                        <li>
                                            {this.state.messages["loginError"]}
                                        </li>
                                    }
                                </ul>
                            </div>
                        }

                        <button
                            className="page-button"
                            disabled={this.state.loadingStatus}
                            type="submit">
                            Submit
                        </button>
                    </form>
                    <Link to='/forgot-password' className="page-link">
                        Forgot Password?
                    </Link>
                    <Link to='/privacy-policy' className="page-link">
                        Privacy Policy
                    </Link>
                </Grid>
            </Box>
        );
    }
}
