import React, { Component } from 'react';
import "../pages.css";
import { isLoggedIn } from '../../modules/LoginManager';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';

export class RdcSsoLogin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            samlResponse: "",
            acsUrl: "",
            relayState: "",
            loggedIn: isLoggedIn()
        };
        this.hiddenForm = React.createRef();
    }

    componentDidMount() {

        if (this.props.location.state) {
            const { samlResponse, acsUrl, relayState } = this.props.location.state.state
            this.setState({ samlResponse, acsUrl, relayState });
        }

        //AUTOSUBMIT FORM
        if (this.hiddenForm.current) {
            setTimeout(() => {
               this.hiddenForm.current.submit();
            }, 500);

        }
    }

    render() {

        //if user not logged in, redirect to login
        if (!this.state.loggedIn) {
            return (
                <Redirect to='/'></Redirect>
            )
        }

        const { samlResponse, acsUrl, relayState } = this.state;

        return (
            <div className="rdcsso-form-container">

                {/* TO HIDE FORM, CHANGE TEXTAREA TO INPUT TYPE='HIDDEN', & COMMENT EVERYTHING ELSE OUT*/}

                <h1>Redirecting...</h1>
                <h2>You may close this window after application launches</h2>

                <form ref={this.hiddenForm} id='hiddenForm' method="post" action={acsUrl} className="rdcsso-form">

                    {/* <label className="rdcsso-label" htmlFor="SAMLResponse">SAML Response</label> */}
                    <input type='hidden' rows="4" cols="50" name="SAMLResponse" value={samlResponse} />

                    {/* <label className="rdcsso-label" htmlFor="RelayState">Relay State</label> */}
                    <input type='hidden' rows="4" cols="50" name="RelayState" value={relayState} />

                    {/* <button className="rdcsso-button" type="submit">POST SAMLResponse and Relay State to ACSURL</button> */}

                </form>
            </div>
        );
    }
}
