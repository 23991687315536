import React, { Component } from "react";
import "../pages.css";
import { getUserInfo, handleLogout, isLoggedIn } from "../../modules/LoginManager";
import { Redirect } from "react-router";
import { handlePlaidGetTransactions, checkPlaidEnabled } from "../../modules/PlaidManager";
import { NavBar } from '../../components/NavBar';
import { Box, Grid, TableRow, TableCell, Paper, Table, Button, Card, TableHead, TableBody, TableContainer, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { generateStatementPDF } from "../../modules/StatementsManager";
import { PlaidLinkButton } from "./PlaidLinkButton";
import { getSingleSso } from "../../modules/SsoManager";
import Loader from "react-loader-spinner";
import { config, config as configData } from "../../config";
import TablePagination from "@material-ui/core/TablePagination";
import QRCode from 'qrcode';

const moment = require("moment");

const mql = window.matchMedia('(max-width: 960px)');
const mql2 = window.matchMedia('(max-width: 1280px)');
export class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            init: true,
            isLoading: false, // Used only for plaid successful connection
            loading: true,
            link: false,
            token: "",
            memberName: getUserInfo().name,
            businessName: getUserInfo().businessName,
            achModule: getUserInfo().achModule,
            wireModule: getUserInfo().wireModule,
            positivePayModule: getUserInfo().positivePayModule,
            integratedModule: getUserInfo().integratedModule,
            alloyModule: getUserInfo().alloyModule,
            elavonModule: getUserInfo().elavonModule,
            validated: getUserInfo().validated,
            loggedIn: isLoggedIn(),
            isPlaidEnabled: checkPlaidEnabled(),
            transToDisplay: 15,
            numTransRows: 15,
            numIterations: 1,
            transContainerHeight: "",
            transListHeight: "",
            showFilterMenu: false,
            filter: "all",
            showSSOMenu: false,
            mobileView: mql.matches,
            tabletView: mql2.matches,
            menuFilter: {
                title: "All Transactions",
            },
            monthOptions: [],
            currentAccount: {
                name: "",
                type: "",
                number: "",
                id: "",
                currentBalance: "",
                elementId: ""
            },
            accountsList: [],
            ssoModules: [],
            transactionsList: [],
            filteredTransactionsList: [],
            selected: 0,
            hideLink: false,
            accountsErrorMsg: "No Accounts Linked for this User",
            transactionsErrorMsg: "No Transactions to display for the selected Account",
            transactionsLoading: true,
            pageNumber: 0,
            rowsPerPage: 10,
            extensionInstalled: false,
            searchValue: ""
        };
    }



    componentDidMount() {
        let hideLink = false;

        if (!!sessionStorage.getItem('accounts')) {
            hideLink = true;
            this.getAccounts();
        }

        this.showFilterMenu = this.showFilterMenu.bind(this);
        this.showSSOMenu = this.showSSOMenu.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);

        mql.addEventListener('change', (e) => {
            this.setState({
                mobileView: e.matches
            })
        });

        mql2.addEventListener('change', (e) => {
            this.setState({
                tabletView: e.matches
            })
        });

        if (this.state.accountsList.length > 0) {
            this.setState({
                currentAccount: {
                    name: this.state.accountsList[0]?.nickname ?? "No Nickname",
                    type: this.state.accountsList[0]?.type ?? "No Type",
                    number: `xxxx${this.state.accountsList[0]?.number ?? "Unknown"}`,
                    currentBalance: this.state.accountsList[0]?.currentBalance ?? "Unknown",
                    id: this.state.accountsList[0]?.accountId ?? "Unknown",
                    elementId: this.state.accountsList[0]?.id ?? "Unknown"
                }

            })

        }

        this.setState({
            ssoModules: configData.modules,
            hideLink: hideLink,
            loading: false
        });

        window.addEventListener("message", this.handleWindowMessageEvents);
        if(sessionStorage.getItem("otp_enabled") && sessionStorage.getItem("otp_enabled") == 1) {
            this.setState({otpEnabled: true});
        } else {
            this.setState({otpEnabled: false});
        }
        const totp = sessionStorage.getItem('totp');
        QRCode.toDataURL(totp, (err, url) => {
            if (!err) {
              this.setState({ dataUrl: url });
            } else {
              console.error('Error generating data URL:', err);
            }
        });

        this.checkIfExtensionIsInstalled();
    }

    componentWillUnmount() {
        mql.removeEventListener('change', (e) => {
            this.setState({
                mobileView: e.matches
            })
        });

        mql2.removeEventListener('change', (e) => {
            this.setState({
                tabletView: e.matches
            })
        });

        window.removeEventListener('message', this.handleWindowMessageEvents);
    }

    handleWindowMessageEvents = (event) => {
        if (event.source === window &&
            event.data?.sender === "tt-extension" &&
            event.data?.type) {

            switch (event.data.type) {
                case "extensionInstalledNotification":
                    this.setState({ extensionInstalled: true });
                    break;
                case "loginComplete":
                    this.setState(state => {
                        const moduleIndex = this.getModuleIndex(event.data.moduleKeyValue);
                        state.ssoModules[moduleIndex].isLoggingIn = false;
                        return state;
                    });
                    break;
                default:
                    break;
            }
        }
    }

    /**
     * Gets the index of the state for the particular module in this.state.ssoModules.
     * NOTE: May return undefined if the state isn't yet fully loaded.
     * @param {string} moduleName The key value for the particular module's object.
     * @returns {int} Index of the particular module in the ssoModules state array.
     */
    getModuleIndex(moduleName) {
        let moduleIndex;

        if (this.state.ssoModules) {
            for (let i = 0; i < this.state.ssoModules.length; i++) {
                if (this.state.ssoModules[i].key === moduleName) {
                    moduleIndex = i;
                    break;
                }
            }
        }

        return moduleIndex

    }

    /** Posts message so the browser extension can reply with an extensionInstalledNotification if it's installed */
    checkIfExtensionIsInstalled() {
        window.postMessage(
            {
                type: "extensionInstallCheck"
            },
            window.location.origin
        );
    }

    numberFormatter(num) {
        num = String(num);
        if (num !== null) {
            let numFormat = new Intl.NumberFormat("en-US", {
                //should look like "$50" or "$50.34"
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            return numFormat.format(num);
        }
    }

    pendingFormat(pending) {
        return pending ? "Pending" : ""
    }

    dateFormat(date) {
        return moment(date).format("MM/DD/YY")
    }

    showFilterMenu() {
        this.setState({ showFilterMenu: !this.state.showFilterMenu });
    }

    showSSOMenu() {
        this.setState({ showSSOMenu: !this.state.showSSOMenu });
    }

    handleFilter(event, option) {
        // Filter transactions list based on button clicked
        // Set menu selection, set transactions list, close menu
        this.setState({ loading: true });
        switch (event.target.value) {
            case "all":
                this.setState({ menuFilter: { title: "All Transactions" }, filter: "all" }, () => setTimeout(() => {
                    this.setState({ filteredTransactionsList: this.getFilteredTransactionList(), loading: false, showFilterMenu: false });
                }));
                break;
            case "deposit":
                this.setState({ menuFilter: { title: "Deposits Only" }, filter: "deposit" }, () => setTimeout(() => {
                    this.setState({ filteredTransactionsList: this.getFilteredTransactionList(), loading: false, showFilterMenu: false });
                }));
                break;
            case "withdrawal":
                this.setState({ menuFilter: { title: "Withdrawals Only" }, filter: "withdrawal" }, () => setTimeout(() => {
                    this.setState({ filteredTransactionsList: this.getFilteredTransactionList(), loading: false, showFilterMenu: false });
                }));
                break;
            default:
                break;
        }
    }

    handleSSORouting(routeIndex) {
        if (routeIndex !== "none") {
            let currentSsoModule = configData.modules[routeIndex];

            getSingleSso(routeIndex).then((singleSsoCred) => {
                if (singleSsoCred.status === "success") {
                    let currentSsoCred = singleSsoCred.data;

                    const loginUrl =
                        `https://${encodeURIComponent(currentSsoCred.username)}:${encodeURIComponent(currentSsoCred.password)}@${currentSsoModule.partialUrl}`;

                    // Create tab in bg if extension is installed.
                    if (this.state.extensionInstalled) {
                        // Show loading spinner for clicked module
                        this.setState(state => {
                            state.ssoModules[routeIndex].isLoggingIn = true;
                            return state;
                        });

                        // Send message to content script to trigger opening a new bg tab
                            window.postMessage(
                            {
                                type: "openBackgroundTab",
                                url: loginUrl
                            },
                            window.location.origin
                        );
                        // window.open(loginUrl, loginUrl, "menubar=1,resizable=1,width=350,height=250");
                        // Reset spinner in case some type of error occurs
                        setTimeout(() => {
                            if (this.state.ssoModules[routeIndex].isLoggingIn) {
                                this.setState(state => {
                                    state.ssoModules[routeIndex].isLoggingIn = false;
                                    return state;
                                });
                            }
                        }, 15000);
                        // Function normally as if extension isn't installed.
                    } else {
                        window.open(loginUrl);
                        //window.open(loginUrl, loginUrl, "menubar=1,resizable=1,width=1200,height=600");
                    }

                } else {
                    window.open(currentSsoModule.url);
                    //window.open(currentSsoModule.url, currentSsoModule.url, "menubar=1,resizable=1,width=1200,height=600");
                }
            }).catch((err) => {
                window.open(currentSsoModule.url);
                //window.open(currentSsoModule.url, currentSsoModule.url, "menubar=1,resizable=1,width=1200,height=600");
            });
        }
    }


    // Separate flow for RDC SSO via SAML begins by initiating request from FTNI Service Provider
    // Although often described by the 3rd party as IDP-initiated, it is in fact SP-initiated
    handleRDCSSO(){
        //need to insert subdomain into entityID of URL
        const subdomain = window.location.host.split('.')[0] ?? "uat";
        const loginUrl = `https://test.ftnirdc.com/Portal2/api/SSO/SignIn2/Shibboleth.sso/Login?entityID=https://${subdomain}.trutreasury.com/idp&target=https://test.ftnirdc.com/Portal2/api/SSO/SignIn2`
        window.open(loginUrl);
    }


    switchAccounts(accountId) {
        // Get ID from account list item that was clicked
        // Find account info, call getTransactions for that account
        // Populate transactions list with new account info
        const currAcc = this.state.accountsList.find((account) => account.id === accountId);
        if (currAcc.accountId !== this.state.currentAccount.id) {
            this.setState({ loading: true });
            this.getTransactions(currAcc.accountId);
            this.setState({ searchValue: "" });
            this.setState({
                currentAccount: {
                    name: currAcc.nickname,
                    type: currAcc.type,
                    number: `xxxx${currAcc.number}`,
                    id: currAcc.accountId,
                    currentBalance: currAcc.currentBalance,
                    elementId: accountId
                }
            }, () => setTimeout(() => this.setState({
                filteredTransactionsList: this.getFilteredTransactionList(),
                numIterations: 1,
                loading: false,
                pageNumber: 0,
                rowsPerPage: 10
            }), 0));
        }
    }

    getFilteredTransactionList() {
        let fullTransList = this.state.transactionsList;
        fullTransList = fullTransList.filter((transaction) => transaction.accountId === this.state.currentAccount.id);
        this.getMonthOptions();

        let searchString = this.state.searchValue;

        if (searchString.length === 0) {
            if (this.state.filter === "all") {
                return fullTransList;
            } else {
                return fullTransList.filter((transaction) => transaction.type === this.state.filter);
            }
        }

        if (searchString.length > 0) {
            let filteredList = fullTransList.filter((transaction) => {
                if (this.state.filter === "all") {
                    return 1;
                } else {
                    return transaction.type === this.state.filter;
                }
            });

            let finalFilteredList = [];
            if (!isNaN(searchString)) {
                finalFilteredList = filteredList.filter((transaction) => {
                    return transaction.amount.includes(this.state.searchValue);
                });
            } else {
                finalFilteredList = filteredList.filter((transaction) => {
                    return transaction.description.toLowerCase().includes(this.state.searchValue.toLowerCase());
                });
            }

            return finalFilteredList;
        }
    }

    getTransactions(accountId) {
        this.setState({ transactionsList: [], loading: true, transactionsLoading: true });
        let selectedAccountBal = this.state.accountsList.filter((account) =>
            account.accountId === accountId)[0].currentBalance
            .replace("$", "")
            .replace(",", "");
        handlePlaidGetTransactions(accountId).then(resp => {
            if (resp.status === "success") {
                const transactions = resp.data;
                const sortedTransactions = transactions.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
                sortedTransactions.forEach((transaction, index) => {
                    let type = "deposit";
                    if (transaction.amount > 0) {
                        type = "withdrawal";
                    }

                    this.state.transactionsList.push({
                        accountId: transaction.accountId,
                        transactionId: transaction.transactionId,
                        pending: transaction.pending,
                        date: transaction.date,
                        description: transaction.name,
                        amount: this.numberFormatter(transaction.amount * -1),
                        type,
                        runningBalance: this.numberFormatter(selectedAccountBal)
                    });

                    selectedAccountBal = selectedAccountBal - (transaction.amount * -1);
                });
                this.setState({ loading: true }, () => {
                    setTimeout(() => {
                        this.setState({ filteredTransactionsList: this.getFilteredTransactionList(), loading: false, transactionsLoading: false });
                    }, 0);
                });
            } else {
                this.setState({ transactionsErrorMsg: "Error retrieving transactions, please refresh the page" })
            }
        });
    }

    getAccounts() {
        const accounts = JSON.parse(sessionStorage.getItem('accounts')) ?? [];
        accounts.forEach((account, index) => {
            this.state.accountsList.push({
                id: `account${index}`,
                nickname: account.accountName,
                type: account.accountSubtype,
                number: account.accountMask,
                currentBalance: account.accountBalance !== null ? this.numberFormatter(account.cachedBalance) : "",
                accountId: account.accountId
            })
        });

        if (this.state.accountsList.length > 0) {
            this.getTransactions(this.state.accountsList[0].accountId);
            this.setState({
                loading: false,
                currentAccount: {
                    name: this.state.accountsList[0]?.nickname ?? "No Nickname",
                    type: this.state.accountsList[0]?.type ?? "No Type",
                    number: `xxxx${this.state.accountsList[0]?.number ?? "Unknwon"}`,
                    currentBalance: this.state.accountsList[0]?.currentBalance ?? "Unknown",
                    id: this.state.accountsList[0]?.accountId ?? "Unknown",
                    elementId: this.state.accountsList[0]?.id ?? "Unknown"
                }
            })
        }
    }

    refreshTransactions() {
        this.state.accountsList.forEach(account => {
            this.getTransactions(account.accountId);
        });
    }

    renderAccountsTable() {
        // Iterate over list of accounts and create new entry in list
        let accounts = [];
        if (this.state.accountsList.length > 0) {
            accounts = this.state.accountsList.map((account, index) => {
                return (
                    <div className={`account-list-item ${this.state.currentAccount.elementId === account.id ? "selected-account" : ""}`} key={index} id={account.id} onClick={(evt) => this.switchAccounts(account.id)}>
                        <Grid container>
                            <Grid item xs={8}>
                                <Grid container direction="column" className="account-list-text">
                                    <Grid item className="bold-text">
                                        {account.nickname}
                                    </Grid>
                                    <Grid item className="capitalize">
                                        {account.type}
                                    </Grid>
                                    <Grid item className="bold-text">
                                        {`xxxx${account.number}`}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container direction="column" className="account-list-text account-list-float-right">
                                    <Grid item className="bold-text">
                                        {`${account.currentBalance}`}
                                    </Grid>
                                    <Grid item>
                                        Current
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                )
            });
        } else {
            accounts =
                <div className="accounts-row" key="no-accounts" id="no-accounts">
                    <div className="no-accounts">
                        {this.state.accountsErrorMsg}
                        {
                            !this.state.hideLink &&
                            <PlaidLinkButton />
                        }
                    </div>
                </div>
        }


        // Need to have an iterable id for switching accounts for trans view
        return (
            <div className="accounts-table">
                <div className="account-list-header">
                    Account Overview - <button onClick={(evt) => this.refreshTransactions()} className="refresh-button">Refresh</button>
                </div>
                {accounts}
            </div>
        )
    }

    monthFormat(date) {
        return moment(date).format("MMM YYYY");
    }

    handleChangePage(evt, newPage) {
        this.setState({ pageNumber: newPage });
    }

    handleChangeRowsPerPage(evt) {
        this.setState({ rowsPerPage: parseInt(evt.target.value, 10), pageNumber: 0 });
    }

    renderTransactionsTable() {
        let transactions = [];
        if (this.state.filteredTransactionsList.length > 0) {
            transactions = this.state.filteredTransactionsList.map((transaction, index) => {
                if (index >= this.state.pageNumber * this.state.rowsPerPage && index < (this.state.pageNumber * this.state.rowsPerPage + this.state.rowsPerPage)) {
                    let deposit = "";
                    let withdrawal = "";
                    if (transaction.type === "deposit") {
                        deposit = transaction.amount;
                    } else if (transaction.type === "withdrawal") {
                        withdrawal = transaction.amount;
                    }

                    return (
                        <TableRow key={index}>
                            <TableCell component="th">{this.dateFormat(transaction.date)}</TableCell>
                            <TableCell component="th">{transaction.description}</TableCell>
                            <TableCell component="th" align="right">{deposit}</TableCell>
                            <TableCell component="th" align="right" className="withdrawal-text">{withdrawal}</TableCell>
                            <TableCell component="th" align="right">{transaction.runningBalance}</TableCell>
                        </TableRow>
                    )
                }
                return null;
            });
        } else if (this.state.transactionsLoading) {
            transactions = <TableRow key="0"></TableRow>
        }

        return (
            <Grid item xs={12}>
                <TableContainer component={Paper} className="user-table">
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow key="header" className="user-table-header">
                                <TableCell className="user-table-header">Date</TableCell>
                                <TableCell className="user-table-header">Description</TableCell>
                                <TableCell align="right" className="user-table-header">Deposits</TableCell>
                                <TableCell align="right" className="user-table-header">Withdrawals</TableCell>
                                <TableCell align="right" className="user-table-header">Balance</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transactions}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={transactions.length > 0 ? transactions.length : 0}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.pageNumber}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
                {(this.state.filteredTransactionsList.length === 0 && !this.state.transactionsLoading) ?
                    <div key="no-transactions" id="no-transactions">
                        <div className="no-transactions">{this.state.transactionsErrorMsg}</div>
                    </div> : <></>
                }
                {this.state.transactionsLoading && <Loader
                    type="Oval"
                    color={configData.colors.loadingSpinner}
                    height={80}
                    width={80}
                    timeout={30000} //30 secs
                    style={{ marginLeft: "45%", marginTop: "50px" }}
                />}
            </Grid>
        )
    }

    renderMobileTransactionsTable() {
        let transactions = [];
        if (this.state.filteredTransactionsList.length > 0) {
            transactions = this.state.filteredTransactionsList.map((transaction, index) => {
                if (index >= this.state.pageNumber * this.state.rowsPerPage && index < (this.state.pageNumber * this.state.rowsPerPage + this.state.rowsPerPage)) {
                    let deposit = "";
                    let withdrawal = "";
                    if (transaction.type === "deposit") {
                        deposit = transaction.amount;
                    } else if (transaction.type === "withdrawal") {
                        withdrawal = transaction.amount;
                    }
                    return (
                        <TableRow key={index}>
                            <TableCell component="th">{this.dateFormat(transaction.date)} {this.pendingFormat(transaction.pending)}</TableCell>
                            <TableCell component="th">{transaction.description}</TableCell>
                            <TableCell component="th" align="right" className={`${transaction.type === "withdrawal" ? "withdrawal-text" : ""}`}>{deposit}{withdrawal}</TableCell>
                        </TableRow>

                    )
                }
                return null;
            });
        } else if (this.state.transactionsLoading) {
            transactions = <TableRow key="0"></TableRow>
        }

        return (
            <Grid item xs={12}>
                <TableContainer component={Paper} className="user-table">
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow key="header" className="user-table-header">
                                <TableCell className="user-table-header">Date</TableCell>
                                <TableCell className="user-table-header">Description</TableCell>
                                <TableCell align="right" className="user-table-header">Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transactions}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={transactions.length > 0 ? transactions.length : 0}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.pageNumber}
                    onChangePage={this.handleChangePage}
                />
                {(this.state.filteredTransactionsList.length === 0 && !this.state.transactionsLoading) ?
                    <div key="no-transactions" id="no-transactions">
                        <div className="no-transactions">{this.state.transactionsErrorMsg}</div>
                    </div> : <></>
                }
                {this.state.transactionsLoading && <Loader
                    type="Oval"
                    color={configData.colors.loadingSpinner}
                    height={80}
                    width={80}
                    timeout={30000} //30 secs
                    style={{ marginLeft: "45%", marginTop: "50px" }}
                />}
            </Grid>
        )
    }

    renderSsoPortal() {
        const ModuleTooltip = withStyles((theme) => ({
            tooltip: {
                backgroundColor: "cornflowerblue",
                color: 'white',
                boxShadow: theme.shadows[1],
                fontSize: 14,
            },
        }))(Tooltip);

        this.state.ssoModules.map((module, index) => {
            if (!this.state.extensionInstalled) {
                return (
                    <div className="sso-module-text account-list-item" key={index} id={module.key} onClick={(evt) => this.handleSSORouting(index)}>
                        {module.name}
                    </div>
                )
            }
            // Used with browser extension to signify to users a login is in progress
            else if (module.isLoggingIn) {
                return (
                    <div className="sso-module-text account-list-item" key={index} id={module.key}>
                        <Loader
                            type="Oval"
                            color={configData.colors.loadingSpinner}
                            height={40}
                            width={40}
                            timeout={15000}
                        />
                    </div>
                )
            }
            // Module is not logging in so show tool tip on hover
            else {
                return (
                    <ModuleTooltip key={index} title="The new tab will auto-focus upon login">
                        <div className="sso-module-text account-list-item" key={index} id={module.key} onClick={(evt) => this.handleSSORouting(index)}>
                            {module.name}
                        </div>
                    </ModuleTooltip>
                )
            }
        });

        // Need to have an iterable id for switching accounts for trans view

        return (
            <div>
                <div className="account-list-header">
                    Product Modules
                </div>
                {this.state.achModule > 0 &&
                    <div
                        className="sso-module-text account-list-item"
                        onClick={(evt) => this.handleSSORouting(0)}>
                        {config.modules_builder["ach.name"]}
                    </div>
                }
                {this.state.wireModule > 0 &&
                    <div
                        className="sso-module-text account-list-item"
                        onClick={(evt) => this.handleSSORouting(1)}>
                        {config.modules_builder["wire.name"]}
                    </div>
                }
                {this.state.positivePayModule > 0 &&
                    <div
                        className="sso-module-text account-list-item"
                        onClick={(evt) => this.handleSSORouting(2)}>
                        {config.modules_builder["posPay.name"]}
                    </div>
                }
                {this.state.integratedModule > 0 &&
                    <div
                        className="sso-module-text account-list-item"
                        onClick={(evt) => this.handleRDCSSO()}>
                        {config.modules_builder["payRec.name"]}
                    </div>
                }
                {this.state.alloyModule > 0 &&
                    <div
                        className="sso-module-text account-list-item"
                        onClick={(evt) => this.handleSSORouting(4)}>
                        {config.modules_builder["alloy.name"]}
                    </div>
                }
                {this.state.elavonModule > 0 &&
                    <div
                        className="sso-module-text account-list-item"
                        onClick={(evt) => this.handleSSORouting(5)}>
                        {config.modules_builder["elavon.name"]}
                    </div>
                }
            </div>


        )
    }


    renderFilters() {
        return (
            <select
                type='text'
                name="filter-dropdown"
                id="filter-dropdown"
                className="filter-dropdown"
                value={this.state.filter}
                onChange={(evt) => this.handleFilter(evt)}
            >
                <option key="all" value="all">All Transactions</option>
                <option key="deposit" value="deposit">Deposits Only</option>
                <option key="withdrawal" value="withdrawal">Withdrawals Only</option>
            </select>
        )
    }

    handleMonthSelect(event) {
        event.preventDefault();
        if (event.target.value !== "none") {
            let monthString = event.target.value.replace("-", " ");
            generateStatementPDF(this.currentAccountTransactions(), monthString, this.state.currentAccount, this.state.memberName, this.state.businessName);
        }
        event.target.value = "none";
    }

    isTransactionMatch(element, value) {
        if (isNaN(value) && element.description.toLowerCase().includes(value.toLowerCase())) {
            return true;
        }

        if (!isNaN(value) && element.amount.includes(value)) {
            return true;
        }

        return false;
    }

    handleSearch = (e) => {
        const value = e.target.value;
        this.setState({ searchValue: value });
        setTimeout(() => {
            let transactionList = this.getFilteredTransactionList();
            if (transactionList.length > 0) {
                this.setState({ filteredTransactionsList: transactionList });
            }
        });
    }

    isLessThanYearOld(transactionDate) {
        let oldestMonth = moment().subtract(12, 'month');
        return oldestMonth.isBefore(transactionDate);
    }

    currentAccountTransactions() {
        return this.state.transactionsList.filter((transaction) => transaction.accountId === this.state.currentAccount.id);
    }

    getMonthOptions() {
        let monthOptions = [];

        this.setState({
            monthOptions: monthOptions
        })

        if (this.state.currentAccount.id !== "") {
            let currentAccountTransactions = this.currentAccountTransactions();

            currentAccountTransactions.forEach((transaction) => {
                let monthString = this.monthFormat(transaction.date)
                if (!monthOptions.includes(monthString) && this.isLessThanYearOld(transaction.date)) {
                    monthOptions.push(monthString);
                }
            })
        }

        this.setState({
            monthOptions: monthOptions
        })
    }

    handleMobileMenuClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget
        })
    };

    handleMobileMenuClose = () => {
        this.setState({
            anchorEl: null
        })
    };

    renderMobileAccounts() {
        let dropdownOptions = this.state.accountsList.map((account, index) => {
            return (
                <option value={account.id} key={index}>{account.nickname} - xxxx{account.number} - {account.currentBalance}</option>
            )
        })

        return (
            <select
                type='text'
                name="filter-dropdown"
                id="filter-dropdown"
                defaultValue="none"
                className="filter-dropdown"
                onChange={(evt) => this.switchAccounts(evt.target.value)}
            >
                {dropdownOptions}
            </select>
        )
    }

    renderMobileSsoPortal() {
        let dropdownOptions = this.state.ssoModules.map((module, index) => {
            return (
                <option value={index} key={index}>{module.name}</option>
            )
        })

        dropdownOptions.unshift(<option key="none" value="none">Select a product module</option>)

        return (
            <select
                type='text'
                name="filter-dropdown"
                id="filter-dropdown"
                defaultValue="none"
                className="filter-dropdown"
                onChange={(evt) => this.handleSSORouting(evt.target.value)}
            >
                {dropdownOptions}
            </select>
        )
    }

    renderStatementsDropdown() {
        let dropdownOptions = this.state.monthOptions.map((monthOption) => {
            return (
                <option key={monthOption.replace(" ", "-")} value={monthOption.replace(" ", "-")}>{monthOption}</option>
            )
        })

        dropdownOptions.unshift(<option key="none" value="none">Select a month</option>)

        return (
            <select
                type='text'
                name="filter-dropdown"
                id="filter-dropdown"
                defaultValue="none"
                className="filter-dropdown"
                onChange={(evt) => this.handleMonthSelect(evt)}
            >
                {dropdownOptions}
            </select>
        )
    }

    render() {
        if (!this.state.loggedIn) {
            return (
                <Redirect to='/'></Redirect>
            )
        }

        if(this.state.validated == 'false') {
            handleLogout();
            return (
                <Redirect to='/'></Redirect>
            )
        }

        if (this.state.isLoading) {
            return <div className="loaderHolder"><Loader
                type="Oval"
                color={configData.colors.loadingSpinner}
                height={80}
                width={80}
                timeout={30000} //30 secs
            /></div>
        } else {

            if (!this.state.isPlaidEnabled) {
                return <Box id="dashboard" className="page">
                    <NavBar {...this.props} />
                    <div className="dashboard-margin-top">
                        <Grid container spacing={3} className="displayFlexCenter">
                            {
                                this.state.mobileView &&
                                <Grid item xs={12} lg={3}>
                                    <div className="dashboard-container">
                                        <Grid container direction="row">
                                            <Grid item xs={12} md={3}>
                                                <div className="menu-container">
                                                    Product Modules:
                                                    {this.renderMobileSsoPortal()}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>

                            }

                            {!this.state.mobileView && this.state.achModule === 0 && this.state.wireModule === 0
                                && this.state.positivePayModule === 0 && this.state.integratedModule === 0 && this.state.alloyModule === 0 && this.state.elavonModule === 0 ? null :
                                !this.state.mobileView &&
                                (
                                    <div>
                                        <div className="grid-container-header">
                                            Product Modules
                                        </div>
                                        <div className="grid-container">
                                            {this.state.achModule > 0 &&
                                                <div
                                                    style={{backgroundColor: "var(--pageButtons)", color: "var(--pageButtonsText)"}}
                                                    onClick={(evt) => this.handleSSORouting(0)}>
                                                    {config.modules_builder["ach.name"]}
                                                </div>
                                            }
                                            {this.state.wireModule > 0 &&
                                                <div
                                                    style={{backgroundColor: "var(--pageButtons)", color: "var(--pageButtonsText)"}}
                                                    onClick={(evt) => this.handleSSORouting(1)}>
                                                    {config.modules_builder["wire.name"]}
                                                </div>
                                            }
                                            {this.state.positivePayModule > 0 &&
                                                <div
                                                    style={{backgroundColor: "var(--pageButtons)", color: "var(--pageButtonsText)"}}
                                                    onClick={(evt) => this.handleSSORouting(2)}>
                                                    {config.modules_builder["posPay.name"]}
                                                </div>
                                            }
                                            {this.state.integratedModule > 0 &&
                                                <div
                                                    style={{backgroundColor: "var(--pageButtons)", color: "var(--pageButtonsText)"}}
                                                    onClick={(evt) => this.handleRDCSSO()}>
                                                    {config.modules_builder["payRec.name"]}
                                                </div>
                                            }
                                            {this.state.alloyModule > 0 &&
                                                <div
                                                    style={{backgroundColor: "var(--pageButtons)", color: "var(--pageButtonsText)"}}
                                                    onClick={(evt) => this.handleSSORouting(4)}>
                                                    {config.modules_builder["alloy.name"]}
                                                </div>
                                            }
                                            {this.state.elavonModule > 0 &&
                                                <div
                                                    style={{backgroundColor: "var(--pageButtons)", color: "var(--pageButtonsText)"}}
                                                    onClick={(evt) => this.handleSSORouting(5)}>
                                                    {config.modules_builder["elavon.name"]}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )}
                        </Grid>
                    </div>
                </Box>
            } else {
                return (
                    <Box id="dashboard" className="page">
                        <NavBar {...this.props} />
                        <div className="dashboard-margin-top">
                            <Grid container spacing={2} className="displayFlexCenter">
                                {
                                    !this.state.tabletView &&
                                    <Grid item xs={12} md={3}>
                                        <div className="dashboard-container">
                                            {this.renderAccountsTable()}
                                        </div>
                                    </Grid>
                                }
                                {
                                    this.state.mobileView &&
                                    <Grid item xs={12} lg={3}>
                                        <div className="dashboard-container">
                                            <Grid container direction="row">
                                                <Grid item xs={12} md={3}>
                                                    <div className="menu-container">
                                                        Product Modules:
                                                        {this.renderMobileSsoPortal()}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>

                                }
                                <Grid item xs={12} md={8} lg={6}>
                                    <div className="dashboard-container mobile-dashboard-container">
                                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                            <Grid item xs={12} lg>
                                                <div className="transactions-header">
                                                    <div className="account-type-transactions">{this.state.currentAccount.name}</div>
                                                    <div className="account-number-transactions">{this.state.currentAccount.number}</div>
                                                    {
                                                        (this.state.tabletView && !this.state.loading && this.state.accountsList.length === 0) &&
                                                        <div className="no-accounts">
                                                            {this.state.accountsErrorMsg}
                                                            <PlaidLinkButton />
                                                        </div>
                                                    }
                                                </div>
                                            </Grid>
                                            {
                                                (this.state.tabletView && this.state.accountsList.length !== 0) &&
                                                <Grid item xs={12}>
                                                    <div className="menu-container">
                                                        Select an Account:
                                                        {this.renderMobileAccounts()}
                                                    </div>
                                                </Grid>
                                            }
                                            <Grid item xs={12} lg={4} className="search-container search-box-item" >
                                                <div className="search-box">
                                                    Search transactions:
                                                    <input type="text" value={this.state.searchValue} placeholder={"Search by Company or Amount..."} onChange={this.handleSearch} />
                                                </div>
                                            </Grid>
                                            {
                                                this.state.monthOptions.length !== 0 &&
                                                <Grid item xs={12} lg>
                                                    {
                                                        <div className="menu-container">
                                                            PDF Statement:
                                                            {this.renderStatementsDropdown()}
                                                        </div>
                                                    }
                                                </Grid>
                                            }

                                            <Grid item xs={12} lg>
                                                <div className="menu-container">
                                                    Filter transactions:
                                                    {this.renderFilters()}
                                                </div>
                                            </Grid>
                                        </Grid>

                                        <div className="transactions-body" id="transactionsBody">
                                            {
                                                this.state.mobileView &&
                                                this.renderMobileTransactionsTable()
                                            }
                                            {
                                                !this.state.mobileView &&
                                                this.renderTransactionsTable()
                                            }
                                        </div>
                                    </div>
                                </Grid>

                                {
                                    !this.state.mobileView && this.state.achModule === 0 && this.state.wireModule === 0
                                        && this.state.positivePayModule === 0 && this.state.integratedModule === 0 && this.state.alloyModule === 0 && this.state.elavonModule === 0 ? null :
                                        !this.state.mobileView &&
                                        (

                                            <Grid item xs={12} md={4} lg={3}>

                                                <div className="dashboard-container">
                                                    {this.renderSsoPortal()}
                                                </div>
                                            </Grid>
                                        )
                                }

                            </Grid>
                        </div>
                    </Box>

                )

            }

        }


    }

    pageInit() {
        if (!!sessionStorage.getItem('accounts')) {
            this.getAccounts();
        }
    }
}
