import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { checkAdmin, createUser, updateUser } from '../../modules/AdminActionManager';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import { checkSubUser } from '../../modules/businessSubUserManager';
import { config as configData } from '../../config'
import AssignUser from './AssignUser';

export class CreateUser extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingStatus: true,
            logoUrl: "",
            questionsAndAnswers: [],
            isAdmin: checkAdmin(),
            isSubUser: checkSubUser(),
            messages: [],
            showModal: false,
            user: {
                accountsSelected: [],
                email: "",
                suid: "",
                businessName: "",
                firstName: "",
                lastName: "",
                isAdmin: false,
                isSubUser: false,
                achModule: false,
                wireModule: false,
                positivePayModule: false,
                integratedModule: false,
                alloyModule: false,
                elavonModule: false
            }
        }
    }


    componentDidMount() {
        this.setState({
            loadingStatus: false
        })
    }

    handleModuleChange = (e) => {
        if (e.target.type === 'checkbox') {
            let user = this.state.user;
            user[e.target.name] = e.target.checked;
            this.setState({ [this.state.user]: user });
        } else {

            this.setState({ [e.target.name]: e.target.value })
        }
        setTimeout(() => {

        }, 500);
    }

    handleFieldChange = (event) => {
        let updatedUser = this.state.user;
        if (event.target.id === 'isAdmin') {
            if (event.target.value === 'subuser') {
                updatedUser.isAdmin = false;
                updatedUser.isSubUser = true;
                this.assignAccounts();
            } else {
                updatedUser.isSubUser = false;
                updatedUser.isAdmin = JSON.parse(event.target.value)
            }
        }
        else {
            updatedUser[event.target.id] = event.target.value
        }

        this.setState({
            user: updatedUser
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            messages: [],
            loadingStatus: true
        })
        let messagesToAdd = [];
        if (this.state.user.isSubUser && this.state.user.accountsSelected?.length === 0) {
            messagesToAdd['error'] = "The subuser must have at least one account to view.";
            this.setState({
                messages: messagesToAdd,
                loadingStatus: false
            });
            return false;
        }
        createUser(this.state.user).then((response) => {
            if (response.status === 'success') {
                this.props.getUserList();
                messagesToAdd['success'] = "User created."
                this.setState({
                    user: {
                        accountsSelected: [],
                        email: "",
                        businessName: "",
                        firstName: "",
                        lastName: "",
                        isAdmin: false,
                        isSubUser: false,
                        achModule: false,
                        wireModule: false,
                        positivePayModule: false,
                        integratedModule: false,
                        alloyModule: false,
                        elavonModule: false
                    },
                })
            } else {
                messagesToAdd['error'] = response.message;
            }
            this.setState({
                messages: messagesToAdd,
                loadingStatus: false
            });
        }).catch(() => {
            messagesToAdd['error'] = "We're unable to contact the database at this time. Please contact the administrator or try again later."
            this.setState({
                messages: messagesToAdd,
                loadingStatus: false
            });
        });
    }

    assignAccounts = () => {
        this.setState({ showModal: true });
    }

    handleCallback = (e) => {
        this.setState({ showModal: false });
        const currentUser = this.state.user;
        currentUser["accountsSelected"] = e;
        this.setState({ user: currentUser });
    }

    handleCancel = () => {
        this.setState({showModal: false});
    }

    render() {
        if (!this.state.isAdmin) {
            return (
                <Redirect to='/settings'></Redirect>
            )
        }
        return (
            <>
                {this.state.showModal ? <AssignUser handleSelect={this.handleCallback} handleCancel={this.handleCancel} /> : <></>}
                <form onSubmit={(evt) => this.handleSubmit(evt)} className="create-edit-form">
                    <h3 className="page-text">Create User</h3>
                    <div className="input-group mb-20">
                        <div className="input-header">
                            SSO Shared User ID
                        </div>
                        <div className="input-box">
                            <input onChange={this.handleFieldChange} type="text" id="suid" name="suid" autoComplete="off" value={this.state.user.suid} />
                        </div>
                    </div>
                    <div className="input-group mb-20">
                        <div className="input-header">
                            Business Name
                        </div>
                        <div className="input-box">
                            <input onChange={this.handleFieldChange} type="text" id="businessName" name="businessName" autoComplete="off" value={this.state.user.businessName} />
                        </div>
                    </div>
                    <div className="input-group mb-20">
                        <div className="input-header">
                            First Name
                        </div>
                        <div className="input-box">
                            <input onChange={this.handleFieldChange} type="text" id="firstName" name="firstName" autoComplete="off" value={this.state.user.firstName} />
                        </div>
                    </div>
                    <div className="input-group mb-20">
                        <div className="input-header">
                            Last Name
                        </div>
                        <div className="input-box">
                            <input type="text" onChange={this.handleFieldChange} name="lastName" id="lastName" value={this.state.user.lastName} autoComplete="off" />
                        </div>
                    </div>
                    <div className="input-group mb-20">
                        <div className="input-header">
                            Email
                        </div>
                        <div className="input-box">
                            <input type="text" onChange={this.handleFieldChange} name="email" id="email" value={this.state.user.email} autoComplete="off" />
                        </div>
                    </div>
                    <div className="input-group mb-20">
                        <div className="input-header">
                            Role
                        </div>
                        <div className="input-box">
                            <select
                                type='text'
                                name="isAdmin"
                                id="isAdmin"
                                defaultValue={this.state.user.isSubUser === 'subuser' ? 'subuser' : this.state.user.isAdmin.toString()}
                                onChange={this.handleFieldChange}
                            >
                                <option value="false">Normal user</option>
                                <option value="subuser">Sub User</option>
                                <option value="true">Admin</option>
                            </select>
                        </div>
                    </div>
                    <div className="assign-module">
                        <FormControl
                            className="assign-module">
                            <label className="page-text">Assign Modules</label>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox color="primary"
                                            value={this.state.achModule}
                                            onChange={this.handleModuleChange}
                                            name="achModule"
                                        />
                                    }
                                    label={`${configData.modules[0]?.name ?? "Module 0"}`}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={this.state.wireModule}
                                            onChange={this.handleModuleChange}
                                            name="wireModule" color="primary"
                                        />
                                    }
                                    label={`${configData.modules[1]?.name ?? "Module 1"}`}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={this.state.positivePayModule}
                                            onChange={this.handleModuleChange}
                                            name="positivePayModule"
                                            color="primary"
                                        />
                                    }
                                    label={`${configData.modules[2]?.name ?? "Module 2"}`}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={this.state.integratedModule}
                                            onChange={this.handleModuleChange}
                                            name="integratedModule"
                                            color="primary"
                                        />
                                    }
                                    label={`${configData.modules[3]?.name ?? "Module 3"}`}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={this.state.alloyModule}
                                            onChange={this.handleModuleChange}
                                            name="alloyModule"
                                            color="primary"
                                        />
                                    }
                                    label={`${configData.modules[4]?.name ?? "Module 4"}`}
                                />
                                 <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={this.state.elavonModule}
                                            onChange={this.handleModuleChange}
                                            name="elavonModule"
                                            color="primary"
                                        />
                                    }
                                    label={`${configData.modules[5]?.name ?? "Module 5"}`}
                                />
                            </FormGroup>
                            <FormHelperText >
                                Please assign proper modules for user.
                            </FormHelperText>
                        </FormControl>
                    </div>
                    {
                        this.state.messages['error'] &&
                        <div className="page-error login-error">
                            <div className="login-error-header">We're sorry, but something went wrong:</div>
                            <ul className="page-error-body">
                                {
                                    this.state.messages["error"] &&
                                    <li>
                                        {this.state.messages["error"]}
                                    </li>
                                }
                            </ul>
                        </div>
                    }
                    {
                        this.state.messages['success'] &&
                        <div className="page-error login-error success-message">
                            <div className="login-error-header">Success!</div>
                            <ul className="page-error-body">
                                {
                                    this.state.messages["success"] &&
                                    <li>
                                        {this.state.messages["success"]}
                                    </li>
                                }
                            </ul>
                        </div>
                    }
                    <button
                        className="page-button"
                        disabled={this.state.loadingStatus}
                        type="submit">
                        Create
                    </button>
                </form>
            </>
        );
    }
}
