import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from '../assets/logo.jpg';
import { config as configData } from '../config';
const moment = require("moment");

// define a generatePDF function that accepts a tickets argument
export const generateStatementPDF = (allTransactions, month, account, memberName, businessName) => {
    let oneMonthTransactions = []

    let monthEndBalance = numberDeformat(account.currentBalance);

    allTransactions.forEach((transaction) => {
        let currentTransactionDate = moment(transaction.date);

        //Need to subtract all deposits / withdrawals from current balance
        if (currentTransactionDate.isAfter(month) && monthFormat(transaction.date) !== month) {
            monthEndBalance -= numberDeformat(transaction.amount);
        }
        if (monthFormat(transaction.date) === month) {
            oneMonthTransactions.push(transaction);
        }
    });

    const transactionColumns = ["Date", "Description", "Amounts Added", "Amounts Deducted", "Balance"];
    const transactionRows = [];

    let totalDeposits = 0;
    let totalWithdrawals = 0;

    oneMonthTransactions.forEach(transaction => {
        let deposit = "";
        let withdrawal = "";
        let balanceAfterThisTransaction = monthEndBalance - totalDeposits - totalWithdrawals;

        if (transaction.type === "deposit") {
            deposit = transaction.amount;
            totalDeposits += numberDeformat(transaction.amount);
        } else if (transaction.type === "withdrawal") {
            withdrawal = transaction.amount;
            totalWithdrawals += numberDeformat(transaction.amount);
        }

        const transactionData = [
            dayFormat(transaction.date),
            transaction.description,
            deposit,
            withdrawal,
            numberFormat(balanceAfterThisTransaction)
        ];
        transactionRows.push(transactionData);
    });

    let monthBefore = monthBeforeFormat(month);

    let monthBeginBalance = monthEndBalance - totalDeposits - totalWithdrawals;
    let difference = monthEndBalance - monthBeginBalance;

    transactionRows.push([
        `${monthBefore}`,
        `Closing Balance`,
        "",
        "",
        `${numberFormat(monthBeginBalance)}`
    ])

    const overviewColumns = ["Value at beginning of month", "Value at end of month", "Difference this month"];
    const overviewRows = [[`${numberFormat(monthBeginBalance)}`, `${numberFormat(monthEndBalance)}`, `${difference > 0 ? "+" : ""}${numberFormat(difference)}`]]

    const activityColumns = ["", "Changes", "Totals"]

    const activityRows = [
        [
            "Opening Balance",
            "",
            `${numberFormat(monthBeginBalance)}`
        ],
        [
            "Plus deposits from checking",
            `${numberFormat(totalDeposits)}`,
            ""
        ],
        [
            "Total amounts added",
            "",
            `${numberFormat(totalDeposits)}`
        ],
        [
            "Less withdrawals and other deductions",
            `${numberFormat(totalWithdrawals)}`,
            ""
        ],
        [
            "Total amounts deducted",
            "",
            `${numberFormat(totalWithdrawals)}`
        ],
        [
            "Closing cash balance",
            "",
            `${numberFormat(monthEndBalance)}`
        ]
    ]

    const doc = new jsPDF('p', 'pt', 'letter');
    doc.setFontSize(14);

    const margin = 30;
    const logoHeight = 70;
    const paddingBetweenItems = 15;
    const pdfWidth = doc.internal.pageSize.getWidth();
    const tableFillColor = configData.colors.secondary;

    let pdfLogo = configData.logo.length > 0 ? configData.logo : logo;
    // TODO GRAB CURRENT URL INSTEAD OF HARDCODING
    let apiUrl = configData.remoteUrl + "/apis/v1";
    // Proxy the image so we don't have CORS errors.
    pdfLogo = apiUrl + "/image?url=" + pdfLogo;
    let img = new Image();
    let imgRatio = 0;
    img.src = pdfLogo;
    img.onload = function () {
        imgRatio = this.width / this.height;
    }

    try {
        doc.addImage(pdfLogo, 'JPEG', margin, margin, imgRatio * (logoHeight - 5), (logoHeight - 5));
    } catch (e) {
        console.log("Failed to add logo: ", e);
    }
    const headerY = margin + logoHeight + paddingBetweenItems;
    doc.text(`Your monthly ${account.name}`, pdfWidth - margin, margin + (paddingBetweenItems * 2), { align: 'right' });
    doc.text(`statement for ${month}`, pdfWidth - margin, margin + (paddingBetweenItems * 3), { align: 'right' });

    doc.text(`${businessName}`, margin, headerY);
    doc.text(`${account.number}`, pdfWidth - margin, headerY, { align: 'right' });

    const overviewY = headerY + (paddingBetweenItems * 3)
    doc.setFont(undefined, "bold");
    doc.text(`Account Summary`, pdfWidth / 2, overviewY, { align: "center" });
    doc.setFont(undefined, "normal");

    doc.autoTable({
        head: [overviewColumns],
        body: overviewRows,
        theme: 'striped',
        startY: overviewY + paddingBetweenItems,
        headStyles: { fillColor: tableFillColor, halign: "center", valign: 'middle' },
        columnStyles: {
            0: { halign: 'center', valign: 'middle' },
            1: { halign: 'center', valign: 'middle' },
            2: { halign: 'center', valign: 'middle' }
        },
        didParseCell: function (data) {
            if (data.column.index === 2 && data.cell.text[0].includes("+")) {
                data.cell.styles.textColor = 'green';
            }
            if (data.column.index === 2 && data.cell.text[0].includes("-")) {
                data.cell.styles.textColor = 'red';
            }
        }
    })

    const activityY = doc.lastAutoTable.finalY + (paddingBetweenItems * 3)
    doc.setFont(undefined, "bold");
    doc.text(`Activity Summary`, pdfWidth / 2, activityY, { align: "center" });
    doc.setFont(undefined, "normal");

    doc.autoTable({
        head: [activityColumns],
        body: activityRows,
        theme: 'striped',
        startY: activityY + paddingBetweenItems,
        headStyles: { fillColor: tableFillColor, halign: "center", valign: 'middle' },
        columnStyles: {
            1: { halign: 'center', valign: 'middle' },
            2: { halign: 'center', valign: 'middle' }
        },
        didParseCell: function (data) {
            if ((data.row.index === 0 || data.row.index === 2 || data.row.index === 4 || data.row.index === 5) && data.row.section !== 'head') {
                data.cell.styles.fontStyle = 'bold';
                data.cell.styles.fillColor = 245;
            }
            if (data.row.index === 1 && data.column.index === 1) {
                data.cell.styles.textColor = 'green';
            }
            if (data.row.index === 3 && data.column.index === 1) {
                data.cell.styles.textColor = 'red';
            }
        },
        willDrawCell: function (data) {
            if ((data.row.index === 1 || data.row.index === 3 || data.row.index === 5) && data.row.section !== 'head') {
                doc.setDrawColor(tableFillColor)
                doc.setLineWidth(.5);
                if (data.row.index === 5) {
                    doc.setLineWidth(2);
                }
                doc.line(data.cell.x, data.cell.y - .5, data.cell.x + data.cell.width, data.cell.y - .5);
            }
        }
    })

    const transactionY = doc.lastAutoTable.finalY + (paddingBetweenItems * 3)
    doc.setFont(undefined, "bold");
    doc.text(`Transaction Summary`, pdfWidth / 2, transactionY, { align: "center" });
    doc.setFont(undefined, "normal");

    // startY is basically margin-top
    doc.autoTable({
        head: [transactionColumns],
        body: transactionRows,
        theme: 'striped',
        startY: transactionY + paddingBetweenItems,
        headStyles: { fillColor: tableFillColor },
        columnStyles: {
            3: { textColor: 'red', halign: 'center', valign: 'middle' },
            2: { textColor: 'green', halign: 'center', valign: 'middle' }
        },
        didParseCell: function (data) {
            if ((data.column.index === 2 || data.column.index === 3 || data.row.index === 4) && data.row.section === 'head') {
                data.cell.styles.halign = 'center';
                data.cell.styles.valign = 'middle';
            }
            if (data.row.index === transactionRows.length - 1) {
                data.cell.styles.fontStyle = 'bold';
                data.cell.styles.fillColor = 245;
            }
        },
        willDrawCell: function (data) {
            if (data.row.index === transactionRows.length - 1) {
                doc.setDrawColor(tableFillColor)
                doc.setLineWidth(2);
                doc.line(data.cell.x, data.cell.y - .5, data.cell.x + data.cell.width, data.cell.y - .5);
            }
        }
    })

    let filenameWithSpaces = `report_${memberName}_${account.name}_${month}.pdf`
    let finalFilename = filenameWithSpaces.replace(/[/\\?%*:|"<> ]/g, "_");
    doc.save(finalFilename);
};

const monthFormat = (date) => {
    return moment(date).format("MMM YYYY");
}

const dayFormat = (date) => {
    return moment(date).format("MM/DD")
}

const monthBeforeFormat = (date) => {
    return moment(date).subtract(1, 'month').endOf('month').format("MMM DD")
}

const numberDeformat = (amountString) => {
    return parseFloat(amountString.replace(/[$,]/g, ""));
}

const numberFormat = (num) => {
    num = String(num);
    if (num !== null) {
        let numFormat = new Intl.NumberFormat("en-US", {
            //should look like "$50" or "$50.34"
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        return numFormat.format(num);
    }
}