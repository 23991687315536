import { config as configData } from './config';
import { checkInactivity, updateLastActive } from "./modules/LoginManager";
const INACTVITY_CHECK_PERIOD_IN_MILLISECONDS = 10000;
//Sets global event listeners to update the user's last active time if they click or press keys
//Every 10 seconds, checks to see if the user has been inactive for more than 30 minutes
export const appInit = () => {
    initColors();

    window.addEventListener("click", updateLastActive);
    window.addEventListener("keydown", updateLastActive);

    return setInterval(() => {
        checkInactivity();
    }, INACTVITY_CHECK_PERIOD_IN_MILLISECONDS);
}

export const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
    }
    else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return "mobile";
    }
    return "desktop";
};

// Clear timer and event listeners if the component is unmounted
export const appClose = (timer) => {
    window.removeEventListener("click", updateLastActive);
    window.removeEventListener("keydown", updateLastActive);
    clearInterval(timer);
}

const initColors = () => {
    const root = document.documentElement;
    root.style.setProperty('--background', configData.colors.background);
    root.style.setProperty('--primary', configData.colors.primary);
    root.style.setProperty("--secondary", configData.colors.secondary);
    root.style.setProperty('--pageButtons', configData.colors.pageButtons);
    root.style.setProperty('--pageButtonsText', configData.colors.pageButtonsText);
    root.style.setProperty('--hover', configData.colors.hover);
    root.style.setProperty('--elementButtons', configData.colors.elementButtons);
    root.style.setProperty('--elementButtonsText', configData.colors.elementButtonsText);
    root.style.setProperty("--links", configData.colors.links);
    root.style.setProperty('--loadingSpinner', configData.colors.loadingSpinner);
    root.style.setProperty('--primaryText', configData.colors.primaryText);
    root.style.setProperty('--secondaryText', configData.colors.secondaryText);
    root.style.setProperty('--border', configData.colors.border);
    root.style.setProperty('--tableHeaderText', configData.colors.tableHeaderText);
};
