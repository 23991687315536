import { config } from '../config';
import { checkTokenExpiration, getToken } from './LoginManager';

export const getAllSso = () => {
    const token = getToken();
    return fetch(`${config.remoteUrl}/sso`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
    .then(checkTokenExpiration)
    .then((data) => data.json());
}

export const saveAllSso = (editedCredentials) => {
    const token = getToken();
    return fetch(`${config.remoteUrl}/sso/update`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({editedSsoCredentials: editedCredentials})
    })
    .then(checkTokenExpiration)
    .then((data) => data.json());
}

export const getSingleSso = (ssoLinkId) => {
    const token = getToken();
    return fetch(`${config.remoteUrl}/sso/${ssoLinkId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
    .then(checkTokenExpiration)
    .then((data) => data.json());
}

export const createSAMLResponse = async (samlRequest, relayState) => {

    let currentSsoCred
    //index for rdc (aka payRec or integratedRec) is 3
    await getSingleSso(3).then((singleSsoCred) => {
        if (singleSsoCred.status === "success") {
            currentSsoCred = singleSsoCred.data;
        }
        else (alert("Failed to retrieve credentials for RDC"))
    })

    const userToken = sessionStorage.getItem("token");

    return fetch(`${config.remoteUrl}/sso/saml/rdc`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userToken}`
        },
        body: JSON.stringify({
            samlRequest: samlRequest,
            relayState: relayState,
            //passing username and password(site abbreviation) from currentSsoCred
            userData: currentSsoCred
        }),
    })
        .then((res) => {
            if (!res.ok) {
                alert("Failed to create samlResponse")
            }
            return res.json();
        });
};