import { Switch, styled } from '@material-ui/core';
import { config, config as configData } from '../config';
import { checkTokenExpiration } from './LoginManager';
import { grey } from "@material-ui/core/colors";

export const handlePlaidCreateLinkToken = () => {
    const token = sessionStorage.getItem('token');
    return fetch(`${config.remoteUrl}/plaid/create_link_token`, {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`,
        },
    })
        .then(checkTokenExpiration)
        .then((response) => {
            if (!response.ok) {
                throw new Error(response.message);
            }
            const data = response.json();
            if (data) {
                if (data.error != null) {
                    throw new Error(data.error);
                }
            }
            return data;
        });
};
export const checkPlaidEnabled = () => {
    if (configData.isPlaidEnabled === 'false') return false;
    return configData.isPlaidEnabled === 'true';
}

export const PlaidSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: config.colors.pageButtons,
        '&:hover': {
            backgroundColor: (config.colors.pageButtons, theme.palette.action.hoverOpacity)
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: grey[600],
    },

}
)

);
;

export const handlePlaidExchangePublicToken = (public_token) => {
    const token = sessionStorage.getItem('token');
    return fetch(`${config.remoteUrl}/plaid/set_access_token`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ public_token: public_token }),
    })
        .then(checkTokenExpiration)
        .then((response) => {
            if (!response.ok) {
                throw new Error(response.message);
            }
        });
};

export const handleGetUserAccessTokens = () => {
    const token = sessionStorage.getItem('token');
    return fetch(`${config.remoteUrl}/plaid/get_user_access_tokens`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        }
    })
        .then(checkTokenExpiration)
        .then((response) => {
            return response;
        });
};

export const handlePlaidInitUserData = () => {
    const token = sessionStorage.getItem('token');
    return fetch(`${config.remoteUrl}/plaid/init_user_data`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        }
    })
        .then(checkTokenExpiration)
        .then((response) => {
            if (!response.ok) {
                // return {status: "error"}
            }
        })
};

export const handlePlaidGetAccounts = () => {
    const token = sessionStorage.getItem('token');
    return fetch(`${config.remoteUrl}/plaid/get_accounts`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        }
    })
        .then(checkTokenExpiration)
        .then((response) => {
            if (!response.ok) {
                // return {status: "error"};
            }
            const data = response.json();
            if (data) {
                if (data.error != null) {
                    // return {status: "error"};
                }
            }
            return data;
        });
};

export const handlePlaidGetTransactions = (accountId) => {
    const token = sessionStorage.getItem('token');
    return fetch(`${config.remoteUrl}/plaid/get_transactions`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ account_id: accountId })
    })
        .then(checkTokenExpiration)
        .then((response) => {
            if (!response.ok) {
                // return {status: "error"};
            }
            const data = response.json();
            if (data) {
                if (data.error != null) {
                    // return {status: "error"};
                }
            }
            return data;
        });
};

export const handlePlaidRenameAccount = (accountId, newName) => {
    const token = sessionStorage.getItem('token');
    return fetch(`${config.remoteUrl}/plaid/rename_account`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ account_id: accountId, new_account_name: newName })
    })
        .then(checkTokenExpiration)
        .then((response) => {
            if (!response.ok) {
                // return {status: "error"};
            }
            const data = response.json();
            if (data) {
                if (data.error != null) {
                    // return {status: "error"};
                }
            }
            return data;
        });
};

