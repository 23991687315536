import { config } from '../config';
import { checkTokenExpiration, getToken } from './LoginManager';

const ERROR_QA_PAIRS = [
    {
        faqid: 1,
        question: "Where are all the FAQs?",
        answer: "Sorry! It looks like we couldn't fetch any FAQs from the server. Please contact the administrator."
    }
]
const NO_QA_PAIRS = [
    {
        faqid: 1,
        question: "Why am I seeing this?",
        answer: "There are no FAQs in your database."
    },
    {
        id: 2,
        question: "What can I do?",
        answer: "Don't worry: this is just a test to make sure the page can load data. Try adding an FAQ to the database, and see if this gets resolved."
    }
]

export const getFaq = () => {
    const token = getToken();
    return fetch(`${config.remoteUrl}/faq`, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(checkTokenExpiration)
        .then((result) => {
            let resultsFromApi = result.json()
            if (resultsFromApi === undefined || resultsFromApi === 0) {
                return NO_QA_PAIRS;
            }
            return resultsFromApi
        })
        .catch((error) => {
            return ERROR_QA_PAIRS;
        });;
}

export const saveFaq = (editedQuestionAnswerPairs) => {
    const token = getToken();
    return fetch(`${config.remoteUrl}/faq/update`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ editedQuestionAnswerPairs: editedQuestionAnswerPairs }),
    })
    .then(checkTokenExpiration)
    .then((data) => data.json());
}