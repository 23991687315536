import { Component } from "react";
import { createSAMLResponse } from "../../modules/SsoManager";
import "../pages.css";
import { isLoggedIn } from "../../modules/LoginManager";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

export class RdcSsoRedirect extends Component {

    constructor(props) {
        super(props);

        //use URLSearchParams to pull SAMLRequest and RelayState Query Params from current URL
        const searchParams = new URLSearchParams(this.props.location.search);

        this.state = {
            samlRequest: searchParams.get('SAMLRequest') || '',
            relayState: searchParams.get('RelayState') || '',
            loggedIn: isLoggedIn()
        }
    }

    // takes response from createSAMLResponse and redirects to Login page with needed data
    handleRedirect = (loginResponse) => {
        console.log("redirecting to " + loginResponse.entityEndpoint);
        const data = {
            samlResponse: loginResponse.samlResponse,
            acsUrl: loginResponse.entityEndpoint,
            relayState: loginResponse.relayState
        };
        this.props.history.push('/sso/rdc/login', { state: data });
    };

    handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }
        const { samlRequest, relayState } = this.state;
        createSAMLResponse(samlRequest, relayState)
            .then((resp) => this.handleRedirect(resp));
    }

    componentDidMount() {
        //AUTOSUBMIT FORM IF USER LOGGED IN
        if (this.state.loggedIn) {
            setTimeout(() => {
                this.handleSubmit();
            }, 500);
        }
    }

    render() {

        // If user is not login, send user to login screen INLCUDING SAMLRequest and RelayState query params
        // After successful login, they will be redirected back to this page to continue sso flow
        if (!this.state.loggedIn) {
            const searchParams = new URLSearchParams(this.props.location.search);
            const urlWithQueryParams = `/?SAMLRequest=${searchParams.get('SAMLRequest')}&RelayState=${searchParams.get('RelayState')}`
            return (
                <Redirect to={urlWithQueryParams}></Redirect>
            )
        }

        const { samlRequest, relayState } = this.state;

        return (

            <div className="rdcsso-form-container">

                <form className='rdcsso-form' onSubmit={(event) => this.handleSubmit(event)}>

                    {/* TO HIDE FORM, CHANGE TEXTAREA TO INPUT TYPE='HIDDEN', & COMMENT EVERYTHING ELSE OUT*/}

                    {/* <h1>redirecting to... /sso/rdc/login</h1> */}
                    {/* <label className="rdcsso-label" htmlFor="SAMLRequest">SAML Request</label> */}
                    <input type='hidden' rows="4" cols="50" id="SAMLRequest" name="SAMLRequest" value={samlRequest} />

                    {/* <label className="rdcsso-label" htmlFor="RelayState">Relay State</label> */}
                    <input type='hidden' rows="4" cols="50" id="RelayState" name="RelayState" value={relayState} />

                    {/* <button className="rdcsso-button" type="submit">
                        Create SAMLResponse and Redirect to Login
                    </button> */}

                </form>

            </div>
        )

    }
}

