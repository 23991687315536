import React, { Component } from 'react';
import { Grid } from '@material-ui/core';

export class PrivacyPolicy extends Component {

    render() {
        return (
            <Grid columns={1} spacing={2} style={{ color: 'black', backgroundColor: 'white', padding: 2 }}>
                <div style={{ padding: 10 }}>
                    <h3 className="page-text">Privacy Policy</h3>

                    <h4>Effective Date: October 1, 2021</h4>

                    <p>
                        Privacy and security are very important to us. This End User Privacy Policy(“Policy”) is meant to help you(the “end user”) understand how our online solutions vendor and Tru Treasury(“Vendor” collects, use, and share end user information in our possession to operate, improve, develop, and protect our services, and as otherwise outlined in this Policy. Please take some time to read this Policy carefully.
                    </p>

                    <h4>About this Policy</h4>

                    <p>
                        Our goal with this Policy is to provide a simple and straightforward explanation of what information The Vendor collects from and about end users(“End User Information”), and how we use and share that information. We value transparency and want to provide you with a clear and concise description of how we treat your End User Information.
                    </p>

                    <p>
                        Please note that this Policy only covers the information that The Vendor collects, uses, and shares. It does not explain what developers do with any End User Information we provide to them(or any other information they may collect about you separately from The Vendor). This Policy also does not cover any websites, products, or services provided by others. We encourage you to review the privacy policies or notices of developers or those third parties for information about their practices.
                    </p>

                    <h4>
                        Our Data Practices
                    </h4>

                    <h5>
                        Information We Collect and Categories of Sources
                    </h5>

                    <p>
                        As explained in greater detail below, The Vendor has collected identifiers, location information, commercial information, electronic network activity information, professional information, inferences, and other types of End User Information.
                    </p>

                    <p>
                        <b>Information you provide.</b> When you connect your financial accounts with a developer application or otherwise connect your financial accounts through The Vendor, where applicable, we collect identifiers and login information required by the provider of your account, such as your username and password, or a security token. In some cases, we also collect your phone number, email address, security questions and answers, and one - time password(OTP) to help verify your identity before connecting your financial accounts. When providing this information, you give the developer and The Vendor the authority to act on your behalf to access and transmit your End User Information from the relevant bank or other entity that provides your financial accounts(we’ll call them “financial product and service providers” in this Policy). You may also provide us with identifiers and other information, including your name, email address, and phone number, when you contact us or enter any such information on our websites.
                    </p>

                    <p>
                        <b>Information we collect from your financial accounts.</b> The information we receive from the financial product and service providers that maintain your financial accounts varies depending on a number of factors, including the specific The Vendor services developers use, as well as the information made available by those providers. But, in general, we collect the following types of identifiers, commercial information, and other personal information from your financial product and service providers:
                    </p>

                    <ul>
                        <li>Account information, including financial institution name, account name, account type, account ownership, branch number, IBAN, BIC, account number, routing number, and sort code;</li>
                        <li>Information about an account balance, including current and available balance;</li>
                        <li>Information about credit accounts, including due dates, balances owed, payment amounts and dates, transaction history, credit limit, repayment status, and interest rate;</li>
                        <li>Information about loan accounts, including due dates, repayment status, balances, payment amounts and dates, interest rate, guarantor, loan type, payment plan, and terms;</li>
                        <li>Information about investment accounts, including transaction information, type of asset, identifying details about the asset, quantity, price, fees, and cost basis;</li>
                        <li>Identifiers and information about the account owner(s), including name, email address, phone number, date of birth, and address information;</li>
                        <li>Information about account transactions, including amount, date, payee, type, quantity, price, location, involved securities, and a description of the transaction; and</li>
                        <li>Professional information, including information about your employer, in limited cases where you’ve connected your payroll accounts or provided us with your pay stub information.</li>
                    </ul>

                    <p>
                        The data collected from your financial accounts includes information from all accounts(e.g., checking, savings, and credit card) accessible through a single set of account credentials.
                    </p>

                    <p>
                        <b>Information we receive from your devices.</b> When you use your device to connect to our services through a developer’s application, we receive identifiers and electronic network activity information about that device, including internet protocol(IP) address, timezone setting and location, device location, hardware model, operating system, which features within our services you access, browser data, and other technical information about the device. We also use cookies or similar tracking technologies to collect usage statistics and to help us provide and improve our services.
                    </p>

                    <p>
                        <b>Information we receive about you from other sources.</b> We also receive identifiers and commercial information about you directly from the relevant developer or other third parties, including our service providers, bank partners, and identity verification services. For example, developers may provide information such as your full name, email address, phone number, or information about your financial accounts and account transactions, and our bank partners or service providers may provide information such as the status of a transaction you have initiated.
                    </p>

                    <p>
                        <b>Inferences we derive from the data we collect.</b> We may use the information we collect about you to derive inferences. For example, we may infer your types and volumes of services used based on the information we have collected about you from you or other sources.
                    </p>

                    <h4>
                        How We Use Your Information
                    </h4>

                    <p>
                        We use your End User Information for a number of business and commercial purposes, including to operate, improve, and protect the services we provide, and to develop new services. More specifically, we use your End User Information:
                    </p>

                    <ul>
                        <li>To operate, provide, and maintain our services;</li>
                        <li>To improve, enhance, modify, add to, and further develop our services;</li>
                        <li>To provide customer support to you or to developers, including to help respond to your inquiries related to our service or developers’ applications;</li>
                        <li>To develop new services;</li>
                        <li>To provide customer support to you or to developers, including to help respond to your inquiries related to our service or developers’ applications;</li>
                        <li>To investigate any misuse of our service or developers’ applications, including violations of our Developer Policy, criminal activity, or other unauthorized access to our services;</li>
                        <li>To comply with contractual and legal obligations under applicable law; and</li>
                        <li>For other notified purposes with your consent.</li>
                    </ul>

                    <h4>
                        How We Share Your Information
                    </h4>

                    <p>
                        We share your End User Information for a number of business purposes:
                    </p>

                    <ul>
                        <li>With the developer of the application you are using and as directed by that developer(such as with another third party if directed by you);</li>
                        <li>To enforce any contract with you;</li>
                        <li>With our data processors and other service providers, partners, or contractors in connection with the services they perform for us or developers;</li>
                        <li>With your connected financial institution(s) to help establish or maintain a connection you’ve chosen to make;</li>
                        <li>If we believe in good faith that disclosure is appropriate to comply with applicable law, regulation, or legal process(such as a court order or subpoena);</li>
                        <li>In connection with a change in ownership or control of all or a part of our business(such as a merger, acquisition, reorganization, or bankruptcy);</li>
                        <li>Between and among The Vendor and our current and future parents, affiliates, subsidiaries and other companies under common control or ownership;</li>
                        <li>As we believe reasonably appropriate to protect the rights, privacy, safety, or property of you, developers, our partners, The Vendor, and others; or</li>
                        <li>For any other notified purpose with your consent.</li>
                    </ul>

                    <p>
                        We may collect, use, and share End User Information in an aggregated, de - identified, or anonymized manner(that does not identify you personally) for any purpose permitted under applicable law. This includes creating or using aggregated, de - identified, or anonymized data based on the collected information to develop new services and to facilitate research.
                    </p>

                    <p>
                        We do not sell or rent personal information that we collect.
                    </p>

                    <p>
                        In the preceding 12 months, we have disclosed the following categories of personal information for business purposes to the following categories of recipients:
                    </p>

                    <p><b>CATEGORIES OF PERSONAL INFORMATION</b></p>
                    <p><b>CATEGORIES OF PARTIES WITH WHOM PERSONAL INFORMATION MAY BE SHARED</b></p>

                    <ul>
                        <li>Identifiers</li>
                        <li>Financial information</li>
                        <li>Commercial information</li>
                        <li>Location information</li>
                        <li>Professional information</li>
                        <li>Electronic network activity information</li>
                        <li>Inferences</li>
                        <li>Developer(s) of application(s) you use, and as directed by the developer</li>
                        <li>Financial institutions</li>
                        <li>Providers of payments services</li>
                        <li>Partners and contractors in connection with the services they perform for us or developers</li>
                        <li>Professional advisors</li>
                        <li>Data analytics providers</li>
                        <li>Cloud storage providers</li>
                        <li>Software development services providers</li>
                        <li>Fraud prevention services providers</li>
                        <li>Identity verification services providers</li>
                        <li>The Vendor group entities</li>
                        <li>Third parties when compelled by law, such as to law enforcement or other governmental authorities</li>
                    </ul>

                    <h5>Our Retention Practices</h5>

                    <p>
                        We retain End User Information for no longer than necessary to fulfill the purposes for which it was collected and used, as described in this Policy, unless a longer retention period is required or permitted under applicable law. As permitted under applicable law, even after you stop using an application or terminate your account with one or more developer, we may still retain your information(for example, if you still have an account with another developer). However, your information will only be processed as required by law or in accordance with this Policy.
                    </p>

                    <p>
                        Please refer to the “Your Data Protection Rights” section for options that may be available to you, including the right to request deletion of End User Information. You can also contact us about our data retention practices using the contact information below.
                    </p>

                    <h5>Your Data Protection Rights</h5>

                    <p>
                        Under applicable law, and subject to limitations and exceptions provided by law, if you are located in certain other jurisdictions, you may have certain rights in relation to the End User Information collected about you and how it is used, including the right to:
                    </p>

                    <ul>
                        <li>Access End User Information collected about you;</li>
                        <li>Request that we rectify or update your End User Information that is inaccurate or incomplete;</li>
                        <li>Request, under certain circumstances, that we restrict the processing of or erase your End User Information;</li>
                        <li>Object to our processing of your End User Information under certain conditions provided by law;</li>
                        <li>Where processing of your End User Information is based on consent, withdraw that consent;</li>
                        <li>Request that we provide End User Information collected about you in a structured, commonly used and machine - readable format so that you can transfer it to another company, where technically feasible; and</li>
                    </ul>

                    <p>
                        Under the California Consumer Privacy Act(“CCPA”), and subject to certain limitations and exceptions, if you are a California resident, you may have the following rights with respect to End User Information we have collected about you that constitutes personal information under the CCPA:
                    </p>

                    <ul>
                        <li>To request access to more details about the categories and specific pieces of personal information we may have collected about you in the last 12 months(including personal information disclosed for business purposes);</li>
                        <li>To request deletion of your personal information;</li>
                        <li>To opt - out of any “sales” of your personal information, if a business is selling your information; and</li>
                        <li>To not be discriminated against for exercising these rights.</li>
                    </ul>

                    <p>
                        To exercise your access or deletion rights, where applicable, you can submit a request to DL.TTOperationsSupport@trutreasury.com. You can also contact us as described in the “Contacting Tru Treasury” section below to exercise any of your data protection rights, where applicable. You may be required to provide additional information necessary to confirm your identity before we can respond to your request.
                    </p>

                    <p>
                        We will consider all such requests and provide our response within a reasonable period of time(and within any time period required by applicable law). Please note, however, that certain information may be exempt from such requests, for example if we need to keep the information to comply with our own legal obligations or to establish, exercise, or defend legal claims.
                    </p>

                    <h5>
                        Changes To This Policy
                    </h5>

                    <p>
                        We may update or change this Policy from time to time. We will notify developers of any material changes in accordance with our developer agreements, as they are generally best positioned to notify their end users about such changes to this Policy, as appropriate.
                    </p>

                    <h5>
                        Contacting Tru Treasury
                    </h5>

                    <p>
                        If you have any questions or complaints about this Policy, or about our privacy practices generally, you can contact us at DL.TTOperationsSupport@trutreasury.com or by mail at:
                        Tru Treasury<br />
                        Attn: Legal<br />
                        3692 Coolidge Court<br />
                        Tallahassee FL 32311<br />
                    </p>

                    <h3>
                        End User Services Agreement(US)
                    </h3>

                    <p>
                        Effective Date: October 1, 2021
                    </p>

                    <p>
                        This End User Services Agreement(US)(“Agreement”) is an agreement between you and Leverage CUSO 1 d / b / a Tru Treasury(“Tru Treasury”, “we” or “us”). By accepting this Agreement or accessing Tru Treasury provided applications, you agree to this Agreement and certify that you have all necessary rights to do so. If you are accepting on behalf of your employer or another entity, you represent and warrant that: (i) you have full legal authority to bind your employer or such entity to this Agreement; (ii) you have read and understand this Agreement; and(iii) you agree to this Agreement on behalf of the party that you represent. If you do not have the legal authority to bind your employer or the applicable entity, please do not accept this Agreement or access the features covered by this Agreement.
                    </p>

                    <h4>Control and Responsibilities.</h4>

                    <p>
                        You represent and warrant that you have all necessary rights to use your Accounts and Apps with the Platform, and you agree to comply with all laws and regulations applicable to your use, as well as any rules and guidelines that we post. You must not(1) use or access anyone else’s Accounts or related data, (2) submit information about anyone else’s identity or Accounts or that violates any third - party rights or(3) use the Platform for any fraudulent, illegal or misleading purpose. You also agree not to(a) modify, reverse engineer or seek to gain unauthorized access to the Platform or related systems, data or source code, (b) bypass or circumvent measures designed to prevent or limit access to any part of the Platform, (c) rent, lease, provide access to or sublicense any elements of the Platform to a third party or use the Platform on behalf of or to provide services to third parties, (d) copy, modify or create derivative works of the Platform or remove any of The Vendor’s proprietary notices, (e) access the Platform for competitive purposes or publish any benchmark or performance information about the Platform, or(f) use the Platform in any manner that could damage, disable, overburden, or impair the functioning of the Platform or interfere with, disrupt or negatively affect other users.
                    </p>

                    <h4>Rights to the Platform.</h4>

                    <p>
                        Note that Tru Treasury owns all right, title and interest(including intellectual property rights) in and to the Platform and our related websites and technology. If you choose to give us feedback, suggestions or other inputs about the Platform, we may use them without restriction.
                    </p>

                    <h4>Our Disclaimers.</h4>

                    <p>
                        TO THE EXTENT PERMITTED BY LAW, THE PLATFORM IS PROVIDED “AS IS” AND “AS AVAILABLE,” AND ANY USE IS AT YOUR DISCRETION AND RISK. THE VENDOR, ITS AFFILIATES AND ITS AND THEIR SUPPLIERS MAKE NO WARRANTIES, EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE OR NONINFRINGEMENT. THE VENDOR DOES NOT WARRANT THAT USE WILL BE UNINTERRUPTED OR ERROR - FREE, THAT ANY OF YOUR DATA WILL BE ACCURATE OR COMPLETE OR THAT THE VENDOR WILL MAINTAIN ANY DATA WITHOUT LOSS.
                    </p>

                    <p>
                        <i>Liabilities for our Platform.</i> TO THE EXTENT PERMITTED BY LAW, LEVERAGE CUSO 1 d / b / a TRU TUREASURY, ITS AFFILIATES AND ITS AND THEIR SUPPLIERS WILL NOT BE RESPONSIBLE FOR: (A) ANY LOST PROFITS, LOSS OF USE, LOST OR INACCURATE DATA, FAILURE OF SECURITY MECHANISMS, FINANCIAL LOSSES, OR ANY INDIRECT, SPECIAL, INCIDENTAL, RELIANCE OR CONSEQUENTIAL DAMAGES OF ANY KIND OR(B) ANY DAMAGES OR AMOUNTS EXCEEDING, IN THE AGGREGATE, THE GREATER OF(1) THE AMOUNT PAID US TO USE THE PLATFORM AND(2) ONE HUNDRED U.S. DOLLARS(US $100).
                    </p>

                    <h4>
                        Dispute Resolution.
                    </h4>

                    <p>
                        We hope you will have a positive experience using our Platform, but should a dispute between us arise out of or relating to these Terms, we agree to resolve the dispute by following these steps:
                    </p>

                    <ol>
                        <li>Send us a notice, according to the Notices section below, describing the dispute and including all relevant facts so we know how to help you.</li>
                        <li>Within 5 business days after our receipt of your notice, we will reach out to discuss your dispute with you.</li>
                        <li>If we’re not able to resolve your dispute during our discussion, you will send us a written proposal for resolving your dispute.</li>
                        <li>Within 15 business days after our receipt of your written proposal, we will let you know whether we agree to your proposal, or we will provide you with a counter - proposal.</li>
                    </ol>

                    <p>
                        After Step 4, it’s up to you to decide whether you’d like to continue to negotiate with us to resolve your dispute, or whether you’d like to pursue a resolution through some other means.
                    </p>

                    <p>
                        Throughout this process, both you and Tru Treasury agree to negotiate in good faith and according to the terms of this section to resolve the dispute before resorting to litigation or some other form of dispute resolution procedure. All negotiations(including your notice, our discussions, and your and our proposals) pursuant to this section are confidential and treated as compromise and settlement negotiations for the purposes of federal and state rules of evidence and procedure.
                    </p>

                    <h5>
                        Ending This Agreement.
                    </h5>

                    <p>
                        At any time in its discretion, Tru Treasury may terminate, modify or suspend this Agreement(or your use of the Platform) with or without notice and for any or no reason, including if we suspect that you have violated this Agreement. We will have no liability to you for any termination or suspension, nor will such action limit any other rights or remedies. Except for your right to use the Platform, this Agreement will survive any termination.
                    </p>

                    <h5>
                        About This Agreement.
                    </h5>

                    <p>
                        This Agreement may not be transferred or assigned by you without our prior written consent. Tru Treasury may assign or transfer this Agreement to its affiliates or in connection with a merger, sale, reorganization or other change of control. In addition, our affiliates, contractors and service providers may exercise Tru Treasury’s rights or fulfill its obligations under this Agreement. Waivers must be in writing and no waivers will be implied. If any provision of this Agreement is held by a court of competent jurisdiction to be unenforceable for any reason, the remaining provisions will remain unaffected and in full force and effect. This Agreement is the final, complete and exclusive agreement between you and us relating the subject matter of this Agreement and supersedes all prior or contemporaneous understandings and agreements relating to such subject matter, whether oral or written. In this Agreement, headings are for convenience only and the term “including” (and similar terms) will be construed without limitation.
                    </p>

                    <p>
                        Tru Treasury may modify this Agreement from time to time. Unless we specify otherwise, modifications take effect(and govern future use of the Platform) when we post the modified version. The Vendor will use reasonable efforts to notify you of the modifications, and you may be required to agree to the modified version. If you do not agree to the modifications, your sole remedy is to cease using the Platform.
                    </p>

                    <p>
                        End
                    </p>
                </div>
            </Grid >
        );
    }
}
