import { config } from '../config';
import history from '../history';
import { showBanner } from './BroadcastManager';
import { getSingleSso } from './SsoManager';
const MAX_IDLE_MINUTES = 30;
const LOGOUT_ALERT = `Your session has expired. If you'd like to resume your session, please log back in.`;

//Sends a post request of the user's email and password. Should return user info and a JWT
export const handleApiLogin = (email, password) => {
    const subdomain = window.location.host.split('.')[0] ?? "TruTreasury";
    return fetch(`${config.remoteUrl}/users/login`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password, company: subdomain }),
    })
        .then((data) => data.json());
}

//Sends a post request with the user's email, so that a reset email may be sent.
export const handleApiForgotPassword = (email) => {
    return fetch(`${config.remoteUrl}/users/send-email`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
    }).then((data) => data.json());
}

//Sends a put request to the API using the user's id as a route param, the reset JWT as an
//authorization header, and the two typed passwords as the body
export const handleApiResetPassword = (token, userId, newPassword, confirmNewPassword) => {
    return fetch(`${config.remoteUrl}/users/change-password/${userId}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ newPassword: newPassword, confirmNewPassword: confirmNewPassword }),
    })
        .then(checkTokenExpiration)
        .then((data) => data.json());
}

//Accepts the user data/token from the API and saves them to local storage
export const saveLogin = (userDataAndToken) => {
    sessionStorage.setItem('name', userDataAndToken.firstName + ' ' + userDataAndToken.lastName);
    sessionStorage.setItem('email', userDataAndToken.email);
    sessionStorage.setItem('businessName', userDataAndToken.businessName);
    sessionStorage.setItem('token', userDataAndToken.token);
    sessionStorage.setItem('achModule', userDataAndToken.has_ach);
    sessionStorage.setItem('wireModule', userDataAndToken.has_wire);
    sessionStorage.setItem('isSubUser', userDataAndToken.is_sub_user);
    sessionStorage.setItem('positivePayModule', userDataAndToken.has_pp);
    sessionStorage.setItem('integratedModule', userDataAndToken.has_ir);
    sessionStorage.setItem('alloyModule', userDataAndToken.has_alloy);
    sessionStorage.setItem('elavonModule', userDataAndToken.has_elavon);
    sessionStorage.setItem('secret', userDataAndToken.secret);
    sessionStorage.setItem('totp', userDataAndToken.totp);
    sessionStorage.setItem('otp_enabled', userDataAndToken.otp_enabled);
    sessionStorage.setItem('validated', userDataAndToken.validated);
    if(userDataAndToken.isAdmin && userDataAndToken.isAdmin === true) {
        sessionStorage.setItem('isAdmin', userDataAndToken.isAdmin);
    }
    sessionStorage.setItem('lastActiveTime', new Date());
    showBanner();
}

//Resets the stored time of when the user was last active
export const updateLastActive = () => {
    const currentToken = sessionStorage.getItem('token');
    if (currentToken) {
        sessionStorage.setItem('lastActiveTime', new Date());
    }
}

//Checks current time against time that the user was last active and when they logged in. Converts these to minnutes.
//If it's been longer than 30 minutes, logs the user out and displays an alert.
//Same happens if user has been logged in for more than 2 hours.
export const checkInactivity = () => {
    const currentToken = sessionStorage.getItem('token');
    if (currentToken) {
        const currentTime = (new Date().getTime() / 1000);
        const lastActiveTime = new Date(sessionStorage.getItem('lastActiveTime')).getTime() / 1000;

        const timeInactive = (currentTime - lastActiveTime) / 60;

        if (timeInactive >= MAX_IDLE_MINUTES) {
            handleLogout();
            setTimeout(() => {
                alert(LOGOUT_ALERT);
            }, 100);
        }
    }
}

// If an API response indicates that the user's JWT is expired, client storage is cleared and a message is displayed
export const checkTokenExpiration = (dataFromApi) => {
    if (dataFromApi.status === 403) {
        handleLogout();
        setTimeout(() => {
            alert(LOGOUT_ALERT);
        }, 100);
        return Promise.reject();
    }
    else if(dataFromApi.status === 401){
        handleLogout();
        setTimeout(() => {
            alert(LOGOUT_ALERT);
        }, 100);
        return Promise.reject();
    }
    else {
        return Promise.resolve(dataFromApi)
    }
}

export const getToken = () => {
    return (sessionStorage.getItem('token') !== null) ? sessionStorage.getItem('token') : null;
}

//Clears user info and JWT, redirects to login page
export const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace("/");
}

//Simply checks if there is a JWT in storage
export const isLoggedIn = () => {
    return sessionStorage.getItem('token') !== null;
}

//Returns the name of the user from session storage
export const getUserInfo = () => {
    const userInfo = {
        name: sessionStorage.getItem('name'),
        email: sessionStorage.getItem('email'),
        businessName: sessionStorage.getItem('businessName'),
        achModule: sessionStorage.getItem('achModule'),
        wireModule: sessionStorage.getItem('wireModule'),
        positivePayModule: sessionStorage.getItem('positivePayModule'),
        integratedModule: sessionStorage.getItem('integratedModule'),
        alloyModule: sessionStorage.getItem('alloyModule'),
        elavonModule: sessionStorage.getItem('elavonModule'),
        validated: sessionStorage.getItem("validated")
    }
    
    return userInfo;

}