import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { checkAdmin, deleteUser, restoreUser, updateUser } from '../../modules/AdminActionManager';
import { saveLogin } from '../../modules/LoginManager';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import AssignUser from './AssignUser';
import { config as configData } from '../../config'
const moment = require("moment");

export class EditUser extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingStatus: true,
            logoUrl: "",
            questionsAndAnswers: [],
            isAdmin: checkAdmin(),
            messages: [],
            initialEmail: "",
            user: {
                accountsSelected: [],
                email: "",
                suid: "",
                businessName: "",
                firstName: "",
                lastName: "",
                isAdmin: false,
                isSubUser: false,
                achModule: false,
                wireModule: false,
                positivePayModule: false,
                integratedModule: false,
                alloyModule: false,
                elavonModule: false,
                deletionDate: null,
                parentAccount: 0,
                assignedAccounts: []
            }
        }
    }

    componentDidMount() {
        this.setState({
            user: this.props.userProp,
            initialEmail: this.props.emailProp,
            loadingStatus: false
        })
    }
    handleModuleChange = (e) => {
        if (e.target.type === 'checkbox') {

            let updateUser = this.state.user;
            updateUser[e.target.name] = e.target.checked;
            this.setState({ [this.state.user]: updateUser });
        } else {

            this.setState({ [e.target.name]: e.target.value })
        }
        setTimeout(() => {

        }, 500);
    }

    handleFieldChange = (event) => {
        let updatedUser = this.state.user;
        if (event.target.id === 'isAdmin') {
            if (event.target.value === 'subuser') {
                updatedUser.isAdmin = false;
                updatedUser.isSubUser = true;
                this.assignAccounts();
            } else {
                updatedUser.isSubUser = false;
                updatedUser.isAdmin = JSON.parse(event.target.value)
            }
        }
        else {
            updatedUser[event.target.id] = event.target.value
        }

        this.setState({
            user: updatedUser
        });
    }

    handleSubFieldChange = (event) => {
        let updatedUser = this.state.user;
        if (event.target.id === 'isSubUser') {
            updatedUser.isSubUser = JSON.parse(event.target.value)
        }
        else {
            updatedUser[event.target.id] = event.target.value
        }

        this.setState({
            user: updatedUser
        });
    }
    componentWillReceiveProps(props) {
        if (props.userProp !== this.state.user) {
            this.setState({
                user: props.userProp,
                initialEmail: this.props.emailProp,
                loadingStatus: false,
                messages: []
            })
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();

        this.setState({
            messages: [],
            loadingStatus: true
        })
        let messagesToAdd = [];
        if (this.state.user.isSubUser && this.state.user.accountsSelected?.length === 0) {
            messagesToAdd['error'] = "The subuser must have at least one account to view.";
            this.setState({
                messages: messagesToAdd,
                loadingStatus: false
            });
            return false;
        }
        updateUser(this.state.user, this.props.emailProp).then((response) => {
            if (response.status === 'success') {
                this.props.getUserList();
                this.setState({
                    initialEmail: this.state.user.email
                })
                messagesToAdd['success'] = "User updated."
                if (response.data !== undefined) {
                    saveLogin(response.data);
                }
            }
            else {
                messagesToAdd['error'] = response.message;
            }
            this.setState({
                messages: messagesToAdd,
                loadingStatus: false
            });
        }).catch((error) => {
            messagesToAdd['error'] = "We're unable to contact the database at this time. Please contact the administrator or try again later."
            this.setState({
                messages: messagesToAdd,
                loadingStatus: false
            });
        });
    }

    handleDelete = (event) => {
        event.preventDefault();

        this.setState({
            loadingStatus: true
        })
        let messagesToAdd = [];
        let editedUser = this.state.user;

        deleteUser(this.state.user.email).then((response) => {
            if (response.status === 'success') {
                editedUser.deletionDate = Date.now();
                messagesToAdd['success'] = "User deleted."
            }
            else {
                messagesToAdd['error'] = response.message;
            }
            this.props.getUserList();
            this.setState({
                user: editedUser,
                messages: messagesToAdd,
                loadingStatus: false
            });
        }).catch((error) => {
            messagesToAdd['error'] = "We're unable to contact the database at this time. Please contact the administrator or try again later."
            this.setState({
                messages: messagesToAdd,
                loadingStatus: false
            });
        });
    }

    handleRestore = (event) => {
        event.preventDefault();

        this.setState({
            loadingStatus: true
        })
        let messagesToAdd = [];
        let editedUser = this.state.user;

        restoreUser(this.state.user.email).then((response) => {
            if (response.status === 'success') {
                editedUser.deletionDate = null;
                messagesToAdd['success'] = "User restored."
            }
            else {
                messagesToAdd['error'] = response.message;
            }
            this.props.getUserList();
            this.setState({
                user: editedUser,
                messages: messagesToAdd,
                loadingStatus: false
            });
        }).catch((error) => {
            messagesToAdd['error'] = "We're unable to contact the database at this time. Please contact the administrator or try again later."
            this.setState({
                messages: messagesToAdd,
                loadingStatus: false
            });
        });
    }

    assignAccounts = () => {
        this.setState({ showModal: true });
    }

    handleCallback = (e) => {
        this.setState({ showModal: false });
        const currentUser = this.state.user;
        currentUser["accountsSelected"] = e;
        this.setState({ user: currentUser });
    }

    handleCancel = () => {
        this.setState({showModal: false});
    }

    render() {
        if (!this.state.isAdmin) {
            return (
                <Redirect to='/settings'></Redirect>
            )
        }

        return (
            <>
                {this.state.showModal ? <AssignUser handleSelect={this.handleCallback} handleCancel={this.handleCancel} parentAccount={this.state.user.parentAccount} assignedAccounts={this.state.user.assignedAccounts}/> : <></>}
                <form onSubmit={(evt) => this.handleSubmit(evt)} className="create-edit-form">
                    <h3 className="page-text">Edit Existing User</h3>

                    {
                        (this.state.user.deletionDate !== null) &&
                        <div className="page-error">
                            User deleted on {moment(this.state.user.deletionDate).format("MM/DD/YY")}
                        </div>
                    }
                    <div className="input-group mb-20">
                        <div className="input-header">
                            SSO Shared User ID
                        </div>
                        <div className="input-box">
                            <input onChange={this.handleFieldChange} type="text" id="suid" name="suid" autoComplete="off" value={this.state.user.suid} />
                        </div>
                    </div>
                    <div className="input-group mb-20">
                        <div className="input-header">
                            Business Name
                        </div>
                        <div className="input-box">
                            <input onChange={this.handleFieldChange} type="text" id="businessName" name="businessName" autoComplete="off" value={this.state.user.businessName} />
                        </div>
                    </div>
                    <div className="input-group mb-20">
                        <div className="input-header">
                            First Name
                        </div>
                        <div className="input-box">
                            <input onChange={this.handleFieldChange} type="text" id="firstName" name="firstName" autoComplete="off" value={this.state.user.firstName} />
                        </div>
                    </div>
                    <div className="input-group mb-20">
                        <div className="input-header">
                            Last Name
                        </div>
                        <div className="input-box">
                            <input type="text" onChange={this.handleFieldChange} name="lastName" id="lastName" value={this.state.user.lastName} autoComplete="off" />
                        </div>
                    </div>
                    <div className="input-group mb-20">
                        <div className="input-header">
                            Email
                        </div>
                        <div className="input-box">
                            <input type="text" onChange={this.handleFieldChange} name="email" id="email" value={this.state.user.email} autoComplete="off" />
                        </div>
                    </div>
                    <div className="input-group mb-20">
                        <div className="input-header">
                            Role
                        </div>
                        <div className="input-box">
                            <select
                                type='text'
                                name="isAdmin"
                                id="isAdmin"
                                value={this.state.user.isSubUser ? 'subuser' : this.state.user.isAdmin.toString()}
                                onChange={this.handleFieldChange}
                            >
                                <option value="false">Normal user</option>
                                <option value="subuser">Sub User</option>
                                <option value="true">Admin</option>
                            </select>
                        </div>
                    </div>
                    {this.state.user.isSubUser &&
                        <button
                        className="custom-save-button"
                        type="submit" onClick={(e) => {e.preventDefault();this.setState({showModal: true})}}>
                        Show Assigned Accounts
                    </button>
                    }
                    <div className="assign-module">
                        <FormControl
                            className="assign-module">
                            <label className="page-text">Assign Modules</label>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox color="primary"
                                            onChange={this.handleModuleChange}
                                            name="achModule"
                                            checked={this.state.user.achModule}
                                        />
                                    }
                                    label={`${configData.modules[0]?.name ?? "Module 0"}`}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={this.handleModuleChange}
                                            name="wireModule" color="primary"
                                            checked={this.state.user.wireModule}
                                        />
                                    }
                                    label={`${configData.modules[1]?.name ?? "Module 1"}`}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={this.handleModuleChange}
                                            name="positivePayModule"
                                            color="primary"
                                            checked={this.state.user.positivePayModule}
                                        />
                                    }
                                    label={`${configData.modules[2]?.name ?? "Module 2"}`}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={this.handleModuleChange}
                                            name="integratedModule"
                                            color="primary"
                                            checked={this.state.user.integratedModule}
                                        />
                                    }
                                    label={`${configData.modules[3]?.name ?? "Module 3"}`}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={this.handleModuleChange}
                                            name="alloyModule"
                                            color="primary"
                                            checked={this.state.user.alloyModule}
                                        />
                                    }
                                    label={`${configData.modules[4]?.name ?? "Module 4"}`}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={this.handleModuleChange}
                                            name="elavonModule"
                                            color="primary"
                                            checked={this.state.user.elavonModule}
                                        />
                                    }
                                    label={`${configData.modules[5]?.name ?? "Module 5"}`}
                                />
                            </FormGroup>
                            <FormHelperText >
                                Please assign proper modules for user.
                            </FormHelperText>
                        </FormControl>
                    </div>
                    {
                        this.state.messages['error'] &&
                        <div className="page-error login-error">
                            <div className="login-error-header">We're sorry, but something went wrong:</div>
                            <ul className="page-error-body">
                                {
                                    this.state.messages["error"] &&
                                    <li>
                                        {this.state.messages["error"]}
                                    </li>
                                }
                            </ul>
                        </div>
                    }
                    {
                        this.state.messages['success'] &&
                        <div className="page-error login-error success-message">
                            <div className="login-error-header">Success!</div>
                            <ul className="page-error-body">
                                {
                                    this.state.messages["success"] &&
                                    <li>
                                        {this.state.messages["success"]}
                                    </li>
                                }
                            </ul>
                        </div>
                    }

                    <div className="edit-button-container">
                        <button
                            className="page-button"
                            disabled={this.state.loadingStatus}
                            type="submit">
                            Save
                        </button>
                        {
                            (this.state.user.deletionDate !== null) &&
                            <button
                                className="page-button add"
                                disabled={this.state.loadingStatus}
                                onClick={(evt) => this.handleRestore(evt)}>
                                Restore
                            </button>
                        }
                        {
                            (this.state.user.deletionDate === null) &&
                            <button
                                className="page-button delete cancel"
                                disabled={this.state.loadingStatus}
                                onClick={(evt) => this.handleDelete(evt)}>
                                Delete
                            </button>
                        }
                    </div>
                </form>
            </>
        );
    }
}
