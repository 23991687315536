import React, { Component } from 'react';
import { config as configData } from '../../config';
import { Link, Redirect } from 'react-router-dom';
import { checkAdmin } from '../../modules/AdminActionManager';
import { getFaq } from '../../modules/FaqManager.js'
import { NavBar } from '../../components/NavBar';
import { Box, Grid } from '@material-ui/core';
import { isLoggedIn } from '../../modules/LoginManager';

export class FAQ extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingStatus: true,
            logoUrl: "",
            questionsAndAnswers: [],
            isAdmin: checkAdmin(),
            loggedIn: isLoggedIn(),
            errorQuestionsAndAnswers: [
                {
                    faqid: 1,
                    question: "Where are all the FAQs?",
                    answer: "Sorry! It looks like we couldn't fetch any FAQs from the server. Please contact the administrator."
                }
            ],
            noQuestionsAndAnswers: [
                {
                    faqid: 1,
                    question: "Why am I seeing this?",
                    answer: "There are no FAQs in your database."
                },
                {
                    faqid: 2,
                    question: "What can I do?",
                    answer: "Don't worry: this is just a test to make sure the page can load data. Try adding an FAQ to the database, and see if this gets resolved."
                }
            ]
        }
    }

    componentDidMount() {
        //Loads logo from config file, FAQs from API
        getFaq().then((questionsAndAnswersFromAPI) => {
            this.setState({
                loadingStatus: false,
                logoUrl: configData.logo,
                questionsAndAnswers: questionsAndAnswersFromAPI
            })
        });
    }

    handleEditRoute(event) {
        event.preventDefault();
        this.props.history.push('/faq/edit');
    }

    handleEditBroadcastRoute(event) {
        event.preventDefault();
        this.props.history.push('/faq/edit-broadcast');
    }

    render() {
        if (!this.state.loggedIn) {
            return (
                <Redirect to='/'></Redirect>
            )
        }

        let faqList = <p className="page-text">Loading...</p>

        if (!this.state.loadingStatus) {
            faqList = <ul className="faq-list">
                <Grid container justify="center" key="ul-grid">
                <Grid item xs={12} md={8}>
                    {
                        this.state.questionsAndAnswers.map((questionAndAnswer) => (
                            
                                <li key={questionAndAnswer.faqid} className="faq-pair">
                                    <strong>{questionAndAnswer.question}</strong>
                                    <p>{questionAndAnswer.answer}</p>
                                </li>
                            
                        ))
                    }
                    </Grid>
                </Grid>
            </ul>
        }

        return (
            <Box className="page" id="faq">
                <NavBar {...this.props} />
                <Grid container justify="center" className="page-form">
                    <form className="page-inner-form">
                        <h3 className="page-text">Frequently Asked Questions</h3>
                        {
                            this.state.isAdmin &&
                            <div className="faq-buttons">
                                <button
                                className="page-button"
                                disabled={this.state.loadingStatus}
                                type="submit"
                                onClick={(evt) => this.handleEditRoute(evt)}>
                                Edit
                                </button>
                                <button
                                className="page-button"
                                disabled={this.state.loadingStatus}
                                type="submit"
                                onClick={(evt) => this.handleEditBroadcastRoute(evt)}>
                                Edit Broadcast
                            </button>
                            </div>
                        }
                        {faqList}
                    </form>
                    <Link to='/' className="page-link">
                        Back
                    </Link>
                </Grid>
            </Box>

        );

    }
}
