import React, { Component, useState } from "react";
import { config, config as configData } from '../../config'
import { getUserInfo, handleApiForgotPassword, handleLogout, isLoggedIn } from "../../modules/LoginManager";
import { FaPencilAlt, FaEyeSlash, FaEye, FaTrash } from "react-icons/fa";
import { Redirect } from "react-router";
import "../pages.css";
import { checkPlaidEnabled, handlePlaidGetAccounts, handlePlaidRenameAccount, handleSwitch } from "../../modules/PlaidManager";
import { checkAdmin, removeAccount } from "../../modules/AdminActionManager";
import { handleGetUsersList } from "../../modules/UserManager";
import { handleGetLogsList } from "../../modules/LogsManager";
import { NavBar } from "../../components/NavBar";
import { CreateUser } from "./CreateUser";
import { EditUser } from "./EditUser";
import { Card, CardContent, CardActions, Button, Grid, TableRow, TableCell, Paper, Table, TableHead, TableBody, TableContainer, FormControlLabel } from '@material-ui/core';
import { getAllSso, saveAllSso } from "../../modules/SsoManager";
import { PlaidLinkButton } from "../Dashboard/PlaidLinkButton";
import { Link } from "react-router-dom";
import { checkSubUser } from "../../modules/businessSubUserManager";
import { PlaidSwitch } from "../../modules/PlaidManager";
import QRCode from 'qrcode';

const mql = window.matchMedia('(max-width: 960px)');

export class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loggedIn: isLoggedIn(),
            isAdmin: checkAdmin(),
            isSubUser: checkSubUser(),
            tabSelected: "accountInfo",
            memberName: getUserInfo().name,
            email: getUserInfo().email,
            validated: getUserInfo().validated,
            isPlaidEnabled: checkPlaidEnabled(),
            accountsList: [],
            ssoModules: [],
            createBoxOpen: true,
            userToEdit: {
                name: "",
                email: "",
                businessName: "",
                suid: "",
                isAdmin: false,
                isSubUser: false,
                userId: 0,
                deletionDate: Date.now(),
                alloyModule: false,
                elavonModule: false,
                achModule: false,
                wireModule: false,
                positivePayModule: false,
                integratedModule: false,
                parentAccount: 0,
                assignedAccounts: []
            },
            usersList: [],
            logsList: [],
            filteredUsers: [],
            ssoCredentials: [],
            ssoMessages: [],
            resetMessages: [],
            searchValue: "",
            achPass: false,
            wirePass: false,
            posPayPass: false,
            payRecPass: false,
            mobileView: mql.matches
        };
    }

    componentDidMount() {

        if (this.state.isAdmin) {
            this.getUserList();
        }

        if (!!sessionStorage.getItem('accounts')) {
            this.getAccounts();
        }

        this.getAccounts();
        this.getSso();
        this.setState({
            ssoModules: configData.modules,
            loading: false,
            mobileView: mql.matches
        });
        mql.addEventListener('change', (e) => {
            this.setState({
                mobileView: e.matches
            })
        });

        if(sessionStorage.getItem("otp_enabled") && sessionStorage.getItem("otp_enabled") == 1) {
            this.setState({otpEnabled: true});
        } else {
            this.setState({otpEnabled: false});
        }
        const totp = sessionStorage.getItem('totp');
        QRCode.toDataURL(totp, (err, url) => {
            if (!err) {
              this.setState({ dataUrl: url });
            } else {
              console.error('Error generating data URL:', err);
            }
        });
    }

    componentWillUnmount() {
        mql.removeEventListener('change', (e) => {
            this.setState({
                mobileView: e.matches
            })
        });
    }

    getLogo() {
        return configData.logo;
    }

    async verifyTotp(code) {
        try {
            const reqBody = {
                token: sessionStorage.getItem('token'),
                code
            }
            const response = await fetch(`${config.remoteUrl}/users/verifyTotp`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                },
                body: JSON.stringify(reqBody),
            });
            console.log(response);
        } catch (err) {
            alert("Failed to save configuration");
        }
    }

    getSso() {
        let messagesToAdd = [];
        getAllSso().then((response) => {
            if (response.status === 'success') {
                if (response.data[0] !== undefined) {
                    const credentialBuilder = [];
                    configData.modules.map((_module, index) => {
                        if (!response.data[index]) {
                            credentialBuilder.push({ username: "", password: "", ssolinkid: index });
                        } else {
                            credentialBuilder.push(response.data[index]);
                        }
                    });
                    this.setState({
                        ssoCredentials: credentialBuilder
                    });
                }
                else {
                    let emptyCredentials = configData.modules.map((_module, index) => {
                        return {
                            username: "",
                            password: "",
                            ssolinkid: index
                        }
                    });

                    this.setState({
                        ssoCredentials: emptyCredentials
                    });
                }
            }
            else {
                messagesToAdd["error"] = response.message;
                this.setState({
                    ssoMessages: messagesToAdd
                })
            }
        }).catch((err) => {
            messagesToAdd['error'] = "We're unable to contact the database at this time. Please contact the administrator or try again later."
            this.setState({
                ssoMessages: messagesToAdd
            });
        });
    }

    backToDashboard() {
        this.props.history.push("/dashboard");
    }

    handleTabChange(tab) {
        this.setState({ tabSelected: tab });
    }

    handleToggleChange = (event) => {
        if (event.target.type === 'checkbox') {
            const newValue = !this.state.isPlaidEnabled;
            this.setState({ [event.target.name]: newValue });
            event.target.value = newValue;
        } else {
            this.setState({ [event.target.name]: event.target.checked })
        }
    }


    togglePassVisibility(field) {
        const el = document.getElementById(field);
        let visible;
        if (el.type === "password") {
            el.type = "text";
            visible = true;
        } else {
            el.type = "password";
            visible = false;
        }

        this.setState({ [field]: visible });
    }

    resetUserPassword(event, userEmail) {
        event.preventDefault();
        let messagesToAdd = [];
        this.setState({
            resetMessages: messagesToAdd
        })
        handleApiForgotPassword(userEmail).then((resp) => {
            messagesToAdd['success'] = 'Thank you! You will receive an email shortly, which will contain a link to reset your password.';
            this.setState({
                resetMessages: messagesToAdd
            });
        }).catch((err) => {
            messagesToAdd['error'] = "We're unable to contact the database at this time. Please contact the administrator or try again later.";
            this.setState({
                resetMessages: messagesToAdd
            });
        })
    }

    getUserList = () => {
        let usersList = [];
        let deletedUsersList = [];
        handleGetUsersList().then((resp) => {
            resp.map((user, index) => {
                if (user.deletiondate === null) {
                    usersList.push({
                        suid: user.suid ?? "",
                        firstName: user.firstname,
                        lastName: user.lastname,
                        name: user.firstname + ' ' + user.lastname,
                        businessName: user.businessname || "",
                        email: user.email,
                        isAdmin: user.isadmin,
                        userId: user.userid,
                        deletionDate: user.deletiondate,
                        achModule: user.has_ach === 1,
                        wireModule: user.has_wire === 1,
                        positivePayModule: user.has_pp === 1,
                        integratedModule: user.has_ir === 1,
                        alloyModule: user.has_alloy === 1,
                        elavonModule: user.has_elavon === 1,
                        isSubUser: user.is_sub_user,
                        parentAccount: user.parent_account,
                        assignedAccounts: user.assigned_accounts
                    })
                } else {
                    deletedUsersList.push({
                        suid: user.suid ?? "",
                        firstName: user.firstname,
                        lastName: user.lastname,
                        name: user.firstname + ' ' + user.lastname,
                        businessName: user.businessname || "",
                        email: user.email,
                        isAdmin: user.isadmin,
                        userId: user.userid,
                        deletionDate: user.deletiondate,
                        achModule: user.has_ach === 1,
                        wireModule: user.has_wire === 1,
                        positivePayModule: user.has_pp === 1,
                        integratedModule: user.has_ir === 1,
                        alloyModule: user.has_alloy === 1,
                        elavonModule: user.has_elavon === 1,
                        isSubUser: user.is_sub_user
                    })
                }
                return null;
            });
            this.setState({
                usersList: usersList.concat(deletedUsersList),
                filteredUsers: usersList.concat(deletedUsersList)
            });
        })
    }

    updateSearch(evt) {
        if (evt.target.value === "") {
            this.setState({ filteredUsers: this.state.usersList, searchValue: evt.target.value });
        } else {
            this.setState({ searchValue: evt.target.value });
        }
    }

    handleSearch(event) {
        event.preventDefault();
        if (this.state.searchValue !== "") {
            this.setState({ filteredUsers: this.state.usersList.filter((user) => user.name.toLowerCase().includes(this.state.searchValue.toLowerCase())) });
        } else {
            this.setState({ filteredUsers: this.state.usersList });
        }
    }

    handleAccountNameChange(evt, account) {
        this.setState({ loading: true });
        this.state.accountsList.filter((acc) => {
            if (acc.id === account.id) {
                acc.nickname = evt.target.value;
            }
            return null;
        });

        this.setState({ loading: false });
    }

    removeAccountAlert(account) {
        if (window.confirm("Are you sure you want to remove this account?")) {
            removeAccount(account);
            const domElement = document.getElementById("account-" + account.accountId);
            domElement.remove();
            const accounts = JSON.parse(sessionStorage.getItem('accounts'));
            let newAccounts = [];
            accounts.map((acc) => {
                if (acc.accountId !== account.accountId) {
                    newAccounts.push(acc);
                }
            });
            sessionStorage.setItem('accounts', JSON.stringify(newAccounts));
        }
    }

    changeAccountNickname(account) {
        this.setState({ loading: true });
        this.state.accountsList.filter((acc) => {
            if (acc.id === account.id) {
                if (acc.renameDisabled === true) {
                    // if previously disabled, enable and allow editing
                    acc.renameDisabled = false;
                } else {
                    // if saving, save to db
                    const newName = this.state.accountsList.filter((acc) => acc.accountId === account.accountId)[0];
                    handlePlaidRenameAccount(account.accountId, newName.nickname).then((resp) => {
                        handlePlaidGetAccounts().then((resp) => {
                            sessionStorage.setItem('accounts', JSON.stringify(resp.data));
                        });
                    });
                    acc.renameDisabled = true;
                }
            }
            return null;
        });

        this.setState({ loading: false });
    }

    getAccounts() {
        const accounts = JSON.parse(sessionStorage.getItem('accounts')) ?? [];
        let accountsList = [];
        accounts.forEach((account, index) => {
            accountsList.push({
                id: `account${index}`,
                nickname: account.accountName,
                type: account.accountSubtype,
                number: account.accountMask,
                accountId: account.accountId,
                renameDisabled: true
            })
        });
        this.setState({
            loading: false,
            accountsList: accountsList
        });
    }

    renderAccountsList() {
        const accountsList = this.state.accountsList.length ? this.state.accountsList.map((account, index) => {
            if (!account?.deleted || account?.delete === false) {
                return (
                    <Card className="account-card" key={`account${index}-card`} id={'account-' + account.accountId}>
                        <CardContent className="account-card-content">
                            <div className="account-name">

                                <Grid item xs={12}>
                                    Account Name:
                                    <input type="text" key={`account${index}`} className="account-card-input" value={account.nickname} disabled={account.renameDisabled} onChange={(evt) => this.handleAccountNameChange(evt, account)} />
                                    {sessionStorage.getItem("isSubUser") && sessionStorage.getItem("isSubUser") != "true" ? <>
                                        <button className="edit-account-button" onClick={(evt) => this.changeAccountNickname(account)}>
                                            <FaPencilAlt />
                                        </button>
                                        <button className="delete-account-button" onClick={(evt) => this.removeAccountAlert(account)}>
                                            <FaTrash />
                                        </button>
                                    </> : <></>}
                                </Grid>
                            </div>

                            <div>
                                {`Account #: xxxx${account.number}`}
                            </div>
                        </CardContent>
                    </Card>
                )
            } else {
                return <></>;
            }
        }) : <Card></Card>;

        return (
            <Card>
                <CardContent>
                    <div className="bold-text sub-header-size">
                        Accounts:
                    </div>
                    <div className="page-text link-message">Use the "Connect Your Accounts" button to link more accounts to this app. Click the pencil icon to change the name of an account, and click the icon again to save.</div>
                    <div className="page-text link-message"><strong>Warning:</strong> Linking an institution multiple times with the same member information adds duplicate accounts to the app.</div>
                    <PlaidLinkButton />
                    <Grid container className="accounts-list">
                        {accountsList}
                    </Grid>
                </CardContent>
            </Card>
        )
    }

    handleEditClick = (event, user) => {
        event.preventDefault();
        this.setState({
            createBoxOpen: false,
            userToEdit: user
        })
    }

    handleCreateClick = (event) => {
        event.preventDefault();
        this.setState({
            createBoxOpen: true,
            userToEdit: {
                name: "",
                email: "",
                businessName: "",
                isAdmin: false,
                isSubUser: false,
                userId: 0,
                deletionDate: Date.now(),
                achModule: false,
                wireModule: false,
                positivePayModule: false,
                integratedModule: false,
                alloyModule: false,
                elavonModule: false
            }
        })
    }

    renderUserList() {
        if (!this.state.mobileView) {
            const rows = this.state.filteredUsers.map((user, index) => {
                return (
                    <TableRow key={`row${index}`} className={`users-list-row`} onClick={(evt) => this.handleEditClick(evt, user)}>
                        <TableCell component="th"
                            className={`users-list-row ${this.state.userToEdit.email === user.email ? "selected" : ""} ${user.deletionDate !== null ? "deleted-user" : ""}`}>
                            {user.name}
                        </TableCell>
                        <TableCell className={`users-list-row ${this.state.userToEdit.email === user.email ? "selected" : ""} ${user.deletionDate !== null ? "deleted-user" : ""}`}>
                            {user.email}
                        </TableCell>
                        <TableCell className={`${user.isAdmin ? "is-admin" : ""} users-list-row ${user.deletionDate !== null ? "deleted-user" : ""} ${this.state.userToEdit.email === user.email ? "selected" : ""}`}>
                            {user.isAdmin ? "Yes" : "No"}
                        </TableCell>
                    </TableRow>
                )
            });

            if (!this.state.loading) {
                return (
                    <Grid item xs={12}>
                        <div className="users-list-container">
                            <form onSubmit={(evt) => this.handleSearch(evt)} className="users-list-search-container">
                                <Grid item xs={6} className="users-list-search-container">
                                    <input type="text"
                                        placeholder="Search..."
                                        className="users-list-search"
                                        value={this.state.searchValue}
                                        onChange={(evt) => this.updateSearch(evt)} />
                                </Grid>
                                <div className="edit-button-container search-button-container">
                                    <button className="users-list-search-button" type="submit">
                                        Search
                                    </button>
                                    <button className="users-list-search-button" onClick={(evt) => this.handleCreateClick(evt)}>
                                        Create New User
                                    </button>
                                </div>
                            </form>
                            <TableContainer component={Paper} className="user-table">
                                <Table stickyHeader sx={{ minWidth: 200, maxHeight: 500 }} size="small">
                                    <TableHead>
                                        <TableRow className="user-table-header">
                                            <TableCell className="user-table-header">Member Name</TableCell>
                                            <TableCell className="user-table-header">Email</TableCell>
                                            <TableCell className="user-table-header">Admin?</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Grid>
                )
            }
        }
        else {
            const rows = this.state.filteredUsers.map((user, index) => {
                return (
                    <TableRow key={`row${index}`}
                        className={`users-list-row ${user.deletionDate !== null
                            ? "deleted-user"
                            : ""} 
                                    ${this.state.userToEdit.email === user.email
                                ? "selected"
                                : ""}`
                        }
                        onClick={(evt) => this.handleEditClick(evt, user)}>
                        <TableCell component="th"
                            className={`users-list-row ${user.isAdmin
                                ? "is-admin"
                                : ""}
                                        ${user.deletionDate !== null
                                    ? "deleted-user"
                                    : ""}
                                        ${this.state.userToEdit.email === user.email
                                    ? "selected"
                                    : ""}`
                            }>
                            {user.name}
                        </TableCell>
                        <TableCell
                            className={`${user.isAdmin
                                ? "is-admin"
                                : ""} users-list-row 
                                ${user.deletionDate !== null
                                    ? "deleted-user"
                                    : ""} 
                                ${this.state.userToEdit.email === user.email
                                    ? "selected"
                                    : ""}`
                            }
                        >
                            {user.isAdmin ? "Yes" : "No"}
                        </TableCell>
                    </TableRow>
                )
            });

            if (!this.state.loading) {
                return (
                    <Grid item xs={12}>
                        <div className="users-list-container">
                            <form onSubmit={(evt) => this.handleSearch(evt)} className="users-list-search-container">
                                <Grid container className="users-list-search-container">
                                    <Grid item xs={12} className="users-list-search-container">
                                        <input type="text" placeholder="Search..."
                                            className="users-list-search"
                                            value={this.state.searchValue}
                                            onChange={(evt) => this.updateSearch(evt)} />
                                    </Grid>
                                    <div className="edit-button-container">
                                        <button className="users-list-search-button" type="submit">
                                            Search
                                        </button>
                                        <button className="users-list-search-button"
                                            onClick={(evt) => this.handleCreateClick(evt)}>
                                            Create New User
                                        </button>
                                    </div>
                                </Grid>
                            </form>
                            <TableContainer component={Paper} className="user-table">
                                <Table stickyHeader sx={{ minWidth: 200 }} size="small">
                                    <TableHead>
                                        <TableRow className="user-table-header">
                                            <TableCell className="user-table-header">Member Name</TableCell>
                                            <TableCell className="user-table-header">Admin?</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Grid>
                )
            }
        }
    }

    renderSsoInstructions() {
        return (
            <Grid item xs={12}>
                <h4 className="page-text">About the Product Modules</h4>
                <div className="page-text">
                    Use the form below to save your credentials for every Product Module. <a href="https://chrome.google.com/webstore/detail/trutreasury-extension/jggfikgljgjdkjondekfahhbanhmbdom" target="_blank" className="page-link" rel="noopener noreferrer">Download the TruTreasury Single Sign-On browser extension.</a> When clicking a Product Module on <Link to="/dashboard" className="page-link">the Dashboard</Link>, the browser extension will log you into the Module using the credentials you save here.
                </div>
            </Grid>
        )
    }

    renderSsoEdit() {
        let ssoField = "";

        if (this.state.ssoModules.length > 0 && this.state.ssoCredentials.length > 0) {
            ssoField = this.state.ssoModules.map((ssoModule, index) => {
                return (
                    <Grid item xs={12} key={index}>
                        <Grid container direction="column">
                            {ssoModule.name}
                            <Grid item><input id={`${index}-username`} type="text" placeholder="Username" className="sso-link-username" value={this.state.ssoCredentials[index]?.username ?? ""} onChange={(evt) => this.handleCredChange(evt)} /></Grid>
                            <Grid item><input title={(index===3) ? "Site Abbreviation" : "Password"} id={`${index}-password`} type={(index===3) ? "text" : "password"} placeholder={(index===3) ? "Site Abbreviation" : "Password"} className="sso-link-password" value={this.state.ssoCredentials[index]?.password ?? ""} onChange={(evt) => this.handleCredChange(evt)} />
                                <button className="pass-toggle"
                                    onClick={(evt) => this.togglePassVisibility(`${index}-password`)}>
                                    {this.state[`${index}-password`] ? <FaEye /> : <FaEyeSlash />}
                                </button></Grid>
                        </Grid>

                    </Grid>
                )
            })
        }
        return (
            <Grid container>
                {ssoField}
            </Grid>
        )
    }

    handleCredChange = (event) => {
        if (event.target.type === "text" || event.target.type === "password") {
            let credsToEdit = event.target.id.split("-");
            let editedCredentials = this.state.ssoCredentials;
            editedCredentials[credsToEdit[0]][credsToEdit[1]] = event.target.value;
            this.setState({
                ssoCredentials: editedCredentials
            })
        } else {
            this.setState({
                ssoCredentials: event.target.value
            })
        }
    }

    handleSaveCreds = (event) => {
        event.preventDefault();
        this.setState({
            loading: true
        })
        let messagesToAdd = [];

        this.setState({
            ssoMessages: messagesToAdd
        })

        saveAllSso(this.state.ssoCredentials).then((response) => {
            if (response.status === 'success') {
                messagesToAdd["success"] = response.message;
            }
            else {
                messagesToAdd["error"] = response.message;
            }
            this.setState({
                ssoMessages: messagesToAdd,
                loading: false
            });
        }).catch((err) => {
            messagesToAdd['error'] = "We're unable to contact the database at this time. Please contact the administrator or try again later."
            this.setState({
                ssoMessages: messagesToAdd,
                loading: false
            });
        })
    }

    renderAccountsTab() {
        return (
            <Grid container spacing={2}>
                <Grid item xs>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card className="member-info-card">
                                <CardContent>
                                    <div>
                                        <div className="bold-text sub-header-size">
                                            Member Name:
                                        </div>
                                        <div>
                                            {this.state.memberName}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="bold-text sub-header-size">
                                            Email:
                                        </div>
                                        <div>
                                            {this.state.email}
                                        </div>
                                    </div>
                                    {
                                        this.state.resetMessages['error'] &&
                                        <div className="page-error login-error">
                                            <div className="login-error-header">We're sorry, but something went wrong:</div>
                                            <ul className="settings-error-body">
                                                {
                                                    this.state.resetMessages["error"] &&
                                                    <li>
                                                        {this.state.resetMessages["error"]}
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                    }
                                    {
                                        this.state.resetMessages['success'] &&
                                        <div className="page-error login-error success-message">
                                            <div className="login-error-header">Success!</div>
                                            <ul className="settings-error-body">
                                                {
                                                    this.state.resetMessages["success"] &&
                                                    <li>
                                                        {this.state.resetMessages["success"]}
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                    }
                                </CardContent>
                                <CardActions>
                                    <Button className="settings-page-button" disabled={this.state.loading} onClick={(evt) => this.resetUserPassword(evt, this.state.email)}>
                                        Request Password Reset Email
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card className="member-info-card">
                                <CardContent>
                                    {this.renderSsoInstructions()}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card className="member-info-card">
                                <CardContent>
                                    {this.renderSsoEdit()}

                                    {
                                        this.state.ssoMessages['error'] &&
                                        <div className="page-error login-error">
                                            <div className="login-error-header">We're sorry, but something went wrong:</div>
                                            <ul className="settings-error-body">
                                                {
                                                    this.state.ssoMessages["error"] &&
                                                    <li>
                                                        {this.state.ssoMessages["error"]}
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                    }
                                    {
                                        this.state.ssoMessages['success'] &&
                                        <div className="page-error login-error success-message">
                                            <div className="login-error-header">Success!</div>
                                            <ul className="settings-error-body">
                                                {
                                                    this.state.ssoMessages["success"] &&
                                                    <li>
                                                        {this.state.ssoMessages["success"]}
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                    }
                                </CardContent>
                                <CardActions>
                                    <Button className="settings-page-button" disabled={this.state.loading} onClick={(evt) => this.handleSaveCreds(evt)}>
                                        Save Links
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={8} lg={8}>
                    <Card className="member-info-card" style={{marginBottom: '24px'}}>
                        {this.renderAccountsList()}
                    </Card>
                        <Card className="member-info-card" style={{ padding: '12px' }}>
                            <h2>Account Security</h2>
                            <h4>Account MFA</h4>
                        {this.state.otpEnabled === true ? (
                            <div>MFA is enabled</div>
                        ) : (
                            <>
                                Download the Google Authenticator app or the Authy app and press the plus to create a new login. Once done, scan the code below and verify the code on your screen.< br/>
                                {this.state.dataUrl && <img src={this.state.dataUrl} alt="Generated Data URL" />}
                                <br />
                                <input
                                    type="text"
                                    name="verify"
                                    placeholder="Verify Code"
                                    onChange={(e) => this.setState({ totpCode: e.target.value })}
                                />
                                <Button
                                    className="settings-page-button"
                                    disabled={this.state.loading}
                                    onClick={(evt) => {
                                    this.verifyTotp(this.state.totpCode);
                                    }}
                                >
                                    Verify
                                </Button>
                            </>
                        )}
                    </Card>
                </Grid>
            </Grid>
        )
    }

    async submitTheme(e) {
        e.preventDefault();
        let elements = {};
        elements["type"] = "colors";
        for (let i = 0; i < e.target.elements.length; i++) {
            let item = e.target.elements[i];
            if (item.name.startsWith("colors.")) {
                if (item.value.match(/^#[a-f0-9]{6}$/i) !== null) {
                    elements[item.name] = item.value;
                } else {
                    alert("Invalid value for " + item.title);
                }
            }
        }
        const token = (sessionStorage.getItem('token') !== null) ? sessionStorage.getItem('token') : null;
        try {
            const response = await fetch(`${config.remoteUrl}/config`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(elements),
            });
        } catch (err) {
            alert("Failed to save configuration");
        }
    }

    toggleThemeInputs(e) {
        const inputs = document.getElementsByTagName('input');
        for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].name.startsWith("colors.")) {
                if (inputs[i].type === "text") {
                    if (i === 0) {
                        e.target.textContent = "Switch to Advanced View";
                    }
                    document.getElementsByTagName('input')[i].setAttribute('type', 'color');
                } else {
                    if (i === 0) {
                        e.target.textContent = "Switch to Simple View";
                    }
                    document.getElementsByTagName('input')[i].setAttribute('type', 'text');
                }
            }
        }
    }

    renderThemeSettings = () => {
        return (
            <>
                <button name="toggleStyle" className="custom-button mb-20" onClick={this.toggleThemeInputs}>
                    Switch to Simple View
                </button>
                <br />
                <form id="newThemeForm" onSubmit={this.submitTheme}>
                    <Card className="member-info-card mb-20">
                        <CardContent>
                            <h2>Main Colors</h2>
                            <div className="horizontal-row">
                                <div className="input-group">
                                    <div className="input-header">
                                        Background Color
                                    </div>
                                    <div className="input-box">
                                        <input type="text" title="Background Color" name="colors.background" defaultValue={configData.colors.background} />
                                    </div>
                                </div>
                                <div className="input-group">
                                    <div className="input-header">
                                        Primary Color
                                    </div>
                                    <div className="input-box">
                                        <input type="text" title="Primary Color" name="colors.primary" defaultValue={configData.colors.primary} />
                                    </div>
                                </div>
                                <div className="input-group">
                                    <div className="input-header">
                                        Secondary Color
                                    </div>
                                    <div className="input-box">
                                        <input type="text" title="Secondary Color" name="colors.secondary" defaultValue={configData.colors.secondary} />
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                    <Card className="member-info-card mb-20">
                        <CardContent>
                            <h2>Buttons</h2>
                            <div className="horizontal-row">
                                <div className="input-group">
                                    <div className="input-header">
                                        Page Buttons
                                    </div>
                                    <div className="input-box">
                                        <input type="text" title="Page Buttons" name="colors.pageButtons" defaultValue={configData.colors.pageButtons} />
                                    </div>
                                </div>
                                <div className="input-group">
                                    <div className="input-header">
                                        Page Buttons Text
                                    </div>
                                    <div className="input-box">
                                        <input type="text" title="Page Buttons Text" name="colors.pageButtonsText" defaultValue={configData.colors.pageButtonsText} />
                                    </div>
                                </div>
                                <div className="input-group">
                                    <div className="input-header">
                                        Hover
                                    </div>
                                    <div className="input-box">
                                        <input type="text" title="Hover" name="colors.hover" defaultValue={configData.colors.hover} />
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="horizontal-row">
                                <div className="input-group">
                                    <div className="input-header">
                                        Element Buttons
                                    </div>
                                    <div className="input-box">
                                        <input type="text" title="Element Buttons" name="colors.elementButtons" defaultValue={configData.colors.elementButtons} />
                                    </div>
                                </div>
                                <div className="input-group">
                                    <div className="input-header">
                                        Element Buttons Text
                                    </div>
                                    <div className="input-box">
                                        <input type="text" title="Element Buttons Text" name="colors.elementButtonsText" defaultValue={configData.colors.elementButtonsText} />
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                    <Card className="member-info-card mb-20">
                        <CardContent>
                            <h2>Text</h2>
                            <div className="horizontal-row">
                                <div className="input-group">
                                    <div className="input-header">
                                        Primary
                                    </div>
                                    <div className="input-box">
                                        <input type="text" title="Primary" name="colors.primaryText" defaultValue={configData.colors.primaryText} />
                                    </div>
                                </div>
                                <div className="input-group">
                                    <div className="input-header">
                                        Secondary
                                    </div>
                                    <div className="input-box">
                                        <input type="text" title="Secondary" name="colors.secondaryText" defaultValue={configData.colors.secondaryText} />
                                    </div>
                                </div>
                                <div className="input-group">
                                    <div className="input-header">
                                        Links
                                    </div>
                                    <div className="input-box">
                                        <input type="text" title="Links" name="colors.links" defaultValue={configData.colors.links} />
                                    </div>
                                </div>
                                <div className="input-group">
                                    <div className="input-header">
                                        Table Header Text
                                    </div>
                                    <div className="input-box">
                                        <input type="text" title="Table Header Text" name="colors.tableHeaderText" defaultValue={configData.colors.tableHeaderText} />
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                    <Card className="member-info-card mb-20">
                        <CardContent>
                            <h2>Misc.</h2>
                            <div className="horizontal-row">
                                <div className="input-group">
                                    <div className="input-header">
                                        Border
                                    </div>
                                    <div className="input-box">
                                        <input type="text" title="Border" name="colors.border" defaultValue={configData.colors.border} />
                                    </div>
                                </div>
                                <div className="input-group">
                                    <div className="input-header">
                                        Loading Spinner
                                    </div>
                                    <div className="input-box">
                                        <input type="text" title="Loading Spinner" name="colors.loadingSpinner" defaultValue={configData.colors.loadingSpinner} />
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                    <input type="submit" value="Save" className={"custom-save-button"} />
                </form>
            </>);
    }

    getLogsList = () => {
        handleGetLogsList((logs) => {
            this.setState({ logsList: logs });
        });
        this.handleTabChange("logs");
    }

    renderLogs() {
        const rows = this.state.logsList.map((log, index) => {
            return (
                <TableRow key={`row${index}`}>
                    <TableCell component="td">
                        {log.timestamp}
                    </TableCell>
                    <TableCell component="td">
                        {log.type}
                    </TableCell>
                    <TableCell component="td">
                        {log.error}
                    </TableCell>
                    <TableCell component="td">
                        {log.user}
                    </TableCell>
                    <TableCell component="td">
                        {log.ip_address}
                    </TableCell>
                </TableRow>
            )
        });

        if (!this.state.loading) {
            return (
                <Grid item xs={12}>
                    <div>
                        <TableContainer component={Paper}>
                            <Table stickyHeader sx={{ minWidth: 200, maxHeight: 500 }} size="small">
                                <TableBody>
                                    {rows}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Grid>
            )
        }
    }

    renderTab() {
        switch (this.state.tabSelected) {
            case "accountInfo":
                if (this.state.isPlaidEnabled) {
                    return (
                        <div>
                            {this.renderAccountsTab()}
                        </div>
                    )
                } else {
                    return (
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Card className="member-info-card">
                                            <CardContent>
                                                <div>
                                                    <div className="bold-text sub-header-size">
                                                        Member Name:
                                                    </div>
                                                    <div>
                                                        {this.state.memberName}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="bold-text sub-header-size">
                                                        Email:
                                                    </div>
                                                    <div>
                                                        {this.state.email}
                                                    </div>
                                                </div>
                                                {
                                                    this.state.resetMessages['error'] &&
                                                    <div className="page-error login-error">
                                                        <div className="login-error-header">We're sorry, but something went wrong:</div>
                                                        <ul className="settings-error-body">
                                                            {
                                                                this.state.resetMessages["error"] &&
                                                                <li>
                                                                    {this.state.resetMessages["error"]}
                                                                </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                }
                                                {
                                                    this.state.resetMessages['success'] &&
                                                    <div className="page-error login-error success-message">
                                                        <div className="login-error-header">Success!</div>
                                                        <ul className="settings-error-body">
                                                            {
                                                                this.state.resetMessages["success"] &&
                                                                <li>
                                                                    {this.state.resetMessages["success"]}
                                                                </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                }
                                            </CardContent>
                                            <CardActions>
                                                <Button className="settings-page-button" disabled={this.state.loading} onClick={(evt) => this.resetUserPassword(evt, this.state.email)}>
                                                    Request Password Reset Email
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Card className="member-info-card">
                                            <CardContent>
                                                {this.renderSsoInstructions()}
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Card className="member-info-card">
                                            <CardContent>
                                                {this.renderSsoEdit()}

                                                {
                                                    this.state.ssoMessages['error'] &&
                                                    <div className="page-error login-error">
                                                        <div className="login-error-header">We're sorry, but something went wrong:</div>
                                                        <ul className="settings-error-body">
                                                            {
                                                                this.state.ssoMessages["error"] &&
                                                                <li>
                                                                    {this.state.ssoMessages["error"]}
                                                                </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                }
                                                {
                                                    this.state.ssoMessages['success'] &&
                                                    <div className="page-error login-error success-message">
                                                        <div className="login-error-header">Success!</div>
                                                        <ul className="settings-error-body">
                                                            {
                                                                this.state.ssoMessages["success"] &&
                                                                <li>
                                                                    {this.state.ssoMessages["success"]}
                                                                </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                }
                                            </CardContent>
                                            <CardActions>
                                                <Button className="settings-page-button" disabled={this.state.loading} onClick={(evt) => this.handleSaveCreds(evt)}>
                                                    Save Links
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    )
                }
                ;
            case "themeSettings":
                return (
                    <div>
                        {this.renderThemeSettings()}
                    </div>
                );
            case "siteSettings":
                return (<div>{this.renderSiteSettings()}</div>)
            case "logs":
                return (
                    <div>
                        {this.renderLogs()}
                    </div>
                );
            case "users":
                return (
                    <Grid container>
                        <Grid item xs>
                            <Grid container spacing={2} justify="center" className="users-list-and-form">
                                {this.state.mobileView &&
                                    <Grid item xs={12}>
                                        {
                                            this.state.createBoxOpen ?
                                                <CreateUser {...this.props} getUserList={this.getUserList} /> :
                                                <EditUser userProp={this.state.userToEdit}
                                                    emailProp={this.state.userToEdit.email} {...this.props} {...this.props}
                                                    getUserList={this.getUserList} />
                                        }
                                    </Grid>
                                }
                                <Grid item xs={12} md={8}>
                                    {this.renderUserList()}
                                </Grid>
                                {!this.state.mobileView &&
                                    <Grid item md={4}>
                                        {
                                            this.state.createBoxOpen ?
                                                <CreateUser {...this.props} getUserList={this.getUserList} /> :
                                                <EditUser userProp={this.state.userToEdit}
                                                    emailProp={this.state.userToEdit.email} {...this.props} {...this.props}
                                                    getUserList={this.getUserList} />
                                        }
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                );
            default:
                return (<div></div>);
        }
    }

    render() {
        if (!this.state.loggedIn) {
            return (
                <Redirect to='/'></Redirect>
            )
        }

        if(this.state.validated == 'false') {
            handleLogout();
            return (
                <Redirect to='/'></Redirect>
            )
        }

        return (
            <div className="page" id="Settings">
                <NavBar {...this.props} />
                {
                    this.renderSettingsTabs()
                }
                <div>
                    {this.renderTab()}
                </div>
            </div>
        )
    }

    async saveModule(e, type) {
        e.preventDefault();
        let elements = {};
        for (let i = 0; i < e.target.elements.length; i++) {
            let item = e.target.elements[i];
            if (item.name.startsWith(`modules.${type}.`)) {
                elements[item.name] = item.value;
            }
        }
        elements["type"] = type;
        const token = (sessionStorage.getItem('token') !== null) ? sessionStorage.getItem('token') : null;
        try {
            await fetch(`${config.remoteUrl}/config`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(elements),
            });
        } catch (err) {
            alert("Failed to save module");
        }
    }

    async saveMainSettings(e, plaidEnabled) {
        e.preventDefault();
        let elements = {};
        for (let i = 0; i < e.target.elements.length; i++) {
            let item = e.target.elements[i];
            if (item.name === "isPlaidEnabled") {
                elements[item.name] = plaidEnabled;
            } else {
                elements[item.name] = item.value;
            }
        }
        const token = (sessionStorage.getItem('token') !== null) ? sessionStorage.getItem('token') : null;
        try {
            await fetch(`${config.remoteUrl}/config`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(elements),
            });
        } catch (err) {
            alert("Failed to save main settings");
        }
    }

    renderSiteSettings() {
        return (
            <div className="vertical-row w-100">
                <Card className="member-info-card mr-10 w-100 mb-20">
                    <CardContent className="w-100">
                        <h2>Main Settings</h2>
                        <form id="mainSettings" onSubmit={(e) => this.saveMainSettings(e, this.state.isPlaidEnabled.toString())}>
                            <div className="vertical-row mb-20 w-100">
                                <div className="horizontal-row mb-20 w-100">
                                    <div className="input-group">
                                        <div className="input-header">
                                            Company Name
                                        </div>
                                        <div className="input-box w-100">
                                            <input type="text" className="w-100" title="Company Name" name="companyName" defaultValue={configData.companyName} />
                                        </div>
                                    </div>
                                    <div className="input-group">
                                        <div className="input-header">
                                            Logo URL
                                        </div>
                                        <div className="input-box w-100">
                                            <input type="text" className="w-100" title="Logo URL" name="logo" defaultValue={configData.logo} />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group mb-20">
                                    <div className="input-header">
                                        Broadcast
                                    </div>
                                    <div className="input-box w-100">
                                        <input type="text" className="w-100" title="Broadcast" name="broadcast" defaultValue={configData.broadcast} />
                                    </div>
                                </div>
                                <div className="input-box w-100">
                                    <FormControlLabel control={<PlaidSwitch checked={this.state.isPlaidEnabled} onChange={this.handleToggleChange} name="isPlaidEnabled" />} label="Plaid Enabled" />
                                </div>
                                <div className="input-group">
                                    <div className="input-box w-100">
                                        <input type="submit" value="Save" className={"custom-save-button"} style={{ "width": "200px", "float": "right" }} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </CardContent>
                </Card>
                <h2>Module Settings</h2>
                <div className="vertical-row w-100">
                    <div className="horizontal-row w-100">
                        <Card className="member-info-card mb-20 mr-10 w-100">
                            <CardContent className="w-100">
                                <h2>ACH</h2>
                                <form onSubmit={(e) => this.saveModule(e, "ach")}>
                                    <div className="input-group mb-20">
                                        <div className="input-header">
                                            Name
                                        </div>
                                        <div className="input-box w-100">
                                            <input type="text" className="w-100" title="ACH Name" name="modules.ach.name" defaultValue={configData.modules_builder["ach.name"]} />
                                        </div>
                                    </div>
                                    <div className="input-group mr-10 mb-20">
                                        <div className="input-header">
                                            Full URL
                                        </div>
                                        <div className="input-box w-100">
                                            <input type="text" className="w-100" title="ACH Full URL" name="modules.ach.url" defaultValue={configData.modules_builder["ach.url"]} />
                                        </div>
                                    </div>
                                    <div className="input-group mb-20">
                                        <div className="input-header">
                                            Partial URL
                                        </div>
                                        <div className="input-box w-100">
                                            <input type="text" className="w-100" title="ACH Partial URL" name="modules.ach.partialUrl" defaultValue={configData.modules_builder["ach.partialUrl"]} />
                                        </div>
                                    </div>
                                    <div className="input-group">
                                        <div className="input-box w-100">
                                            <input type="submit" value="Save" className={"custom-save-button"} />
                                        </div>
                                    </div>
                                </form>
                            </CardContent>
                        </Card>
                        <Card className="member-info-card mb-20 ml-10 mr-10 w-100">
                            <CardContent className="w-100">
                                <h2>Wire</h2>
                                <form onSubmit={(e) => this.saveModule(e, "wire")}>
                                    <div className="input-group mb-20">
                                        <div className="input-header">
                                            Name
                                        </div>
                                        <div className="input-box w-100">
                                            <input type="text" className="w-100" title="Wire Name" name="modules.wire.name" defaultValue={configData.modules_builder["wire.name"]} />
                                        </div>
                                    </div>
                                    <div className="input-group mr-10 mb-20">
                                        <div className="input-header">
                                            Full URL
                                        </div>
                                        <div className="input-box w-100">
                                            <input type="text" className="w-100" title="Wire Full URL" name="modules.wire.url" defaultValue={configData.modules_builder["wire.url"]} />
                                        </div>
                                    </div>
                                    <div className="input-group mb-20">
                                        <div className="input-header">
                                            Partial URL
                                        </div>
                                        <div className="input-box w-100">
                                            <input type="text" className="w-100" title="Wire Partial URL" name="modules.wire.partialUrl" defaultValue={configData.modules_builder["wire.partialUrl"]} />
                                        </div>
                                    </div>
                                    <div className="input-group">
                                        <div className="input-box w-100">
                                            <input type="submit" value="Save" className={"custom-save-button"} />
                                        </div>
                                    </div>
                                </form>
                            </CardContent>
                        </Card>
                        <Card className="member-info-card mb-20 ml-10 mr-10 w-100">
                            <CardContent className="w-100">
                                <h2>Positive Pay</h2>
                                <form onSubmit={(e) => this.saveModule(e, "posPay")}>
                                    <div className="input-group mb-20">
                                        <div className="input-header">
                                            Name
                                        </div>
                                        <div className="input-box w-100">
                                            <input type="text" className="w-100" title="Positive Pay Name" name="modules.posPay.name" defaultValue={configData.modules_builder["posPay.name"]} />
                                        </div>
                                    </div>
                                    <div className="input-group mr-10 mb-20">
                                        <div className="input-header">
                                            Full URL
                                        </div>
                                        <div className="input-box w-100">
                                            <input type="text" className="w-100" title="Positive Pay Full URL" name="modules.posPay.url" defaultValue={configData.modules_builder["posPay.url"]} />
                                        </div>
                                    </div>
                                    <div className="input-group mb-20">
                                        <div className="input-header">
                                            Partial URL
                                        </div>
                                        <div className="input-box w-100">
                                            <input type="text" className="w-100" title="Positive Pay Partial URL" name="modules.posPay.partialUrl" defaultValue={configData.modules_builder["posPay.partialUrl"]} />
                                        </div>
                                    </div>
                                    <div className="input-group">
                                        <div className="input-box w-100">
                                            <input type="submit" value="Save" className={"custom-save-button"} />
                                        </div>
                                    </div>
                                </form>
                            </CardContent>
                        </Card>
                    </div>
                    <div className="horizontal-row w-100">
                        <Card className="member-info-card mb-20 ml-10 w-100">
                            <CardContent className="w-100">
                                <h2>Integrated Receivables</h2>
                                <form onSubmit={(e) => this.saveModule(e, "payRec")}>
                                    <div className="input-group mb-20">
                                        <div className="input-header">
                                            Name
                                        </div>
                                        <div className="input-box w-100">
                                            <input type="text" className="w-100" title="Integrated/Recievables Name" name="modules.payRec.name" defaultValue={configData.modules_builder["payRec.name"]} />
                                        </div>
                                    </div>
                                    <div className="input-group mr-10 mb-20">
                                        <div className="input-header">
                                            Full URL
                                        </div>
                                        <div className="input-box w-100">
                                            <input type="text" className="w-100" title="Integrated/Recievables Full URL" name="modules.payRec.url" defaultValue={configData.modules_builder["payRec.url"]} />
                                        </div>
                                    </div>
                                    <div className="input-group mb-20">
                                        <div className="input-header">
                                            Partial URL
                                        </div>
                                        <div className="input-box w-100">
                                            <input type="text" className="w-100" title="Integrated/Recievables Partial URL" name="modules.payRec.partialUrl" defaultValue={configData.modules_builder["payRec.partialUrl"]} />
                                        </div>
                                    </div>
                                    <div className="input-group">
                                        <div className="input-box w-100">
                                            <input type="submit" value="Save" className={"custom-save-button"} />
                                        </div>
                                    </div>
                                </form>
                            </CardContent>
                        </Card>
                        <Card className="member-info-card mb-20 ml-10 w-100">
                            <CardContent className="w-100">
                                <h2>Alloya Module</h2>
                                <form onSubmit={(e) => this.saveModule(e, "alloy")}>
                                    <div className="input-group mb-20">
                                        <div className="input-header">
                                            Name
                                        </div>
                                        <div className="input-box w-100">
                                            <input type="text" className="w-100" title="Alloya Name" name="modules.alloy.name" defaultValue={configData.modules_builder["alloy.name"]} />
                                        </div>
                                    </div>
                                    <div className="input-group mr-10 mb-20">
                                        <div className="input-header">
                                            Full URL
                                        </div>
                                        <div className="input-box w-100">
                                            <input type="text" className="w-100" title="Alloy Full URL" name="modules.alloy.url" defaultValue={configData.modules_builder["alloy.url"]} />
                                        </div>
                                    </div>
                                    <div className="input-group mb-20">
                                        <div className="input-header">
                                            Partial URL
                                        </div>
                                        <div className="input-box w-100">
                                            <input type="text" className="w-100" title="Alloy Partial URL" name="modules.alloy.partialUrl" defaultValue={configData.modules_builder["alloy.partialUrl"]} />
                                        </div>
                                    </div>
                                    <div className="input-group">
                                        <div className="input-box w-100">
                                            <input type="submit" value="Save" className={"custom-save-button"} />
                                        </div>
                                    </div>
                                </form>
                            </CardContent>
                        </Card>
                        <Card className="member-info-card mb-20 ml-10 w-100">
                            <CardContent className="w-100">
                                <h2>Elavon Module</h2>
                                <form onSubmit={(e) => this.saveModule(e, "elavon")}>
                                    <div className="input-group mb-20">
                                        <div className="input-header">
                                            Name
                                        </div>
                                        <div className="input-box w-100">
                                            <input type="text" className="w-100" title="Elavon Name" name="modules.elavon.name" defaultValue={configData.modules_builder["elavon.name"]} />
                                        </div>
                                    </div>
                                    <div className="input-group mr-10 mb-20">
                                        <div className="input-header">
                                            Full URL
                                        </div>
                                        <div className="input-box w-100">
                                            <input type="text" className="w-100" title="Elavon Full URL" name="modules.elavon.url" defaultValue={configData.modules_builder["elavon.url"]} />
                                        </div>
                                    </div>
                                    <div className="input-group mb-20">
                                        <div className="input-header">
                                            Partial URL
                                        </div>
                                        <div className="input-box w-100">
                                            <input type="text" className="w-100" title="Elavon Partial URL" name="modules.elavon.partialUrl" defaultValue={configData.modules_builder["elavon.partialUrl"]} />
                                        </div>
                                    </div>
                                    <div className="input-group">
                                        <div className="input-box w-100">
                                            <input type="submit" value="Save" className={"custom-save-button"} />
                                        </div>
                                    </div>
                                </form>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
        );
    }

    renderSettingsTabs() {
        if (this.state.isAdmin) {
            return (
                <div className="settings-tab-container">
                    <button className={`sso-menu-button 
                        ${this.state.tabSelected === "accountInfo"
                            ? "selected-setting"
                            : ""}`
                    } onClick={(evt) => this.handleTabChange("accountInfo")}>
                        Account Info
                    </button>
                    <button className={`sso-menu-button 
                        ${this.state.tabSelected === "users"
                            ? "selected-setting"
                            : ""}`
                    } onClick={(evt) => this.handleTabChange("users")}>
                        Users
                    </button>
                    <button className={`sso-menu-button 
                        ${this.state.tabSelected === "logs"
                            ? "selected-setting"
                            : ""}`
                    } onClick={this.getLogsList}>
                        Logs
                    </button>
                    <button className={`sso-menu-button 
                        ${this.state.tabSelected === "themeSettings"
                            ? "selected-setting"
                            : ""}`
                    } onClick={() => this.handleTabChange("themeSettings")}>
                        Colors
                    </button>
                    <button className={`sso-menu-button 
                        ${this.state.tabSelected === "siteSettings"
                            ? "selected-setting"
                            : ""}`
                    } onClick={() => this.handleTabChange("siteSettings")}>
                        Settings
                    </button>
                </div>
            )
        }
        else {
            return (
                <div className="settings-tab-container">
                    <button className={`sso-menu-button ${this.state.tabSelected === "accountInfo"
                        ? "selected-setting"
                        : ""}`
                    } onClick={(evt) => this.handleTabChange("accountInfo")}>
                        Account Info
                    </button>
                </div>
            )
        }
    }
}
