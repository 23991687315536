import React, { Component } from 'react';
import { config as configData } from '../../config';
import { Redirect } from 'react-router-dom';
import { checkAdmin } from '../../modules/AdminActionManager';
import { getFaq, saveFaq } from '../../modules/FaqManager.js';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { NavBar } from '../../components/NavBar';
import { Grid } from '@material-ui/core';

export class FAQEdit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingStatus: true,
            logoUrl: "",
            questionsAndAnswers: [],
            isAdmin: checkAdmin(),
            messages: []
        }
    }

    componentDidMount() {
        this.isBackButtonClicked = false;

        window.addEventListener('beforeunload', this.handleLeavePage);

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', this.handleGoBack);

        getFaq().then((questionsAndAnswersFromAPI) => {
            this.setState({
                loadingStatus: false,
                logoUrl: configData.logo,
                questionsAndAnswers: questionsAndAnswersFromAPI
            });
        });
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleLeavePage);
        window.removeEventListener('popstate', this.handleGoBack);
    }

    handleLeavePage = (event) => {
        event.preventDefault();
        event.returnValue = 'Are you sure you wish to leave the page? All unsaved changes will be lost.';
        return event.returnValue;
    }

    handleGoBack = (event) => {
        if (window.confirm("Are you sure you wish to leave the page? All unsaved changes will be lost.")) {
            if (event.type === 'popstate') {
                window.history.back();
                return true;
            }
            else if(event.type === 'click') {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            event.preventDefault();
            return false;
        }
    }

    handleFieldChange = (event) => {
        let newQuestionsAndAnswers = this.state.questionsAndAnswers;

        let indexToChange = event.target.parentElement.id;

        newQuestionsAndAnswers[indexToChange][event.target.id] = event.target.value;

        this.setState({
            questionsAndAnswers: newQuestionsAndAnswers
        });
    }

    deleteFromFaq = (event, index) => {
        event.preventDefault();
        let newQuestionsAndAnswers = this.state.questionsAndAnswers;
        newQuestionsAndAnswers.splice(index, 1);
        this.setState({
            questionsAndAnswers: newQuestionsAndAnswers
        });
    }

    addToFaq = (event) => {
        event.preventDefault();
        let newQuestionsAndAnswers = this.state.questionsAndAnswers;
        newQuestionsAndAnswers.push({ faqid: this.state.questionsAndAnswers.length + 1, question: "", answer: "" });
        this.setState({
            questionsAndAnswers: newQuestionsAndAnswers
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            loadingStatus: true
        });

        let messagesToAdd = [];

        saveFaq(this.state.questionsAndAnswers).then((response) => {
            if (response.status === 'success') {
                this.props.history.push('/faq');
            }
            else {
                messagesToAdd['error'] = response.message;
                this.setState({
                    messages: messagesToAdd,
                    loadingStatus: false
                });
            }
        }).catch((error) => {
            messagesToAdd['error'] = "We're unable to contact the database at this time. Please contact the administrator or try again later."
            this.setState({
                messages: messagesToAdd,
                loadingStatus: false
            });
        });
    }

    handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = this.state.questionsAndAnswers;
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        this.setState({
            questionsAndAnswers: items
        });
    }

    render() {
        let faqList = <p className="page-text">Loading...</p>

        if (!this.state.isAdmin) {
            return (
                <Redirect to='/faq'></Redirect>
            )
        }

        if (!this.state.loadingStatus) {
            faqList =
                <DragDropContext onDragEnd={this.handleOnDragEnd}>
                    <Droppable droppableId="faq-list">
                        {(provided) => (
                            <ul className="faq-list" ref={provided.innerRef}>
                                <Grid container justify="center">
                                    <Grid item xs={12} md={8}>
                                    {
                                        this.state.questionsAndAnswers.map((questionAndAnswer, index) => (
                                            <Draggable key={questionAndAnswer.faqid} draggableId={questionAndAnswer.faqid.toString()} index={index}>
                                                {(provided) => (

                                                    <li id={index} className="faq-edit-pair" ref={provided.innerRef}  {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        <textarea
                                                            onChange={this.handleFieldChange}
                                                            id='question'
                                                            value={questionAndAnswer.question}
                                                            className="page-input faq-textarea"
                                                            rows="3"
                                                            autoComplete='off' />
                                                        <textarea
                                                            onChange={this.handleFieldChange}
                                                            id='answer'
                                                            value={questionAndAnswer.answer}
                                                            className="page-input faq-textarea"
                                                            rows="5"
                                                            autoComplete='off' />
                                                        <button
                                                            onClick={(event) => {
                                                                if (window.confirm('Are you sure you wish to delete this item?')) this.deleteFromFaq(event, index);
                                                                else event.preventDefault();
                                                            }}
                                                            className="page-button delete"
                                                            disabled={this.state.loadingStatus}>
                                                            Delete
                                                    </button>
                                                    </li>

                                                )}
                                            </Draggable>
                                        ))
                                    }
                                    {provided.placeholder}
                                </Grid>
                                </Grid>
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>

        }

        return (
            <div id="faq-edit" className="page">
                <NavBar {...this.props} changeProp={this.handleGoBack}/>
                <Grid container className="page-form">
                    <form onSubmit={(evt) => this.handleSubmit(evt)} className="page-inner-form">
                        <h3 className="page-text">Edit FAQ</h3>
                        <div className="page-error login-error">
                            Please save all changes before leaving page. Failing to do so will result in lost work.
                        </div>
                        <p className="faq-text">On this page, you can add or delete new question/answer pairs, as well as edit the ones that already exist. You can also drag and drop pairs to re-order your FAQ. When you're finished, please click 'Save' at the bottom of the page.</p>
                        {faqList}
                        {
                            this.state.messages["error"] &&
                            <div className="page-error login-error">
                                <div className="login-error-header">We're sorry, but something went wrong:</div>
                                <ul className="page-error-body">
                                    {
                                        this.state.messages["error"] &&
                                        <li>
                                            {this.state.messages["error"]}
                                        </li>
                                    }
                                </ul>
                            </div>
                        }
                        <div className="edit-button-container">
                            <button
                                onClick={(evt) => this.addToFaq(evt)}
                                className="page-button add"
                                disabled={this.state.loadingStatus}>
                                Add
                        </button>
                            <button
                                className="page-button"
                                disabled={this.state.loadingStatus}
                                type="submit">
                                Save
                        </button>
                            <button
                                onClick={(event) => {
                                    if (window.confirm('Are you sure you wish to cancel? All unsaved changes will be lost.')) this.props.history.push('/faq');
                                    else event.preventDefault();
                                }}
                                className="page-button delete cancel"
                                disabled={this.state.loadingStatus}>
                                Cancel
                            </button>
                        </div>
                    </form>
                </Grid>
            </div>
        )
    }
};