import { config } from '../config';
import { getToken } from './LoginManager';

export const bannerStatus = () => {
    return sessionStorage.getItem('showBanner') === "true"
}

export const showBanner = () => {
    sessionStorage.setItem('showBanner', "true");
}

export const hideBanner = () => {
    sessionStorage.setItem('showBanner', "false");
}

export const getBroadcast = async () => {
    const token = getToken();
    return new Promise(async (resolve) => {
        if(token) {
            const response = await fetch(`${config.remoteUrl}/broadcast`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            if(response.status === 200) {
                const { broadcast } = await response.json();
                resolve(broadcast);
            } else {
                resolve("Unauthorized");
            }
        }
    });
}

export const saveBroadcast = (broadcastMessage) => {
    const token = getToken();
    return fetch(`${config.remoteUrl}/broadcast/update`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ "broadcast": broadcastMessage }),
    });
}