import React, { useCallback, useEffect, useState } from "react";
import {
    handleGetUserAccessTokens,
    handlePlaidExchangePublicToken,
    handlePlaidGetAccounts,
    handlePlaidInitUserData
} from "../../modules/PlaidManager";
import { PlaidLink } from "react-plaid-link";
import Loader from "react-loader-spinner";
import { handlePlaidCreateLinkToken } from "../../modules/PlaidManager";

export const PlaidLinkButton = () => {
    const [isLinking, setIsLinking] = useState(false);
    const [linkToken, setLinkToken] = useState(null);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        let mounted = true;
        if (sessionStorage.getItem("isSubUser") && sessionStorage.getItem("isSubUser") != "true") {
            handlePlaidCreateLinkToken().then((res) => {
                if (mounted) {
                    setLinkToken(res.data.link_token)
                }
            }, (err) => {
                console.log('Plaid link error:', err);
                alert("There was an issue getting a token from Plaid. If this persists, please contact support.");
            });
        }

        return () => mounted = false;
    }, []);

    const onSuccess = useCallback((token, metadata) => {
        setIsLinking(true);
        handlePlaidExchangePublicToken(token, metadata).then((resp) => {
            handlePlaidInitUserData().then((resp) => {
                handleGetUserAccessTokens().then((resp) => {
                    // if so, get those accounts and transactions and store in session storage
                    if (resp.status === 200) {
                        handlePlaidGetAccounts().then((resp) => {
                            sessionStorage.setItem('accounts', JSON.stringify(resp.data));
                            window.location.reload();
                        });
                    } else {
                        console.log("Error: ", resp);
                    }
                });
            });
        });
        //props.history.push({pathname: props.location.pathname, state: {link: true, token: token}});
    }, []);

    const onError = useCallback((err) => {
        setError(err);
    }, []);

    if (sessionStorage.getItem("isSubUser") && sessionStorage.getItem("isSubUser") == "true") {
        return (<></>)
    }

    if(isLinking) {
        return (<div className="loaderHolder"><Loader
            type="Oval"
            color={"#000"}
            height={80}
            width={80}
            timeout={30000} //30 secs
        /><br /><h3>Please do not refresh your browser</h3></div>)
    } else {
        return (
            <div className="plaid-link-button-container">
                {!!error && (
                    <div>{error}</div>
                )}
                {!!linkToken && <PlaidLink
                    className="custom-button"
                    token={linkToken}
                    onSuccess={(token, metadata) => onSuccess(token, metadata)}
                    onError={(err) => onError(err)}
                >
                    CONNECT YOUR ACCOUNTS
                </PlaidLink>}
            </div>
        );
    }
};
