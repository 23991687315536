let configUrl = "";
let local = false;
if ( window.location.origin.startsWith("http://uat.tt.local") ||
    window.location.origin.startsWith("https://uat.tt") ) {
    const currentSub = window.location.host.split(".")[0];
    configUrl = "http://uat.tt.local:8081/apis/v1/config/" + currentSub;
    local = true;
} else {
    var host = window.location.host;
    var subdomain = host.split('.')[0];
    configUrl = window.location.origin + "/apis/v1/config/" + subdomain;
}
export const load = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await fetch(configUrl);
            const response = await res.json();
            let configBuilder = {};
            configBuilder['colors'] = {};
            configBuilder['modules_builder'] = {};
            configBuilder['modules'] = [];
            response.map((item, index) => {
                // Build data into json that was originally present in file.
                if (item.key === 'broadcast') {
                    configBuilder['broadcast'] = item.value;
                } else if (item.key === 'logo') {
                    configBuilder['logo'] = item.value;
                } else if (item.key === 'companyName') {
                    configBuilder['companyName'] = item.value;
                } else if (item.key === 'isPlaidEnabled') {
                    configBuilder['isPlaidEnabled'] = item.value;
                } else if (item.key === 'remoteUrl') {
                    configBuilder['remoteUrl'] = local ? 'http://uat.tt.local:8081/apis/v1' : item.value;
                } else if (item.key.startsWith('color')) {
                    configBuilder['colors'][item.key.split("colors.")[1]] = item.value;
                } else if (item.key.startsWith('modules.')) {
                    configBuilder['modules_builder'][item.key.split("modules.")[1]] = item.value;
                } else if (item.key === "mfa_enabled") {
                    configBuilder["mfa_enabled"] = item.value;
                }// Else, do not store.
            });

            // Build ACH array item.
            const achKey = configBuilder['modules_builder']['ach.key'];
            const achName = configBuilder['modules_builder']['ach.name'];
            const achUrl = configBuilder['modules_builder']['ach.url'];
            const achPartialUrl = configBuilder['modules_builder']['ach.partialUrl'];

            // Build Positive Pay array item.
            const posPayKey = configBuilder['modules_builder']['posPay.key'];
            const posPayName = configBuilder['modules_builder']['posPay.name'];
            const posPayUrl = configBuilder['modules_builder']['posPay.url'];
            const posPayPartialUrl = configBuilder['modules_builder']['posPay.partialUrl'];

            // Build Wire array item.
            const wireKey = configBuilder['modules_builder']['wire.key'];
            const wireName = configBuilder['modules_builder']['wire.name'];
            const wireUrl = configBuilder['modules_builder']['wire.url'];
            const wirePartialUrl = configBuilder['modules_builder']['wire.partialUrl'];

            // Build Integrated Recievables array item.
            const payRecKey = configBuilder['modules_builder']['payRec.key'];
            const payRecName = configBuilder['modules_builder']['payRec.name'];
            const payRecUrl = configBuilder['modules_builder']['payRec.url'];
            const payRecPartialUrl = configBuilder['modules_builder']['payRec.partialUrl'];

            // Build Alloya array item.
            const alloyKey = configBuilder['modules_builder']['alloy.key'];
            const alloyName = configBuilder['modules_builder']['alloy.name'];
            const alloyUrl = configBuilder['modules_builder']['alloy.url'];
            const alloyPartialUrl = configBuilder['modules_builder']['alloy.partialUrl'];

            // Build Elavon array item.
            const elavonKey = configBuilder['modules_builder']['elavon.key'];
            const elavonName = configBuilder['modules_builder']['elavon.name'];
            const elavonUrl = configBuilder['modules_builder']['elavon.url'];
            const elavonPartialUrl = configBuilder['modules_builder']['elavon.partialUrl'];
            // Add ACH to modules array
            configBuilder['modules'].push(
                {
                    key: achKey,
                    name: achName,
                    url: achUrl,
                    partialUrl: achPartialUrl
                }
            );

            // Add Wire to modules array
            configBuilder['modules'].push(
                {
                    key: wireKey,
                    name: wireName,
                    url: wireUrl,
                    partialUrl: wirePartialUrl
                }
            );

            // Add Positive Pay to modules array
            configBuilder['modules'].push(
                {
                    key: posPayKey,
                    name: posPayName,
                    url: posPayUrl,
                    partialUrl: posPayPartialUrl
                }
            );

            // Add Integreated Recievables to modules array
            configBuilder['modules'].push(
                {
                    key: payRecKey,
                    name: payRecName,
                    url: payRecUrl,
                    partialUrl: payRecPartialUrl
                }
            );
            // Add Alloya to modules array
            configBuilder['modules'].push(
                {
                    key: alloyKey,
                    name: alloyName,
                    url: alloyUrl,
                    partialUrl: alloyPartialUrl
                }
            );
            // Add Elavon to modules array
            configBuilder['modules'].push(
                {
                    key: elavonKey,
                    name: elavonName,
                    url: elavonUrl,
                    partialUrl: elavonPartialUrl
                }
            );
            // Remove the builder object
            localStorage.setItem('configuration', JSON.stringify(configBuilder));
            config = configBuilder;
            resolve(configBuilder);
        } catch (e) {
            alert("Failed to load configuration. If this persists, please contact support.");
            reject("Failed to load config");
        }
    })
}

// Poor mans state
export let config = JSON.parse(localStorage.getItem("configuration")) || {};
