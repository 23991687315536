import { config } from '../config';
import { checkTokenExpiration } from './LoginManager';

export const handleGetUsersList = () => {
    const userToken = sessionStorage.getItem("token");

    return fetch(`${config.remoteUrl}/users`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userToken}`
        }
    })
        .then(checkTokenExpiration)
        .then((data) => data.json());
};
