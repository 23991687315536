import React, { useEffect, useState } from "react";
import { load as loadConfig, config } from './config';
import './App.css';
import './pages/pages.css';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { ForgotPassword, Login, ResetPassword, FAQ, FAQEdit, Dashboard, Settings, PrivacyPolicy, BroadcastEdit, Token, RdcSsoRedirect, RdcSsoLogin} from './pages';
import history from './history';
import { appInit, appClose } from "./init";
import Loader from "react-loader-spinner";

function App() {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function loadConfiguration() {
            try {
                await loadConfig();
            } catch(e) {
                alert(e);
            }
            document.title = config.companyName;
            setLoading(false);
            const timer = appInit();
            return () => {
                appClose(timer);
            }
        }
        loadConfiguration();
    });

    if(loading) {
        return (
            <Loader
                    type="Oval"
                    color="#000"
                    height={80}
                    width={80}
                    timeout={30000} //30 secs
                    style={{ marginLeft: "45%", marginTop: "200px" }}
                />
        )
    } else {
        return (
            <Router history={history}>
                <div>
                    <Switch>
                        <Route exact path="/" component={Login} />
                        <Route exact path="/reset-password" component={ResetPassword} />
                        <Route exact path="/forgot-password" component={ForgotPassword} />
                        <Route exact path="/faq" component={FAQ} />
                        <Route exact path="/faq/edit" component={FAQEdit} />
                        <Route exact path="/faq/edit-broadcast" component={BroadcastEdit} />
                        <Route exact path="/dashboard" component={Dashboard} />
                        <Route exact path="/settings" component={Settings} />
                        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                        <Route exact path="/sso" component={Token} />
                        <Route exact path="/sso/rdc/redirect" component={RdcSsoRedirect } />
                        <Route exact path="/sso/rdc/login" component={RdcSsoLogin } />
                        <Route render={() => <Redirect to={{ pathname: "/" }} />} />
                    </Switch>
                </div>
            </Router>
        )
    }
}

export default App;
