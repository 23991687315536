import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { handleApiForgotPassword } from '../../modules/LoginManager';
import { NavBar } from '../../components/NavBar';
import { Box, Grid } from '@material-ui/core';

export class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            loadingStatus: true,
            messages: [],
            submitted: false
        }
    }

    componentDidMount() {
        this.setState({
            loadingStatus: false
        })
    }

    handleFieldChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleEmailRequirements = () => {
        if (!this.state.loadingStatus) {
            let messagesToAdd = [];
            let isValidEmail = true;
            let regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            this.setState({
                messages: messagesToAdd
            });

            if (this.state.email === "") {
                messagesToAdd["email"] = "Please enter an email address";
                isValidEmail = false;
            } else if (!regEx.test(this.state.email)) {
                messagesToAdd["email"] = "Please enter a valid email address";
                isValidEmail = false;
            }

            this.setState({
                messages: messagesToAdd,
            });

            return isValidEmail;
        }

    }

    handleSubmit = (event) => {
        event.preventDefault();

        this.setState({
            loadingStatus: true
        });

        if (this.handleEmailRequirements()) {
            handleApiForgotPassword(this.state.email).then((response) => {
                if (response.message === 'Unable to send automated message') {
                    throw new Error();
                }
                else {
                    this.setState({
                        submitted: true
                    });
                }
            }).catch((error) => {
                let messagesToAdd = [];
                messagesToAdd["error"] = "We were unable to send your email. Please try again or ask an administrator for help."
                this.setState({
                    messages: messagesToAdd,
                    loadingStatus: false
                });
            })
        }
        else {
            this.setState({
                loadingStatus: false
            });
        }
    }

    render() {
        let emailEntry =
            <form onSubmit={this.handleSubmit} className="page-inner-form">
                <h3 className="page-text">Forgot Password Form</h3>
                <label htmlFor="email" className="page-text">Please enter your email address below.
                </label>
                <input
                    onChange={this.handleFieldChange}
                    name="email"
                    id="email"
                    placeholder="Email address"
                    autoFocus="on"
                    value={this.state.email}
                    autoComplete="off"
                    className="page-input"
                />
                {
                    (this.state.messages["email"] || this.state.messages["error"]) &&
                    <div className="page-error login-error">
                        <div className="login-error-header">We're sorry, but something went wrong:</div>
                        <ul className="page-error-body">
                            {
                                this.state.messages["email"] &&
                                <li>
                                    {this.state.messages["email"]}
                                </li>
                            }
                            {
                                this.state.messages["error"] &&
                                <li>
                                    {this.state.messages["error"]}
                                </li>
                            }
                        </ul>
                    </div>
                }
                <button
                    className="page-button"
                    disabled={this.state.loadingStatus}
                    type="submit">
                    Submit
                </button>

            </form>

        if (this.state.submitted) {
            emailEntry =
                <form className="page-inner-form">
                    <h3 className="page-text">Forgot Password Form</h3>
                    <div className="page-text">Thank you! You will receive an email shortly, which will contain a link to reset your password.</div>
                </form>
        }

        return (
            <Box className="page" id="forgot-password">
                <NavBar {...this.props} />
                <Grid container alignContent="center" className="page-form">
                    {emailEntry}
                    <Link to='/' className="page-link">Back to Login</Link>
                </Grid>
            </Box>
        );
    }
}