import React, { Component } from "react";
import { Link } from "react-router-dom";
import { handleApiResetPassword } from "../../modules/LoginManager";
import queryString from 'query-string'
import { NavBar } from "../../components/NavBar";
import { Grid } from '@material-ui/core';

export class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newPassword: "",
            confirmPassword: "",
            initialRequirements: true,
            passLengthMet: false,
            passLowerMet: false,
            passUpperMet: false,
            passNumMet: false,
            passSpecialMet: false,
            requirementsError: false,
            matchingError: false,
            passwordChangeError: false,
            messages: [],
            loading: false,
            credentialsGood: false,
            submitted: false
        }
    }

    componentDidMount() {
        const queries = queryString.parse(this.props.location.search)
        if (!queries.token || !queries.id) {
            this.props.history.push('/');
        }
    }

    getLogo() {
        const config = require("../../config");
        return config.logo;
    }

    handleRequirements(event) {
        if (!this.state.loading && event.type === "change") {
            if (event.target.id === "newPassword") {
                this.setState({ newPassword: event.target.value });
            } else if (event.target.id === "confirmPassword") {
                this.setState({ confirmPassword: event.target.value });
            }

            // Regex for at least
            // 1 lowercase, 1 uppercase, 1 numeral, 1 special char (!@#$)
            const reLower = new RegExp("^(?=.*[a-z]).+$").test(event.target.value);
            const reUpper = new RegExp("^(?=.*[A-Z]).+$").test(event.target.value);
            const reNum = new RegExp("^(?=.*\\d).+$").test(event.target.value);
            const reSpecial = new RegExp("(?=.*[!@#$]).+$").test(event.target.value);
            const requiredLength = 8;
            const lengthMet = event.target.value.length >= requiredLength;

            let requirementsMet = reLower && reUpper && reNum && reSpecial && lengthMet;

            let matchingError = this.checkMatching();

            this.setState({
                passLengthMet: lengthMet,
                passLowerMet: reLower,
                passUpperMet: reUpper,
                passNumMet: reNum,
                passSpecialMet: reSpecial,
                requirementsError: !requirementsMet,
                matchingError: matchingError,
                credentialsGood: !matchingError && requirementsMet,
                initialRequirements: false
            });

            if(!matchingError){
                this.removeMatchingError();
            }
        }
    }

    checkMatching() {
        let matching = true;
        if ((this.state.newPassword.length > 0 && this.state.confirmPassword.length > 0) && (this.state.newPassword !== this.state.confirmPassword)) {
            matching = false;
        }
        return matching;
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({
            passwordChangeError: false,
            loading: true
        });

        if (this.state.newPassword.length > 0 &&
            this.state.confirmPassword.length > 0 &&
            !this.state.requirementsError &&
            !this.state.matchingError
            && event.type === "submit"
        ) {
            const queries = queryString.parse(this.props.location.search);

            handleApiResetPassword(queries.token, queries.id, this.state.newPassword, this.state.confirmPassword).then((response) => {
                if (response.status === 'success') {
                    sessionStorage.clear();
                    localStorage.clear();
                    this.setState({
                        submitted: true,
                        loading: false
                    });
                }
                else if (response.status === 'error') {
                    this.setState({
                        passwordChangeError: true,
                        loading: false
                    });
                }
            }).catch((error) => {
                this.setState({
                    passwordChangeError: true,
                    loading: false
                });
            })
        }
        else {
            this.setState({
                loading: false
            });
        }
    }

    handleError(event) {
        if (this.state.requirementsError) {
            this.setState({ showReqError: true });
        } else {
            this.setState({ showReqError: false });
        }

        if (this.state.matchingError) {
            this.setState({ showMatchError: true });
        } else {
            this.setState({ showMatchError: false });
        }
    }

    removeMatchingError(){
        this.setState({ showMatchError: false });
    }

    render() {
        let passwordEntry = <Grid container alignContent="center" className="page-form">
            <div className="page-text reset-errors">
                Passwords are case sensitive and must be:
            <ul className="reset-error-body">
                    <li className={
                        this.state.initialRequirements
                            ? "requirements-initial"
                            : (this.state.passLengthMet ? "requirement-met" : "requirement-missing")
                    }>A minimum of eight (8) or more characters</li>
                    <li className={
                        this.state.initialRequirements
                            ? "requirements-initial"
                            : (this.state.passUpperMet ? "requirement-met" : "requirement-missing")
                    }>Contain one (1) capital letter</li>
                    <li className={
                        this.state.initialRequirements
                            ? "requirements-initial"
                            : (this.state.passLowerMet ? "requirement-met" : "requirement-missing")
                    }>Contain one (1) lower case letter</li>
                    <li className={
                        this.state.initialRequirements
                            ? "requirements-initial"
                            : (this.state.passNumMet ? "requirement-met" : "requirement-missing")
                    }>Contain one (1) number</li>
                    <li className={
                        this.state.initialRequirements
                            ? "requirements-initial"
                            : (this.state.passSpecialMet ? "requirement-met" : "requirement-missing")
                    }>Contain one (1) special character (!@#$)</li>
                </ul>
            </div>
            <form onSubmit={(evt) => this.handleSubmit(evt)} className="page-inner-form">
                <div className="page-text">
                    New Password
                </div>
                <input type="password"
                    className="page-input"
                    id="newPassword"
                    value={this.state.newPassword}
                    onChange={(evt) => this.handleRequirements(evt)}
                    onBlur={(evt) => this.handleError(evt)}
                />
                {
                    this.state.showReqError &&
                    <div className="page-error">
                        <div className="page-error-header">One or more of the above requirements were not met</div>
                    </div>
                }
                <div className="page-text">
                    Confirm Password
            </div>
                <input type="password"
                    className="page-input"
                    id="confirmPassword"
                    value={this.state.confirmPassword}
                    onChange={(evt) => this.handleRequirements(evt)}
                    onBlur={(evt) => this.handleError(evt)}
                />
                {
                    this.state.showMatchError &&
                    <div className="page-error">
                        Passwords do not match.
                    </div>
                }
                {
                    this.state.passwordChangeError &&
                    <div className="reset-api-error">
                        We were unable to change your password. Please request another password reset, navigate here using the link in the generated email, and try again. Alternatively, ask an administrator for help.
                    </div>
                }
                <button type="submit" disabled={this.state.loading || !this.state.credentialsGood} className="page-button">Reset</button>
            </form>
            <Link to='/' className="page-link">Back to Login</Link>
        </Grid>

        if (this.state.submitted) {
            passwordEntry =
                <Grid container alignContent="center" className="page-form">
                    <form className="page-inner-form">
                        <h3 className="page-text">Success!</h3>
                        <div className="page-text">Your password has been reset. Please return to the login page using the link below.</div>
                    </form>
                    <Link to='/' className="page-link">Back to Login</Link>
                </Grid>
        }
        return (
            <div id="ResetPassword" className="page">
                <NavBar {...this.props} />
                {passwordEntry}
            </div>
        );
    }
}
