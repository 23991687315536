import React, { Component} from 'react';
import Loader from 'react-loader-spinner';
import { config } from '../../config';
import { saveLogin } from '../../modules/LoginManager';
import { handleGetUserAccessTokens, handlePlaidGetAccounts } from '../../modules/PlaidManager';

export class Token extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: null,
            loading: true, 
            error: null
        };
    }

    async componentDidMount() {
        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams.get('token');
        this.setState({ token });
        try {
            const requestBody = JSON.stringify({token});
            const response = await fetch(`${config.remoteUrl}/token/consume`, {headers: { 'Content-Type': 'application/json' }, method: "POST", body: requestBody});
            const user = await response.json();
            if (!response.ok) {
                this.setState({ error: user.message, loading: false });
              return;
            }
            saveLogin(user.data);
            handleGetUserAccessTokens().then((resp) => {
                if (resp.status === 200) {
                    handlePlaidGetAccounts().then((resp) => {
                        this.setState({ loading: false });
                        if(resp.data.length > 0) sessionStorage.setItem('accounts', JSON.stringify(resp.data));
                        this.props.history.push("/dashboard");
                    });
                } else if (resp.status === 204) {
                    if(user.data.is_sub_user) {
                        handlePlaidGetAccounts().then((resp) => {
                            this.setState({ loading: false });
                            if(resp.data.length > 0) sessionStorage.setItem('accounts', JSON.stringify(resp.data));
                            this.props.history.push("/dashboard");
                        });
                    } else {
                        this.setState({ loading: false });
                        this.props.history.push("/dashboard");
                    }
                }
            });
            this.setState({ data: user.data });
          } catch (error) {
            this.setState({ error: error.message, loading: false });
        }
    }

    render() {
        if (this.state.loading) {
            return <Loader
                type="Oval"
                color="#000"
                height={80}
                width={80}
                timeout={30000} //30 secs
                style={{ marginLeft: "45%", marginTop: "200px" }}
            />;
        }
      
        if (this.state.error) {
            return <div>Error: {this.state.error}</div>;
        }
        return (
            <div>Logged in! Redirecting...</div>
        )
    }
}