import React from 'react';
import { config } from '../../config';
import Loader from "react-loader-spinner";

export class AssignUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            accountsLoading: false,
            users: [],
            selectedUser: 0,
            accounts: [],
            accountsSelected: []
        }
    }

    async componentDidMount() {
        const users = await this.getUserList();
        this.setState({ users });
        this.setState({ loading: false });
        if(this.props.parentAccount && this.props.parentAccount !== 0) {
            await this.getAccountsById(this.props.parentAccount);
        }
    }


    handleClose = () => {
        let selectedAccounts = [];
        if (document.getElementById("accountsSelected")) {
            const selectElement = document.getElementById("accountsSelected");
            for (let i = 0; i < selectElement.selectedOptions.length; i++) {
                selectedAccounts.push(selectElement.selectedOptions[i].id);
            }
        }
        if (selectedAccounts.length === 0) {
            alert("You must assign at least one account!");
        } else {
            this.props.handleSelect(selectedAccounts);
        }
    }

    handleCancel = () => {
        this.props.handleCancel();
    }

    getUserList = async () => {
        return new Promise(async (resolve, reject) => {
            const token = (sessionStorage.getItem('token') !== null) ? sessionStorage.getItem('token') : null;
            try {
                const response = await fetch(`${config.remoteUrl}/accounts/primary`, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    }
                });
                const users = await response.json();
                resolve(users);
            } catch (e) {
                reject(e);
            }
        })
    }

    getAccountsById = async (id) => {
        this.setState({ accountsLoading: true });
        try {
            console.log("Loading accounts");
            const token = (sessionStorage.getItem('token') !== null) ? sessionStorage.getItem('token') : null;
            this.setState({ selectedUser: id });
            this.setState({ accountsLoading: true });
            const response = await fetch(`${config.remoteUrl}/plaid/get_accounts/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const parsedResponse = await response.json();
            this.setState({ accounts: parsedResponse.data });
            this.setState({ accountsLoading: false });
        } catch (ex) {
            console.log(ex);
            alert("Failed to get accounts.");
        }
    }

    handleAccountChange = async (e) => {
        this.setState({ accountsLoading: true });
        const targetUserId = e.target.value;
        await this.getAccountsById(targetUserId);
    }

    render() {
        if (this.state.loading) {
            return (
                <>
                    <div className="page-shadow"></div>
                    <div className="modal-container">
                        <div className="modal-box">
                            <Loader
                                type="Oval"
                                color="#000"
                                height={80}
                                width={80}
                                timeout={30000}
                                style={{ marginLeft: "45%", marginTop: "20px" }}
                            />
                        </div>
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <div className="page-shadow"></div>
                    <div className="modal-container">
                        <div className="modal-box">
                            <div className="input-group mb-20">
                                <div className="input-header">
                                    Select User
                                </div>
                                <div className="input-box">
                                    <select onChange={this.handleAccountChange} name="user" style={{ "padding": "12px", "border": "1px solid var(--border)", "borderRadius": "4px", "width": "100%" }}>
                                        <option key='0' value='0'>Select a User</option>
                                        {this.state.users.map(user => {
                                            return (<>
                                                {user.userid === this.props.parentAccount ? 
                                                <option key={user.userid} value={user.userid} selected>{user.name} ({user.email})</option>
                                                :
                                                <option key={user.userid} value={user.userid}>{user.name} ({user.email})</option>
                                                }
                                            </>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="input-group mb-20">
                                <div className="input-header">
                                    Select Account
                                </div>
                                <div className="input-box">
                                    {this.state.selectedUser == 0 ?
                                        <span>No user selected</span>
                                        :
                                        <>
                                            {this.state.accountsLoading ?
                                                <Loader
                                                    type="Oval"
                                                    color="#000"
                                                    height={40}
                                                    width={40}
                                                    timeout={30000}
                                                    style={{ marginLeft: "45%", marginTop: "20px" }}
                                                /> :
                                                <>
                                                    {this.state.accounts.length !== 0 ?
                                                        <select id="accountsSelected" name="accounts" multiple style={{ "padding": "12px", "border": "1px solid var(--border)", "borderRadius": "4px", "width": "100%" }}>
                                                            {this.state.accounts.map((account, i) => {
                                                                return (<>
                                                                {this.props.assignedAccounts && this.props.assignedAccounts.includes(account.accountId) === true ? 
                                                                <option key={i} id={account.accountId} selected>{account.accountName} XXXX{account.accountMask}</option>
                                                                :
                                                                <option key={i} id={account.accountId}>{account.accountName} XXXX{account.accountMask}</option>
                                                                }
                                                                </>)
                                                            })}
                                                        </select>
                                                        :
                                                        <span>No accounts found</span>
                                                    }
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            <div style={{ "float": "right" }}>
                                <button className="custom-button" onClick={this.handleClose}>Save</button>
                            </div>
                            <div style={{ "float": "right", "marginRight":"10px" }}>
                                <button className="custom-button" onClick={this.handleCancel}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }
}
export default AssignUser;